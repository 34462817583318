import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getAcademiaSessionsReserved, getAcademiaAppointments} from 'slices/academias'
import {getEntities} from 'slices/entities'
import Grid from 'components/Calendar'
import EventPopup from 'components/EventPopup'
import FilterForm from 'components/FilterForm'
import {EventHandlers} from 'components/Calendar/interfaces'
import {filterConfig} from 'enums/academias/sessions'
import {defaultEventColor, TopBarHeight} from 'enums/styles'
import {IContext} from 'containers/Employee/Academia/interfaces'
import {Entity} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

type Filters = {
    projectAreaId: number | null,
    projectArea_trainerId: number | null,
    traineeId: number | null,
    statusId: number | null
}

const Calendar = () => {
    const [filters, setFilters] = useState<Filters>({projectAreaId: null, projectArea_trainerId: null, traineeId: null, statusId: null})
    const calendarRef = useRef<EventHandlers>(null)

    const sessions = useAppSelector(state => state.academias.bookings)
    const appointments = useAppSelector(state => state.academias.appointments)
    const trainees = useAppSelector(state => state.entities[Entity.trainee])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])
    const statuses = useAppSelector(state => state.entities[Entity.sessionStatus])

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()
    const intl = useIntl()

    useEffect(() => {
        if (academiaId) {
            dispatch(getAcademiaSessionsReserved({id: Number(academiaId)}))
            dispatch(getAcademiaAppointments({id: Number(academiaId)}))
            dispatch(getEntities({entity: Entity.sessionStatus}))
            dispatch(getEntities({entity: Entity.trainer}))
            dispatch(getEntities({entity: Entity.trainee}))
        }
    }, [dispatch, academiaId])

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'projectAreaId') {
            return appointments.map(item => {
                return {
                    id: item.id,
                    name: item.area_name
                }
            })
        }

        if (name === 'projectArea_trainerId') {
            return trainers
        }

        if (name === 'traineeId') {
            return trainees
        }

        if (name === 'statusId') {
            return statuses
        }

        return []
    }, [appointments, trainers, trainees, statuses])

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = sessions.find(item => item.id === Number(id))

        if (event) {
            return <EventPopup
                id={event.id}
                name={event.projectArea_trainer_name}
                title={event.projectArea_area_name}
                start={event.starts}
                trainee={event.trainee_name}
                onClose={onClose}
            />
        }
    }

    const currentSessions = useMemo(() => Object.values(filters).find(item => !!item) ? sessions.filter(session => {
        const appointmentFilter = filters.projectAreaId ? session.projectAreaId === filters.projectAreaId : true
        const trainerFilter = filters.projectArea_trainerId ? session.projectArea_trainerId === filters.projectArea_trainerId : true
        const traineeFilter = filters.traineeId ? session.traineeId === filters.traineeId : true
        const statusFilter = filters.statusId ? session.statusId === filters.statusId : true
    
        return appointmentFilter && traineeFilter && trainerFilter && statusFilter
    }) : sessions, [sessions, filters])

    const events = useMemo(() => {
        return currentSessions.filter(item => !!item.id).map((item, index) => {
            return {
                id: item.id.toString(),
                title: item.projectArea_trainer_name ? `${item.projectArea_trainer_name} - ${item.projectArea_area_name}` : item.projectArea_area_name,
                start: item.starts,
                end: item.ends,
                backgroundColor: item.projectArea_area_color ? item.projectArea_area_color : defaultEventColor,
                borderColor: item.projectArea_area_color ? item.projectArea_area_color : defaultEventColor,
                textColor: '#666666'
            }
        })
    }, [currentSessions])
    
    return (
        <Container margin={TopBarHeight}>
            <Grid
                defaultView={'dayGridMonth'}
                locale={intl.locale as any}
                ref={calendarRef}
                modeChangeControl={true}
                events={events}
                eventPopupContent={eventPopupContent}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filterConfig}
            />
        </Container>
    )
}

export default Calendar