import {useCallback, useEffect, useState, useMemo, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Brush from '@mui/icons-material/Brush'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getAcademias, createAcademias} from 'slices/academias'
import {getEntities} from 'slices/entities'
import CardsList from 'components/CardsList'
import EditForm from 'components/EditForm'
import {types} from 'enums/fields'
import routes from 'enums/routes'
import {createForm} from 'enums/academias/academias'
import {Entity} from 'api/enums'

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

const icon = {
    fontSize: 18
}

const chip = {
    width: '180px',
    marginBottom: 2
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        }
    ]
}

const Academias = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement | null>(null)
    const [status, setStatus] = useState<number>(0)
    const [openForm, setOpenForm] = useState<boolean>(false)

    const containerElement = useRef(null)

    const academias = useAppSelector(state => state.academias.academias)
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])
    const members = useAppSelector(state => state.entities[Entity.teamMember])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        dispatch(getAcademias())
        dispatch(getEntities({entity: Entity.projectStatus}))
    }, [dispatch])

    const academiaStatuses = useMemo(() => {
        return statuses.filter(status => status.academia)
    }, [statuses])

    const onGridItemClick = useCallback((id: number) => {
        navigate(`/${routes.Academia}/${id}/${routes.Overview}`)
    }, [navigate])

    const handleFormOpen = () => {
        dispatch(getEntities({entity: Entity.teamMember}))
        
        setOpenForm(true)
    }

    const handleFormClose = () => {
        setOpenForm(false)
    }

    const handleConfirm = (values: any) => {
        dispatch(createAcademias({data: [{...values, typeId: 1}]}))

        handleFormClose()
    }

    const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleFilterClose = () => {
        setOpenFilter(null)
    }

    const filterItems = (id: number) => {
        if (id === status) {
            setStatus(0)
        } else {
            setStatus(id)
        }

        handleFilterClose()
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
       if (name === 'teamMembers') {
            return members
        } else {
            return []
        }
    }

    const academiasFiltered = useMemo(() => status ? academias.filter(academia => {
        const filterStatus = academia.status_id === status

        return filterStatus

    }) : academias, [academias, status])

    const currentStatus = academiaStatuses.find(item => item.id === status)

    const initialValues = useMemo(() => {
        return {
            name: '',
            starts: null,
            ends: null,
            salesCloseDate: null,
            kickoffDate: null,
            kickoffLink: '',
            surveyLink: '',
            pricePerTrainee: null,
            teamMembers: []
        }
    }, [])
    
    return (
        <div style={{height: '100%', overflow: 'auto', padding: '20px'}} ref={containerElement}>
            <Buttons>
                <Chip
                    variant='outlined'
                    color='secondary'
                    label='Create academia'
                    onClick={handleFormOpen}
                    sx={{...chip, marginRight: 2}}
                    icon={<Brush />}
                />
                <Chip
                    icon={<TaskAlt />}
                    label={currentStatus ? currentStatus.name : 'Status'}
                    sx={{...chip, background: currentStatus ? currentStatus.color : ''}}
                    onClick={handleFilterOpen}
                    data-name='status'
                />
                <Menu
                    anchorEl={openFilter}
                    open={!!openFilter}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                marginTop: '10px',
                                width: '180px'
                            }
                        }
                    }}
                >
                    {Object.values(academiaStatuses).map(item => (
                        <MenuItem onClick={() => filterItems(item.id)}
                            key={item.id}
                            value={item.id}
                            selected={status === item.id}
                        >
                            <Typography variant="inherit" noWrap>
                                {item.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Buttons>
            <CardsList
                config={config}
                data={academiasFiltered}
                onClick={onGridItemClick}
                color='#faf5f1'
                itemsCount={sm ? 1 : md ? 3 : 4}
                containerComponent={containerElement.current}
                paperHeight={280}
            />
            <EditForm
                open={openForm}
                handleClose={handleFormClose}
                handleConfirm={handleConfirm}
                title={'Create academia'}
                config={createForm}
                getOptions={getOptions}
                initialValues={initialValues}
            />
        </div>
    )
}

export default Academias