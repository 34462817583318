import React, {useState} from 'react'
import DialogContentText from '@mui/material/DialogContentText'
import Fields from 'components/EditForm/Fields'
import Modal from 'components/Modal'
import {types, defaultValues} from 'enums/fields'
import {IComponent, Value} from 'components/EditForm/interfaces'

const EditForm = (props: IComponent) => {
    const [values, setValues] = useState<{[key: string]: Value}>(props.initialValues)

    const onChange = (name: string, value: Value) => {
        const dependency = props.config.find(field => field.optionsDependency === name || field.editDependency === name)

        if (typeof props.getAutocomplete === 'function') {
            const field = props.config.find(field => field.name === name)
            const autocomplete = field ? field.autocomplete : false

            if (autocomplete) {
                const data = props.getAutocomplete(name, value)

                if (data) {
                    setValues({
                        ...values,
                        ...data,
                        [name]: value
                    })
                } else {
                    setValues({
                        ...values,
                        [name]: value
                    })
                }

                return
            }
        }

        if (dependency) {
            const defaultValue = Array.isArray(value) && value.includes(values[dependency.name]) ? values[dependency.name] : defaultValues[dependency.type]

            setValues({
                ...values,
                [dependency.name]: defaultValue,
                [name]: value
            })
        } else {
            setValues({
                ...values,
                [name]: value
            })
        }
    }

    const onCreate = (name: string, value: string) => {
        if (typeof props.onCreate === 'function') {
            const field = props.config.find(item => item.name === name)
            const dependencyValue = field && field.optionsDependency ? values[field.optionsDependency] : null

            props.onCreate(name, value, dependencyValue)
        }
    }

    const getOptions = (name: string, dependencyField?: string) => {
        if (typeof props.getOptions === 'function') {
            const dependencyValue = dependencyField ? values[dependencyField] : undefined
            return props.getOptions(name, dependencyField, dependencyValue)
        } else {
            return []
        }
    }

    const setEditable = (name: string, dependencyField?: string) => {
        if (typeof props.setEditable === 'function') {
            const dependencyValue = dependencyField ? values[dependencyField] : undefined
            return props.setEditable(name, dependencyField, dependencyValue)
        } else {
            return true
        }
    }

    const handleConfirm = () => {
        props.handleConfirm(values)
    }

    const disabled = !!props.config.find(field => field.required && !values[field.name]) || props.disabled
    const autocomplete = !!props.config.find(field => field.autocomplete && values[field.name])
    
    return (
        <Modal
            open={props.open}
            title={props.title}
            disabled={disabled}
            handleClose={props.handleClose}
            handleConfirm={handleConfirm}
        >
            <React.Fragment>
                {props.description &&
                    <DialogContentText sx={{marginBottom: 2}}>
                        {props.description}
                    </DialogContentText>
                }
                {props.config.map(item => {
                    const options = item.type === types.Chip || item.type === types.Chips || item.type === types.Suggestions || item.type === types.Color || item.type === types.Mention ?
                        getOptions(item.name, item.optionsDependency) : []

                    const editable = item.editDependency ? setEditable(item.name, item.editDependency) : true

                    return <Fields
                        key={item.name}
                        value={values[item.name]}
                        name={item.name}
                        title={item.title}
                        type={item.type}
                        min={item.min ? values[item.min] : undefined}
                        max={item.max ? values[item.max] : undefined}
                        onChange={onChange}
                        options={options}
                        disabled={(autocomplete && !item.autocomplete && !!item.autocompleteDependent) || !!item.disabled || !editable}
                        adding={item.adding}
                        onCreate={onCreate}
                        helper={item.helper}
                        getComponent={props.getComponent}
                    />
                })}
            </React.Fragment>
        </Modal>
    )
}

export default React.memo(EditForm)
