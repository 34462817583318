import countryData from './countries'
import {ICountry} from '../interfaces'

export const debounce = (func: () => void, delay: number) => {
	let timer: ReturnType<typeof setTimeout>
	return function (this: any, ...args: any) {
		clearTimeout(timer)
		timer = setTimeout(() => func.apply(this, args), delay)
	}
}

export const formatNumber = (text: string, pattern: string | null, autoFormat = false) => {
    if (!text || text.length === 0) {
        return ''
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
        return `+${text}`
    }

    const formattedObject = pattern.split('').reduce((acc, character) => {
        if (acc.remainingText.length === 0) {
            return acc
        }

        if (character !== '.') {
            return {
                formattedText: acc.formattedText + character,
                remainingText: acc.remainingText,
            }
        }

        return {
            formattedText: acc.formattedText + acc.remainingText[0],
            remainingText: acc.remainingText.slice(1)
        }
    }, {formattedText: '', remainingText: text.split('')})

    return formattedObject.formattedText + formattedObject.remainingText.join('')
}

export const replaceCountryCode = (currentSelectedCountry: ICountry, nextSelectedCountry: ICountry, number: string) => {
    const dialCodeRegex = RegExp(`^(${currentSelectedCountry.dialCode})`)
    const codeToBeReplaced = number.match(dialCodeRegex)
    const newNumber = number.replace(dialCodeRegex, nextSelectedCountry.dialCode)

    if (codeToBeReplaced === null && newNumber === number) {
        return nextSelectedCountry.dialCode + number
    }

    return newNumber
}

export const isNumberValid = (inputNumber: string) => {
    const countries = countryData.allCountries
    return countries.some(country => inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber))
}

export const guessSelectedCountry = (inputNumber: string, defaultCountry: string, onlyCountries: ICountry[]) => {
    const { allCountries, allCountryCodes } = countryData
    const secondBestGuess = allCountries.find(item => item.iso2 === defaultCountry) || onlyCountries[0]

    const inputNumberForCountries = inputNumber.substring(0, 4)
    let bestGuess

    if (inputNumber.trim() !== '') {
        bestGuess = onlyCountries.reduce((selectedCountry: ICountry, country: ICountry) => {
        // if the country dialCode exists WITH area code

            if (allCountryCodes[inputNumberForCountries] && allCountryCodes[inputNumberForCountries][0] === country.iso2) {
                return country
            // if the selected country dialCode is there with the area code
            } else if (allCountryCodes[inputNumberForCountries] && allCountryCodes[inputNumberForCountries][0] === selectedCountry.iso2) {
                return selectedCountry
            // else do the original if statement
            }

            if (inputNumber.startsWith(country.dialCode)) {
                if (country.dialCode.length > selectedCountry.dialCode.length) {
                    return country
                }

                if (country.dialCode.length === selectedCountry.dialCode.length && country.priority < selectedCountry.priority) {
                    return country
                }
            }

            return selectedCountry
        }, {name: '', iso2: '', dialCode: '', format: '', priority: 10001})
    } else {
        return secondBestGuess
    }

    if (!bestGuess || !bestGuess.name) {
        return secondBestGuess
    }

    return bestGuess
}
