import React, {useState, useEffect, useRef} from 'react'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import countryData from './utils/countries'
import {formatNumber, guessSelectedCountry} from './utils/utils'
import {IProps, ICountry} from './interfaces'
import icons from './icons.png'
// import './index.css'

const Icon = styled.div`
    background-image: url(${icons});
    width: 44px;
    height: 30px;
    transform: scale(0.8);
`

const autoFormat = true
const {allCountries} = countryData

const defaultProps = {
    onlyCountries: allCountries,
    defaultCountry: allCountries[0].iso2
}

const numeric = (str: string) => {
    return /^\d+$/.test(str)
}

export const getFormattedPhone = (propsPhone: string, defaultCountry: string = allCountries[0].iso2, onlyCountries: ICountry[] = allCountries) => {
    let inputNumber
   
    if (propsPhone) {
        inputNumber = propsPhone
    } else {
        inputNumber = ''
    }

    let selectedCountryGuess = guessSelectedCountry(inputNumber.replace(/\D/g, ''), defaultCountry, onlyCountries)

    const formattedNumber = formatNumber(inputNumber.replace(/\D/g, ''), selectedCountryGuess && selectedCountryGuess.format ?
        selectedCountryGuess.format : null, autoFormat)

    return {
        name: selectedCountryGuess,
        number: formattedNumber
    }
}

const PhoneInput = (componentProps: IProps) => {
    const props = {...defaultProps, ...componentProps}

    const [formattedPhone, setFormattedPhone] = useState<string>('')
    const [country, setCountry] = useState<ICountry>(allCountries[0])
    const [inputId, setInputId] = useState<number>(0)

    const mounted = useRef<boolean>(false)
    const numberInputRef = useRef<HTMLInputElement>()
    const caretPosition = useRef<number>(0)

    useEffect(() => {
        const {name, number} = getFormattedPhone(props.value, props.defaultCountry, props.onlyCountries)

        setFormattedPhone(number)
        setCountry(name)
    }, [props.value, props.defaultCountry, props.onlyCountries])

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        } else {
            if (numberInputRef.current) {
                numberInputRef.current.setSelectionRange(caretPosition.current, caretPosition.current)
            }
        }
    }, [inputId])

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        let formattedNumber = ''
        let newSelectedCountry = country

        if (event.target.value === formattedPhone) {
            return
        }

        if (event.target.value && event.target.value.length > 0) {
            const inputNumber = event.target.value.replace(/\D/g, '')
            const nextCountry =  guessSelectedCountry(inputNumber.substring(0, 6), props.defaultCountry, props.onlyCountries)
            newSelectedCountry = nextCountry.dialCode.length > 0 ? nextCountry : country

            formattedNumber = formatNumber(inputNumber, newSelectedCountry && newSelectedCountry.format ? newSelectedCountry.format : null, autoFormat)
        }

        const diff = formattedNumber.length - formattedPhone.length

        caretPosition.current = event.target.selectionStart || 0

        if (formattedNumber.length === 2 && caretPosition.current === 1) {
            caretPosition.current = caretPosition.current + 1
        }

        if (formattedNumber && caretPosition.current && diff > 0) {
            caretPosition.current = caretPosition.current - 1 + diff
        }

        setInputId(id => id + 1)   
        setFormattedPhone(formattedNumber)
        setCountry(newSelectedCountry)

        if (typeof props.onChange === 'function') {
            const number = formattedNumber.replace(/\D/g, '')
            props.onChange(number ? '+' + number : number, formattedNumber, newSelectedCountry)
        }
    }

    const handleInputFocus = () => {
        const formatted = formattedPhone ? formattedPhone : `+${country.dialCode}`

        if (formatted !== formattedPhone) {
            setFormattedPhone(formatted)

        }

        if (typeof props.onFocus === 'function') {
            const number = formatted.replace(/\D/g, '')
            props.onFocus(number ? '+' + number : number, formatted, country)
        }
    }

    const handleInputBlur = () => {
        const formatted = formattedPhone === `+${country.dialCode}` ? '' : formattedPhone

        if (formatted !== formattedPhone) {
            setFormattedPhone(formatted)

            if (typeof props.onChange === 'function') {
                props.onChange(formatted, formatted, country)
            }
        }

        if (typeof props.onBlur === 'function') {
            const number = formatted.replace(/\D/g, '')
            props.onBlur(number ? '+' + number : number, formatted, country)
        }
    }

    return (
        <TextField
            label={props.label}
            onChange={handleInput}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            value={formattedPhone}
            inputRef={numberInputRef}
            placeholder={props.placeholder}
            disabled={props.disabled}
            fullWidth
            InputLabelProps={{shrink: true}}
            // InputProps={{
            //     startAdornment: <InputAdornment position="start"><Icon title={country.name} className={country.iso2} /></InputAdornment>
            // }}
            // className='phone-input'
        />
    )
}

export default React.memo(PhoneInput)