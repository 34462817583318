import {numericFormatter} from 'react-number-format'

export const currencyFormatter = (value?: string | number | null) => {
    return value ? numericFormatter(value.toString(), {
        thousandSeparator: ' ',
        decimalScale: 2,
        decimalSeparator: ',',
        fixedDecimalScale: true,
        prefix: '€ '
    }) : ''
}

export const numberFormatter = (value?: string | number | null) => {
    return value ? numericFormatter(value.toString(), {
        thousandSeparator: ' ',
        decimalScale: 2,
        decimalSeparator: ',',
        fixedDecimalScale: true,
    }) : ''
}