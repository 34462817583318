import React from 'react'
import styled from '@emotion/styled'

interface IComponent {
    value: string
}

const Container = styled.img`
    height: 50px;
    width: 100px;
    margin: 0 10px;
    object-fit: cover;
    border-radius: 10px;
    background: rgba(235, 235, 235, 0.5);
`

const Image = (props: IComponent) => {
    return (
        <React.Fragment>
            {props.value &&
                <Container src={`https://lh3.googleusercontent.com/d/${props.value}`} alt='' />
            }
        </React.Fragment>
    )
}

export default React.memo(Image)
