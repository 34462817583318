import React, {useCallback, useEffect, useState, useMemo} from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import Person from '@mui/icons-material/Person'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Euro from '@mui/icons-material/Euro'
import TaskAlt from '@mui/icons-material/TaskAlt'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getForecast} from 'slices/forecast'
import {getEntities} from 'slices/entities'
import FilterForm from 'components/FilterForm'
import Card from 'components/Card'
import {filter} from 'enums/forecast'
import {types} from 'enums/fields'
import {Entity} from 'api/enums'
import {currencyFormatter} from 'utils/formatter'

const Container = styled.div`
    padding: 20px;
`

const Item = styled.div`
    height: 450px;
    margin: 20px 0;
`

const Amount = styled.div`
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 1px;
    text-align: end;

    :not(:last-child) {
        margin-bottom: 5px;
    }
`

const Accepted = styled(Amount)`
    color: #43a047;
`

const Notaccepted = styled(Amount)`
    color: #708085;
`

const Switch = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

const icon = {
    fontSize: 18
}

const chip = {
    width: '180px',
    marginBottom: 2
}

const paper = {
    width: '100%',
    padding: '20px',
    display: 'flex',
    backgroundColor: 'info.light'
}

const Count = 4

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Sales close date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'salesCloseDate'
        },
        {
            label: 'Sales value',
            icon: <Euro sx={icon} />,
            type: types.Currency,
            value: 'salesValue'
        },
        {
            label: 'Type',
            icon: <AutoFixHigh sx={icon} />,
            type: types.Chip,
            value: 'type_name',
            props: {
                id: 'type_id',
                text: 'type_name',
                background: 'type_color'
            }
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        }
    ]
}

const getMonths = (count: number) => {
    const today = dayjs()

    return [...Array(count)].map((item, index) => today.add(index, 'month'))
}

const Forecast = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))
    const count = mobile ? 1 : Count

    const [filters, setFilters] = useState<{typeId: number[]}>({typeId: []})
    const [months, setMonths] = useState<any[]>(() => getMonths(count))
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    
    const data = useAppSelector(state => state.forecast.data)
    const types = useAppSelector(state => state.entities[Entity.projectType])

    const items = data.items
    const infos = data.infos

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getForecast())
        dispatch(getEntities({entity: Entity.projectType}))
    }, [dispatch])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'typeId') {
            return types
        }

        return []

    }, [types])

    const handleFilterOpen = useCallback(() => {
        setOpenFilter(openFilter => !openFilter)
    }, [])

    const handleFilterConfirm = useCallback((values: any) => {
        setFilters({
            ...filters,
            ...values
        })

        handleFilterOpen()
    }, [filters, handleFilterOpen])

    const nextButtonClick = () => {
        const last = dayjs(months[months.length - 1])
        const dates = [...months].slice(1)
        dates.push(last.add(1, 'month'))

        setMonths(dates)
    }

    const prevButtonClick = () => {
        const first = dayjs(months[0])
        const dates = [...months].slice(0, -1)
        dates.unshift(first.subtract(1, 'month'))

        setMonths(dates)
    }

    const currentItems = useMemo(() => {
        return filters.typeId.length ? items.filter(item => {
            return filters.typeId.find(id => item.typeId === id)
        }) : items
    }, [items, filters.typeId])

    return (
        <React.Fragment>
            <Container>
                <Buttons>
                    <Switch>
                        <IconButton onClick={prevButtonClick}>
                            <ChevronLeft />
                        </IconButton>
                        <Chip
                            label={months[0].format('MMMM, YYYY')}
                            sx={{margin: '0 10px', width: '200px'}}
                        />
                        <IconButton onClick={nextButtonClick}>
                            <ChevronRight />
                        </IconButton>
                    </Switch>
                    <Chip
                        icon={<FilterAlt />}
                        label={'Filters'}
                        sx={chip}
                        onClick={handleFilterOpen}
                        variant={filters.typeId.length ? 'filled' : 'outlined'}
                        color='secondary'
                    />
                </Buttons>
                 <Grid container spacing={2}>
                    {months.map(month => {
                        const info = infos.find(obj => month.isSame(obj.salesCloseDate_month, 'month'))
                        const key = month.format('MMMM')

                        return (
                            <Grid item xs={12} md={3} key={key}>
                                <Paper sx={paper}>
                                    <Chip
                                        label={key}
                                        sx={{background: '#b2dfdb', marginRight: 'auto'}}
                                    />
                                    <div>
                                        <Notaccepted>{currencyFormatter(info ? info.notaccepted || '0' : '0')}</Notaccepted>
                                        <Accepted>{currencyFormatter(info ? info.accepted || '0' : '0')}</Accepted>
                                        <Amount>{currencyFormatter(info ? info.total || '0' : '0')}</Amount>
                                    </div>
                                </Paper>
                                <div>
                                    {currentItems.map(item =>
                                        info && info.salesCloseDate_month === item.salesCloseDate_month &&
                                        <Item key={item.id}>
                                            <Card
                                                config={config}
                                                data={item}
                                                color={item.accepted ? '#edfaee' : '#faf5f1'}
                                            />
                                        </Item>
                                    )}
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
            <FilterForm
                open={openFilter}
                handleClose={handleFilterOpen}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filter}
            />
        </React.Fragment>
    )
}

export default Forecast