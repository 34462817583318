import React, {useRef} from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

interface IComponent {
    value: string,
    title?: string,
    name: string,
    multiline?: boolean,
    disabled: boolean,
    onChange?: (name: string, value: File | null) => void,
    size?: 'small',
    accept?: string
}

const File = (props: IComponent) => {
    const input = useRef<HTMLInputElement>(null)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof props.onChange === 'function') {
            if (e.target.files && e.target.files.length) {
                props.onChange(props.name, e.target.files[0])
            }
        }
    }

    const onFocus = () => {
        if (input.current) {
            input.current.click()
        }
    }

    const onButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation()

        if (typeof props.onChange === 'function') {
            props.onChange(props.name, null)

            if (input.current) {
                input.current.value = ''
            }
        }
    }

    return (
        <React.Fragment>
            <TextField
                disabled={props.disabled}
                value={props.value}
                InputLabelProps={{ shrink: true }}
                label={props.title}
                name={props.name}
                variant="outlined"
                fullWidth
                sx={props.size && {input: {fontSize: 14}}}
                size={props.size}
                inputProps={{
                    readOnly: true
                }}
                onClick={onFocus}
                InputProps={{
                    endAdornment: props.value && !props.disabled && (
                        <IconButton onClick={onButtonClick} sx={{padding: '4px'}}>
                            <Clear fontSize='small' />
                        </IconButton>
                    )
                }}
            />
            <input
                type="file"
                accept={props.accept ? props.accept : undefined}
                hidden
                onChange={onChange}
                name={props.name}
                ref={input}
            />
        </React.Fragment>
    )
}

export default React.memo(File)
