import React from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import {SessionTypeRemote} from 'enums/utils'
import {setTime} from 'utils/datetime'

const Title = styled.div`
    font-size: 13px;
    color: #708085;
    letter-spacing: 0.5px;
    font-weight: 500;
    margin: 10px 0;
`

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0;
`

const Location = styled.div`
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
`

interface IComponent {
    id: number
    name?: string
    title?: string
    start: string
    link?: string
    type?: string
    color?: string
    typeId?: number
    location?: string
    onClose: () => void
}

const delay = 0.25

const EventPopup = (props: IComponent) => {
    const intl = useIntl()

    const target = setTime(new Date(props.start), delay)
    const current = new Date()
    const past = current > target

    const date = intl.formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })
    
    return (
        <div>
            <React.Fragment>
                {props.title && <Title>{props.title}</Title>}
                {props.type &&
                    <Chip label={props.type} size='small'
                        sx={{marginBottom: '10px', borderRadius: '5px', background: props.color}}
                    />
                }
                {props.location &&
                    <Location>{props.location}</Location>
                }
                {past ?
                    <React.Fragment>
                        {props.name && <Text>
                            {intl.formatMessage({id: "calendar.PastSession"}, {title: props.name, date: date, time: time})}
                        </Text>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {props.name && <Text>
                            {intl.formatMessage({id: "calendar.FutureSession"}, {title: props.name, date: date, time: time})}
                        </Text>}
                        {props.typeId === SessionTypeRemote && props.link &&
                            <Button
                                fullWidth
                                variant='contained'
                                color='secondary'
                                component={Link}
                                to={props.link}
                                target='_blank'
                            >
                                {intl.formatMessage({id: 'calendar.Connect'})}
                            </Button>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        </div>
    )
}

export default React.memo(EventPopup) 