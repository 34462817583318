import React, {useRef} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import {useTheme} from '@mui/material/styles'
import {useIntl} from 'react-intl'
import Chip from '@mui/material/Chip'
import Backup from '@mui/icons-material/Backup'
import Edit from '@mui/icons-material/Edit'
import {types} from 'enums/fields'
import {DateFormate, DateTimeFormate} from 'enums/utils'
import {currencyFormatter} from 'utils/formatter'

const Container = styled.div<{pointer: boolean}>`
    font-size: 13px;
    cursor: ${props => props.pointer ? 'pointer' : 'default'};
`

const Rule = styled.span<{color: string}>`
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    border-bottom: 1px solid;
    color: ${props => props.color};
    margin-right: 20px;
    filter: brightness(90%);
    margin-bottom: 10px;
`

const Title = styled.div<{textColor: string}>`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: ${props => props.textColor};
    white-space: nowrap;
    height: 20px;
`

const Icon = styled.div`
    margin-right: 5px;
`

const Name = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`

const Text = styled.div<{presentation?: boolean}>`
    ${props => props.presentation && `
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 20px;
    `}
`

const Link = styled(RouterLink)`
    color: inherit;
    font-style: italic;
    letter-spacing: 0.5px;
    padding-right: 5px;
    display: block;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    margin-right: 10px;
    border-bottom: 1px solid;
    text-decoration: none;
`

const Numeric = styled(Text)`
    color: #43a047;
    font-weight: 500;
`

const chip = {
    marginBottom: '10px',
    marginRight: '10px',
    cursor: 'inherit'
}

const button = {
    marginBottom: '10px',
    marginRight: '10px'
}

interface IField {
    label: string
    icon: JSX.Element
    type: string
    value: string
    editable?: boolean
    props?: {
        id: string
        text?: string
        background?: string
        value?: string
    }
}

interface IComponent {
    onChange?: (name: string, value?: any) => void
    onItemClick?: (name: string) => void
    data: any
    presentation?: boolean
    field: IField
}

const Item = (props: IComponent) => {
    const input = useRef<HTMLInputElement>(null)

    const theme = useTheme()
    const intl = useIntl()

    const onButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation()

        if (input.current) {
            input.current.click()
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof props.onChange === 'function') {
            if (e.target.files && e.target.files.length) {
                props.onChange(props.field.value, e.target.files)
            }
        }
    }

    const onEditClick = (e: React.MouseEvent) => {
        if (typeof props.onChange === 'function') {
            props.onChange(props.field.value)
        }
    }

    const onDelete = (e: React.MouseEvent, id: number) => {
        e.preventDefault()
        e.stopPropagation()

        if (typeof props.onChange === 'function') {
            props.onChange(props.field.value, id)
        }
    }

    const getItem = (field: IField) => {
        const value = props.data[field.value]

        switch (field.type) {
            case types.Date:
                return (
                    <Text presentation={props.presentation}>
                        {value ? dayjs(value).format(DateFormate) : ''}
                    </Text>
                )

            case types.DateTime:
                return (
                    <Text presentation={props.presentation}>
                        {value ? dayjs(value).format(DateTimeFormate) : ''}
                    </Text>
                )

            case types.Currency: {
                const formatted = currencyFormatter(value)
                
                return (
                    <Numeric presentation={props.presentation}>
                        {formatted}
                    </Numeric>
                )
            }

            case types.Link: {
                return (
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        {value &&
                            <Link to={value} target='_blank'>
                                {value}
                            </Link>
                        }
                        {field.editable &&
                            <Chip
                                variant='outlined'
                                label={intl.formatMessage({id: 'card.Edit'})}
                                onClick={onEditClick}
                                sx={button}
                                color='primary'
                                icon={<Edit />}
                            />
                        }
                    </div>
                )
            }

            case types.Chip:
                return (
                    <div>
                        <Chip label={value}
                            sx={{
                                ...chip,
                                background: field.props && field.props.background ? props.data[field.props.background] : '',
                            }}
                        />
                    </div>
                )

            case types.Chips:
                return (
                    <div>
                        {field.props && value.map((item: {[key: string]: string}) => {
                            if (field.props && field.props.text && item[field.props.text]) {
                                return  <Chip
                                    key={item[field.props.id]}
                                    label={field.props.text ? item[field.props.text] : ''}
                                    sx={{
                                        ...chip,
                                        background: field.props.background ? item[field.props.background] : ''
                                    }}
                                />
                            } else {
                                return <React.Fragment />
                            }
                        })}
                    </div>
                )

            case types.ProjectInvoice:
                return (
                    <div>
                        {field.props && value.map((item: {[key: string]: string}) => {
                            if (field.props) {
                                const value = field.props.value && item[field.props.value] ? item[field.props.value] : undefined

                                return !!value && !!Number(value) && <Rule
                                    key={item[field.props.id]}
                                    color={field.props.background ? item[field.props.background] : ''}>
                                        {field.props.text ? item[field.props.text] : ''} {value ? '- ' + value : ''}
                                    </Rule>
                            } else {
                                return <React.Fragment />
                            }
                        })}
                    </div>
                )

            case types.File:
                return (
                    <div>
                        {Array.isArray(value) ? value.map((item: any) => {
                            if (field.props && field.props.text && field.props.value && field.props.id &&
                                item[field.props.text] && item[field.props.value] && item[field.props.id]) {
                                
                                const id = item[field.props.id]

                                return  <Chip
                                    key={id}
                                    label={item[field.props.text]}
                                    sx={button}
                                    component={RouterLink}
                                    to={item[field.props.value]}
                                    target='_blank'
                                    clickable
                                    onDelete={field.editable ? (e) => onDelete(e, id) : undefined}
                                />
                            } else {
                                return <React.Fragment />
                            }
                        }) : field.props && field.props.text && field.props.value && field.props.id &&
                        props.data[field.props.text] && props.data[field.props.value] && props.data[field.props.id] &&
                            <Chip
                                label={props.data[field.props.text]}
                                sx={button}
                                component={RouterLink}
                                to={props.data[field.props.value]}
                                target='_blank'
                                clickable
                                onDelete={field.editable ? (e) => onDelete(e, props.data[field.props!.id]) : undefined}
                            />
                        }
                        {field.editable &&
                            <React.Fragment>
                                <Chip
                                    variant='outlined'
                                    label={intl.formatMessage({id: 'card.Upload'})}
                                    onClick={onButtonClick}
                                    sx={button}
                                    color='primary'
                                    icon={<Backup />}
                                />
                                <input
                                    type='file'
                                    hidden
                                    onChange={onChange}
                                    name={props.field.value}
                                    ref={input}
                                    multiple
                                />
                            </React.Fragment>
                        }
                    </div>
                )

            default:
                return (
                    <Text presentation={props.presentation}>{value}</Text>
                )
        }

    }

    const onItemClick = () => {
        if (typeof props.onItemClick === 'function') {
            props.onItemClick(props.field.value)
        }
    }

    const pointer = typeof props.onItemClick === 'function' ? true : false
    
    return (
        <Container pointer={pointer} onClick={onItemClick}>
            <Title textColor={theme.palette.info.dark}>
                {props.field.icon &&
                    <Icon>{props.field.icon}</Icon>
                }
                <Name>{props.field.label}</Name>
            </Title>
            {getItem(props.field)}
        </Container>
    )
}

export default React.memo(Item)