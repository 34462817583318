import React from 'react'
import {Link} from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import ChevronRight from '@mui/icons-material/ChevronRight'

type Item = {
    name: string,
    path?: string
}

interface IComponent {
    items: Item[],
    data: {
        [key: string]: string
    }
    margin?: string
}

const style = {
    color: 'text.primary',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: {
        xs: 'none',
        sm: 'block'
    },

    '.MuiBreadcrumbs-ol': {
        flexWrap: 'nowrap'
    },

    '.MuiBreadcrumbs-li:last-child': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
} as const

const text = {
    letterSpacing: '0.5px',
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}

const Navigation = styled(Link)`
    letter-spacing: 0.5px;
    font-size: 14px;
    text-decoration: none;
    color: inherit;
`

const Breadcrumbs = (props: IComponent) => {
    return (
        <MuiBreadcrumbs separator={<ChevronRight fontSize="small" />} sx={{...style, margin: props.margin}}>
            {props.items.map((item, index) => {
                return item.path ?
                    <Navigation key={index} to={item.path}>
                        {props.data[item.name]}
                    </Navigation> :
                    <Typography key={index} color="text.secondary" sx={text}>
                        {props.data[item.name]}
                    </Typography>
            })}
        </MuiBreadcrumbs>
    )
}

export default React.memo(Breadcrumbs)
