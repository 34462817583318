import {Outlet} from 'react-router-dom'
import Box from '@mui/material/Box'

const Trainee = () => {
    return (
        <Box sx={{flexGrow: 1, minWidth: 0}}>
            <Outlet />
        </Box>
    )
}

export default Trainee