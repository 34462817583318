import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import {IReqId, IReqData, ITrainerSlot} from 'api/interfaces'
import api from 'utils/api'

export type Report = {
    id: number,
    report: string
}

export type Session = {
    id: number,
    starts: string,
    ends: string,
    location: string,
    projectAreaId: number,
    statusId: number,
    sessionTypeId: number,
    traineeId: number,
    event_id: number,
    event_projectId: number,
    event_areaId: number,
    event_trainerId: number,
    event_area_id: number,
    event_area_name: string,
    event_area_color: string,
    event_project_id: number,
    event_project_name: string,
    event_project_context: string,
    event_trainee_id: number,
    event_trainee_name: string,
    event_trainee_photo: string,
    event_trainee_phone: string,
    event_trainee_email: string,
    event_session_id: number,
    report: string,
    [key: string]: any
}

type Slot = {
    id: number,
    starts: string,
    ends: string,
    [prop: string]: any
}

type Academia = {
    id: number,
    name: string,
    [prop: string]: any
}

type Participants = {
    id: number,
    projectId: number,
    traineeId: number | null,
    trainee_id: number | null,
    presentationVideo: string,
    finalReport: string,
    trainee_name: string,
    trainee_photo: string,
    trainee_phone: string,
    trainee_email: string,
    trainee_role: string,
    trainee_company_name: string,
    trainee_clientId: number | null
}

interface IState {
    academias: Academia[],
    slots: Slot[],
    sessions: Session[],
    bookings: Session[],
    participants: Participants[],
    pending: boolean
}

const initialState: IState = {
    academias: [],
    slots: [],
    sessions: [],
    bookings: [],
    participants: [],
    pending: false
}

export const getTrainings = createAsyncThunk('trainer/getTrainings', async () => {
    const response = await api(ApiRoute.gettraineracademias)
    return response.data
})

export const getTrainerTimeslots = createAsyncThunk('trainer/getTrainerTimeslots', async () => {
    const response = await api(ApiRoute.gettrainerslots)
    return response.data
})

export const updateTrainerTimeslot = createAsyncThunk('trainer/updateTrainerTimeslot', async (data: IReqData<ITrainerSlot>) => {
    const response = await api(ApiRoute.updatetrainerslots, data)
    return response.data
})

export const getTrainerSessions = createAsyncThunk('trainer/getTrainerSessions', async (data: IReqId | undefined) => {
    const response = await api(ApiRoute.gettrainersessions, data)
    return response.data
})

export const getTrainerBookings = createAsyncThunk('trainer/getTrainerBookings', async () => {
    const response = await api(ApiRoute.gettrainersessionsreserved)
    return response.data
})

export const updateTrainerSessions = createAsyncThunk('trainer/updateTrainerSessions', async (data: IReqData<Report>) => {
    const response = await api(ApiRoute.updatetrainersessions, data)
    return response.data
})

export const getTrainerParticipants = createAsyncThunk('trainer/getTrainerParticipants', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojecttrainees, data)
    return response.data
})

export const trainerSlice = createSlice({
    name: 'trainer',
    initialState,
    reducers: {
        resetAcademia: state => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTrainings.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTrainings.fulfilled, (state, action) => {
                state.academias = action.payload
                state.pending = false
            })
            .addCase(getTrainings.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTrainerTimeslots.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTrainerTimeslots.fulfilled, (state, action) => {
                state.slots = action.payload
                state.pending = false
            })
            .addCase(getTrainerTimeslots.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateTrainerTimeslot.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateTrainerTimeslot.fulfilled, (state, action) => {
                const slots = state.slots.filter(item => item.starts_day !== action.meta.arg.filter!.starts_day.equals)
                slots.push(...action.payload)

                state.slots = slots
                state.pending = false
            })
            .addCase(updateTrainerTimeslot.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTrainerSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTrainerSessions.fulfilled, (state, action) => {
                state.sessions = action.payload
                state.pending = false
            })
            .addCase(getTrainerSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTrainerBookings.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTrainerBookings.fulfilled, (state, action) => {
                state.bookings = action.payload
                state.pending = false
            })
            .addCase(getTrainerBookings.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateTrainerSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateTrainerSessions.fulfilled, (state, action) => {
                const sessions = state.sessions

                action.payload.forEach((item: Session) => {
                    const index = sessions.findIndex(session => session.id === item.id)
                    sessions[index] = item
                })

                state.sessions = sessions
                state.pending = false
            })
            .addCase(updateTrainerSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTrainerParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTrainerParticipants.fulfilled, (state, action) => {
                state.participants = action.payload
                state.pending = false
            })
            .addCase(getTrainerParticipants.rejected, (state, action) => {
                state.pending = false
            })
    }
})

export const {resetAcademia} = trainerSlice.actions

export default trainerSlice