import React from 'react'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

interface IComponent {
    id: number,
    onClick?: (id: number) => void
}

const Button = (props: IComponent) => {
    const onClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick(props.id)
        }
    }

    return (
        <Container>
            <IconButton size="small" onClick={onClick}>
                <Clear sx={{fontSize: 15}} />
            </IconButton>
        </Container>
    )
}

export default React.memo(Button)
