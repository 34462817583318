import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Item from 'components/Card/Item'

const Field = styled.div<{presentation?: boolean}>`
    margin-bottom: 20px;
    margin-right: ${props => props.presentation ? '100px' : '0'};

    :last-child {
        margin-bottom: 0;
    }
`

const Name = styled.div<{presentation?: boolean}>`
    margin-bottom: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.presentation && `
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: normal;
    `}
`

const paper = {
    padding: '20px',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    backgroundColor: 'primary.light'
}

const fullwidth = {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'primary.light'
}

interface IField {
    label: string
    icon: JSX.Element
    type: string
    value: string
    editable?: boolean
    props?: {
        id: string
        text?: string
        background?: string
        value?: string
    }
}

interface IComponent {
    onClick?: (id: number) => void
    onItemClick?: (name: string) => void
    onChange?: (name: string, value: any) => void
    data: any
    presentation?: boolean
    tooltips?: string
    color?: string
    config: {
        id: string
        title: string
        fields: IField[]
    }
}

const Card = (props: IComponent) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const onClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick(props.data[props.config.id])
        }
    }

    const presentation = props.presentation && !mobile

    return (
        <Paper onClick={onClick} sx={presentation ? {...fullwidth} : {...paper, background: props.color}}>
            {props.config.title && <Name presentation={presentation}>{props.data[props.config.title]}</Name>}
            {props.config.fields.map(field => {
                const value = props.data[field.value]

                if (!field.editable && (!value || (Array.isArray(value) && !value.length))) return <React.Fragment />
                
                const item = <Field key={field.value} presentation={presentation}>
                    <Item
                        presentation={presentation}
                        onItemClick={props.onItemClick}
                        onChange={props.onChange}
                        field={field}
                        data={props.data}
                    />
                </Field>

                return props.tooltips ?
                    <Tooltip key={field.value} title={props.tooltips}>{item}</Tooltip> : item
            })}
        </Paper>
    )
}

export default React.memo(Card)