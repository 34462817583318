import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getEntities} from 'slices/entities'
import FilterForm from 'components/FilterForm'
import Grid from 'components/Calendar'
import EventPopup from 'components/EventPopup'
import {EventHandlers} from 'components/Calendar/interfaces'
import {filter} from 'enums/calendar'
import {defaultEventColor} from 'enums/styles'
import {Entity, View} from 'api/enums'

const Top = styled.div`
    position: absolute;
    right: 15px;
    z-index: 1;
    top: 74px;
`

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
`

const chip = {
    width: '180px',
    marginLeft: 'auto'
}

const Calendar = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const [filters, setFilters] = useState<{projectArea_projectId: number | null, projectArea_trainerId: number | null, traineeId: number | null}>({projectArea_projectId: null, projectArea_trainerId: null, traineeId: null})
    const [openFilter, setOpenFilter] = useState<boolean>(false)

    const calendarRef = useRef<EventHandlers>(null)
    
    const sessions = useAppSelector(state => state.entities[View.trainerSessionsReserved])
    const trainees = useAppSelector(state => state.entities[Entity.trainee])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])
    const projects = useAppSelector(state => state.entities[Entity.project])

    const intl = useIntl()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEntities({view: View.trainerSessionsReserved}))
        dispatch(getEntities({entity: Entity.project}))
        dispatch(getEntities({entity: Entity.trainer}))
        dispatch(getEntities({entity: Entity.trainee}))
    }, [dispatch])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'projectArea_trainerId') {
            return trainers
        }

        if (name === 'traineeId') {
            return trainees
        }

        if (name === 'projectArea_projectId') {
            return projects
        }

        return []

    }, [trainers, trainees, projects])

    const handleFilterOpen = useCallback(() => {
        setOpenFilter(openFilter => !openFilter)
    }, [])

    const handleFilterConfirm = useCallback((values: any) => {
        setFilters({
            ...filters,
            ...values
        })

        handleFilterOpen()
    }, [filters, handleFilterOpen])

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = sessions.find((item: any) => item.id === Number(id))

        if (event) {
            return <EventPopup
                id={event.id}
                name={event.projectArea_trainer_name}
                title={event.projectArea_area_name}
                project={event.projectArea_project_name}
                trainee={event.trainee_name}
                start={event.starts}
                onClose={onClose}
            />
        }
    }

    const currentSessions = useMemo(() => Object.values(filters).find(item => !!item) ? sessions.filter((session: any) => {
        const projectFilter = filters.projectArea_projectId ? session.projectArea_projectId === filters.projectArea_projectId : true
        const trainerFilter = filters.projectArea_trainerId ? session.projectArea_trainerId === filters.projectArea_trainerId : true
        const traineeFilter = filters.traineeId ? session.traineeId === filters.traineeId : true
    
        return projectFilter && traineeFilter && trainerFilter
    }) : sessions, [sessions, filters])

    const events = useMemo(() => {
        return currentSessions.filter((item: any) => !!item.id).map((item: any) => {
            const color = item.projectArea_area_color ? item.projectArea_area_color : defaultEventColor

            return {
                id: item.id.toString(),
                title: item.projectArea_trainer_name,
                start: item.starts,
                end: item.ends,
                backgroundColor: item.projectArea_project_accepted ? color : color + '33',
                borderColor: color,
                textColor: '#666666'
            }
        })
    }, [currentSessions])

    const appliedFilters = !!Object.values(filters).find(item => !!item)

    return (
        <React.Fragment>
            <Top>
                {!mobile && <Chip
                    icon={<FilterAlt />}
                    label={'Filters'}
                    sx={chip}
                    onClick={handleFilterOpen}
                    variant={appliedFilters ? 'filled' : 'outlined'}
                    color='secondary'
                />}
            </Top>
            <Container margin={0}>
                <Grid
                    defaultView={'dayGridMonth'}
                    locale={intl.locale as any}
                    ref={calendarRef}
                    modeChangeControl={true}
                    events={events}
                    eventPopupContent={eventPopupContent}
                />
            </Container>
            <FilterForm
                open={openFilter}
                handleClose={handleFilterOpen}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filter}
            />
        </React.Fragment>
    )
}

export default Calendar