import Sheet from 'components/Sheet'
import {Entity, Sheet as Sheets} from 'api/enums'
import {form} from 'enums/entities/expenses'

const Expenses = () => {
    return (
        <Sheet
            data={Entity.frequentExpense}
            primaryId={'id'}
            textFilter={['name']}
            editFormFields={form}
            entities={[]}
            sheet={Sheets.frequentExpense}
            bottom
        />
    )
}

export default Expenses