import {createTheme} from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'

const muiTheme = createTheme({
})

const theme = createTheme({
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto, Helvetica Neue, sans-serif'
    },
    spacing: 10,
    palette: {
        primary: {
            main: '#d2a078',
            light: '#faf5f1'
        },
        secondary: {
            main: '#7460eb',
            light: '#f1effd'
        },
        error: {
            main: '#f44336'
        },
        info: {
            main: '#ffffff',
            dark: '#708085',
            light: '#f5f5f5'
        },
        warning: {
            main: '#ef6c00'
        },
        success: {
            main: '#43a047'
        },
        text: {
            primary: '#444444',
            secondary: '#666666',
            disabled: '#999999'
        }
    },
    mixins: {
        toolbar: {
            minHeight: 60,
            '@media (min-width: 0px) and (orientation: landscape)': {
                minHeight: 60,
            },
            '@media (min-width: 600px)': {
                minHeight: 60,
            },
        }
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: '5px 10px',
                    letterSpacing: '0.5px'
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    left: 10,
                    bottom: 10,
                    right: 10,

                    [muiTheme.breakpoints.up('sm')]: {
                        left: 20,
                        bottom: 20
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    letterSpacing: '0.5px',
                    paddingLeft: '15px',
                    paddingRight: '15px'
                },
                icon: {
                    fontSize: 18,
                    marginLeft: '15px',
                    color: 'inherit'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 20
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 20,
                    paddingBottom: 0
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    padding: 20
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    textTransform: 'none',
                    letterSpacing: 0.5,
                    padding: '10px 15px'
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                }
            }
        },
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    maxHeight: 320,
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
                }
            }
        },
        MuiDigitalClock: {
            styleOverrides: {
                root: {
                    width: 130,
                    alignItems: 'center',
                    maxHeight: 320
                }
            }
        }
    },
})

export default theme