import React, {useLayoutEffect, useState} from 'react'
import {NumericFormat, NumericFormatProps} from 'react-number-format'
import TextField from '@mui/material/TextField'

interface IComponent {
    value: number | null,
    title?: string,
    name: string,
    multiline?: boolean,
    disabled: boolean,
    onChange?: (name: string, value: number) => void,
    size?: 'small',
    controlled: boolean,
    decimalScale?: number
}

interface CustomProps {
    onChange: (event: {target: {name: string; value: string }}) => void,
    name: string,
    decimalScale?: number
  }

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
    const {onChange, ...other} = props

    return <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value
                }
            })
        }}
        thousandSeparator=' '
        valueIsNumericString
        decimalScale={props.decimalScale || props.decimalScale === 0 ? props.decimalScale : 2}
        decimalSeparator=','
        fixedDecimalScale
    />
})

const Numeric = (props: IComponent) => {
    const [value, setValue] = useState<string | null>(props.value || props.value === 0 ? props.value.toString() : null)

    useLayoutEffect(() => {
        setValue(props.value || props.value === 0 ? props.value.toString() : null)
    }, [props.value])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.controlled && typeof props.onChange === 'function') {
            const current = e.target.value ? Number(e.target.value) : 0
            props.onChange(props.name, current)
        } else {
            setValue(e.target.value ? e.target.value : null)
        }
    }

    const onBlur = () => {
        if (!props.controlled && typeof props.onChange === 'function') {
            const previous = props.value || props.value === 0 ? Number(props.value) : null
            const current = value ? Number(value) : null
            if (previous !== current) {
                props.onChange(props.name, current || 0)
            }
        }
    }
    
    return (
        <TextField
            disabled={props.disabled}
            value={value}
            InputLabelProps={{ shrink: true }}
            label={props.title}
            name={props.name}
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            multiline={!!props.multiline}
            minRows={props.multiline ? 3 : undefined}
            sx={props.size && {input: {fontSize: 14}}}
            size={props.size}
            InputProps={{
                inputComponent: NumericFormatCustom as any,
                inputProps: {
                    decimalScale: props.decimalScale
                }
            }}
        />
    )
}

export default React.memo(Numeric)
