import React from 'react'
import Text from 'components/Fields/Text'
import Date from 'components/Fields/Date'
import Chip from 'components/Fields/Chip'
import Image from 'components/Fields/Image'
import Chips from 'components/Fields/Chips'
import Numeric from 'components/Fields/Numeric'
import Rule from 'components/Fields/Rule'
import Links from 'components/Fields/Links'
import Phone from 'components/Fields/Phone'
import {types} from 'enums/fields'

interface IComponent {
    value: any
    column: Field
    data: {[key: string]: any}
}

type Field = {
    name: number | string
    width: number
    type?: string
    color?: string
    itemId?: string
    itemLabel?: string
    itemColor?: string
    itemValue?: string
    link?: string
    status?: string
    days?: string
}

const BodyItem = (props: IComponent) => {
    const getField = () => {
        switch (props.column.type) {
            case types.Date:
                return (
                    <Date
                        value={props.value as string}
                        datetime={false}
                    />
                )

            case types.DateTime:
                return (
                    <Date
                        value={props.value as string}
                        datetime={true}
                    />
                )

            case types.Currency:
                return (
                    <Numeric
                        value={props.value}
                        type='currency'
                    />
                )

            case types.Number:
                return (
                    <Numeric
                        value={props.value}
                        type='number'
                    />
                )

            case types.Percent:
                return (
                    <Numeric
                        value={props.value}
                        type='number'
                    />
                )

            case types.Chip:
                return (
                    <Chip
                        value={props.value as string}
                        color={props.column.color ? props.data[props.column.color] : undefined}
                    />
                )

            case types.Chips:
                return (
                    <Chips
                        value={props.value}
                        itemId={props.column.itemId}
                        itemLabel={props.column.itemLabel}
                        itemColor={props.column.itemColor}
                    />
                )

            case types.Image:
                return (
                    <Image
                        value={props.value as string}
                    />
                )

            case types.File:
                return (
                    <Links
                        value={props.value}
                        itemId={props.column.itemId}
                        itemLabel={props.column.itemLabel}
                        itemValue={props.column.itemValue}
                        link={props.column.link ? props.data[props.column.link] : undefined}
                    />
                )

            case types.Link:
                return (
                    <Links
                        value={props.value}
                        link={props.column.link ? props.data[props.column.link] : undefined}
                    />
                )

            case types.Phone:
                return (
                    <Phone
                        value={props.value}
                    />
                )

            case types.AcademiaInvoice: {
                return (
                    <Rule
                        value={props.value}
                        color={props.column.color ? props.data[props.column.color] : undefined}
                        type='academia'
                        days={props.column.days ? props.data[props.column.days] : undefined}
                    />
                )
            }

            case types.ProjectInvoice: {
                return (
                    <Rule
                        value={props.value}
                        status={props.column.status ? props.data[props.column.status] : undefined}
                        color={props.column.color ? props.data[props.column.color] : undefined}
                        type='project'
                    />
                )
            }
                
            default:
                return (
                    <Text
                        value={props.value}
                    />
                )
        }
    }
    
    return (
        <React.Fragment>
            {getField()}
        </React.Fragment>
    )
}

export default React.memo(BodyItem)
