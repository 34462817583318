import React from 'react'
import {Navigate} from 'react-router-dom'
import {useAppSelector} from 'app/hooks'
import {access} from 'enums/routes'

const Redirect = () => {
    const user = useAppSelector(state => state.auth.user)
    const type = user ? Object.values(access).find(item => item.id === user.roleId) : undefined

    return (
        <React.Fragment>
            {user && type &&
                <Navigate to={type.redirect} replace={true} />
            }
        </React.Fragment>
    )
}

export default Redirect