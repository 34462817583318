import React, {useMemo, useState} from 'react'
import {useTheme} from '@mui/material/styles'
import {useIntl} from 'react-intl'
import MuiChip from '@mui/material/Chip'
import MuiAutocomplete, {AutocompleteRenderInputParams, AutocompleteRenderGetTagProps} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'

const Added = styled.div`
    font-size: 15px;
    font-style: italic;
    cursor: pointer;
`

const Empty = styled.div`
    font-size: 15px;
    font-style: italic;
`

const Text = styled.span<{textColor: string}>`
    font-size: 13px;
    color: ${props => props.textColor};
`

const Item = styled.li`
    && {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

export type Option = {
    id: number,
    name: string,
    description?: string
}

interface IComponent {
    value: number| number[] | null,
    title?: string,
    helper?: string,
    name: string,
    options: Option[],
    multiple?: boolean,
    disabled?: boolean,
    adding?: boolean,
    size?: 'small',
    onChange: (name: string, value: number | number[] | null) => void
    onCreate?: (name: string, value: string) => void
}

const Autocomplete = (props: IComponent) => {
    const [input, setInput] = useState<string>('')

    const theme = useTheme()
    const intl = useIntl()

    const onChange = (e: any, value: number | number[] | null) => {
        props.onChange(props.name, value)
    }

    const getLabel = (option: number) => {
        const item = props.options.find(item => item.id === option)
        return item ? item.name : ''
    }

    const getInput = (params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            label={props.title}
            helperText={props.helper}
            InputLabelProps={{
                shrink: true
            }}
            FormHelperTextProps={{
                sx: {
                    fontSize: 13,
                    letterSpacing: '0.5px'
                }
            }}  
            size={props.size}
            sx={props.size && {input: {fontSize: 14}}}
        />
    }

    const getChips = (value: number[], getTagProps: AutocompleteRenderGetTagProps) => {
        return value.map((option, index) => {
            const label = getLabel(option)
            return label && <MuiChip
                label={label}
                {...getTagProps({index})}
            />
        })
    }

    const getOption = (params: React.HTMLAttributes<HTMLLIElement>, option: number) => {
        const item = props.options.find(item => item.id === option)

        return (      
            <Item {...params} key={option}>
                {item ? item.name : ''}
                <Text textColor={theme.palette.info.dark}>
                    {item ? item.description : ''}
                </Text>
            </Item>
        )
    }

    const onInputChange = (e: any, value: string) => {
        if (props.adding) {
            setInput(value)
        }
    }

    const onClick = () => {
        if (typeof props.onCreate === 'function') {
            props.onCreate(props.name, input.trim())
        }
    }

    const getEmpty = useMemo(() => {
        if (props.adding && input) {
            return <Added onClick={onClick}>{intl.formatMessage({id: 'autocomplete.Create'}, {name: `${props.title} "${input}"`})}</Added>
        } else {
            return <Empty onClick={onClick}>{intl.formatMessage({id: 'autocomplete.Empty'})}</Empty>
        }
    }, [props.adding, props.title, input])

    const options = props.options.map(item => item.id)

    return (
        <MuiAutocomplete
            fullWidth
            options={options}
            getOptionLabel={getLabel}
            onInputChange={onInputChange}
            onChange={onChange}
            value={props.value}
            renderInput={getInput}
            renderTags={getChips}
            renderOption={getOption}
            noOptionsText={getEmpty}
            multiple={props.multiple}
            disabled={props.disabled}
        />
    )
}

export default React.memo(Autocomplete)
