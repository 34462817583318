import {types} from 'enums/fields'

export const proposalForm = [
    {
        title: 'Sales value',
        type: types.Number,
        name: 'salesValue',
        required: false
    },
    {
        title: 'Invoice Rules',
        type: types.ProjectInvoice,
        name: 'invoiceRules',
        required: false
    }
]

export const expenseForm = [
    {
        title: 'Expense',
        type: types.Chip,
        name: 'id',
        required: false,
        autocomplete: true,
        helper: 'Select an existing expense or fill in the fields below to create a new item'
    },
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        autocompleteDependent: true,
        required: true
    },
    {
        title: 'Price',
        type: types.Number,
        name: 'cost',
        autocompleteDependent: false,
        required: false
    },
    {
        title: 'Units',
        type: types.Number,
        name: 'units',
        autocompleteDependent: false,
        required: false
    }
]