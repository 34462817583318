import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Chip from '@mui/material/Chip'
import PlaylistRemove from '@mui/icons-material/PlaylistRemove'
import Create from '@mui/icons-material/Create'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Group from '@mui/icons-material/Group'
import AttachFile from '@mui/icons-material/AttachFile'
import Grading from '@mui/icons-material/Grading'
import Sell from '@mui/icons-material/Sell'
import EventAvailable from '@mui/icons-material/EventAvailable'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {updateAcademias, deleteAcademias, updateAcademiaFiles} from 'slices/academias'
import {getEntities} from 'slices/entities'
import Card from 'components/Card'
import EditForm from 'components/EditForm'
import Breadcrumbs from 'components/Breadcrumbs'
import {types} from 'enums/fields'
import routes from 'enums/routes'
import {DocumentActions} from 'enums/utils'
import {updateForm} from 'enums/academias/academias'
import {Entity, View} from 'api/enums'

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
`

const chip = {
    width: '180px',
    marginBottom: 2
}

const icon = {
    fontSize: 18
}

const infoConfig = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Sales close date',
            icon: <EventAvailable sx={icon} />,
            type: types.Date,
            value: 'salesCloseDate'
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Price',
            icon: <Sell sx={icon} />,
            type: types.Currency,
            value: 'pricePerTrainee'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
        {
            label: 'Team members',
            icon: <Group sx={icon} />,
            type: types.Chips,
            value: 'teamMembers',
            props: {
                id: 'id',
                text: 'member_name',
                background: 'member_color',
            },
        },
        {
            label: 'Initial session',
            icon: <CalendarMonth sx={icon} />,
            type: types.DateTime,
            value: 'kickoffDate',
        },
        {
            label: 'Initial session link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'kickoffLink'
        },
        {
            label: 'Final session',
            icon: <CalendarMonth sx={icon} />,
            type: types.DateTime,
            value: 'finalSessionDate',
        },
        {
            label: 'Final session link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'finalSessionLink'
        },
        {
            label: 'Survey link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'surveyLink'
        },
        {
            label: 'Confirmation documents',
            icon: <Grading sx={icon}/>,
            type: types.File,
            value: 'docs',
            editable: true,
            props: {
                id: 'fileId',
                text: 'file_name',
                value: 'file_link'
            }
        }
    ]
}

const breadcrumbs = [
    {
        name: 'section',
        path: `/${routes.Academias}`
    },
    {
        name: 'name'
    }
]


const Overview = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    const academia = useAppSelector((state) => state.academias.info)
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])
    const members = useAppSelector(state => state.entities[Entity.teamMember])

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const academiaStatuses = useMemo(() => {
        return statuses.filter(status => {
            const stage = academia ? academia.status_stage : 0

            return status.academia && (stage === status.stage || stage + 1 === status.stage)
        })
    }, [statuses, academia])

    const handleFormOpen = () => {
        dispatch(getEntities({entity: Entity.teamMember}))
        dispatch(getEntities({entity: Entity.projectStatus}))

        setOpenForm(true)
    }

    const handleFormClose = () => {
        setOpenForm(false)
    }

    const handlePopupOpen = () => {
        setOpenPopup(true)
    }

    const handlePopupClose = () => {
        setOpenPopup(false)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}

        if (academia) {
            obj.id = academia.id
            obj.typeId = 1

            dispatch(updateAcademias({data: [{...obj}]}))
        }

        handleFormClose()
    }

    const handleDelete = () => {
        if (academia) {
            const id = academia.id
            dispatch(deleteAcademias({ids: [id]}))
        }

        handlePopupClose()
    }

    const handleUpload = (name: string, value: File[] | number) => {
        if (academia) {
            if (typeof value === 'number') {
                const form = new FormData()

                const data = [
                    {
                        id: academia.id,
                        docs: [value]
                    }
                ]

                form.append('entity', Entity.project)
                form.append('view', View.academiaDetailed)
                form.append('data', JSON.stringify(data))

                dispatch(updateAcademiaFiles({data: form, type: DocumentActions.detach}))
            } else {
                const form = new FormData()

                const data = [
                    {
                        id: academia.id,
                        docs: [...value].map((item: File, index: number) => {
                            const id = `$file=${index + 1}`
                            form.append(id, item)

                            return id
                        })
                    }
                ]

                form.append('entity', Entity.project)
                form.append('view', View.academiaDetailed)
                form.append('data', JSON.stringify(data))

                dispatch(updateAcademiaFiles({data: form, type: DocumentActions.attach}))
            }
        }
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'statusId') {
            return academiaStatuses
        } else if (name === 'teamMembers') {
            return members
        } else {
            return []
        }
    }

    const initialValues = useMemo(() => {
        return {
            name: academia ? academia.name : '',
            starts: academia ? academia.starts : null,
            ends: academia ? academia.ends : null,
            salesCloseDate: academia ? academia.salesCloseDate : null,
            statusId: academia ? academia.statusId : null,
            kickoffDate: academia ? academia.kickoffDate : null,
            kickoffLink: academia ? academia.kickoffLink : '',
            surveyLink: academia ? academia.surveyLink : '',
            pricePerTrainee: academia ? academia.pricePerTrainee : null,
            teamMembers: academia ? academia.teamMembers.map((item: any) => item.teamMemberId) : []
        }
    }, [academia])

    const config = useMemo(() => {
        return academia && academia.accepted ? updateForm.map(item => {
            return item.name === 'starts' || item.name === 'ends' ||
            item.name === 'pricePerTrainee' || item.name === 'salesCloseDate' ? {...item, disabled: true} : item
        }) : updateForm

    }, [academia])
    
    return (
        <div style={{padding: '20px'}}>
           {academia &&
                <React.Fragment>
                    <Top>
                        <Breadcrumbs margin={'0 20px 20px 0'} items={breadcrumbs} data={{name: academia.name, section: 'Academias'}} />
                        <div style={{display: 'flex', overflow: 'hidden'}}>
                            <Chip
                                variant='outlined'
                                color='secondary'
                                label={intl.formatMessage({id: "academia.ChangeAcademia"})}
                                onClick={handleFormOpen}
                                sx={{...chip, marginRight: 2}}
                                icon={<Create />}
                            />
                            <Chip
                                variant='outlined'
                                color='error'
                                label={intl.formatMessage({id: "academia.DeleteAcademia"})}
                                onClick={handlePopupOpen}
                                sx={chip}
                                icon={<PlaylistRemove />}
                            />
                        </div>
                    </Top>
                    {academia &&
                        <Card data={academia} presentation config={infoConfig} onChange={handleUpload} />
                    }
                    <EditForm
                        open={openForm}
                        handleClose={handleFormClose}
                        handleConfirm={handleConfirm}
                        title={intl.formatMessage({id: "academia.ChangeAcademia"})}
                        config={config}
                        getOptions={getOptions}
                        initialValues={initialValues}
                    />
                    <Dialog
                        open={openPopup}
                        onClose={handlePopupClose}
                    >
                        <DialogTitle>
                            {intl.formatMessage({id: "app.ConfirmYourAction"})}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {intl.formatMessage({id: "academia.WantToDeleteAcademia"}, {name: academia.name})}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePopupClose}>{intl.formatMessage({id: "app.Cancel"})}</Button>
                            <Button onClick={handleDelete} variant='contained'>{intl.formatMessage({id: "app.Confirm"})}</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            }
        </div>
    )
}

export default Overview