import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Navigate} from 'react-router-dom'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Lock from '@mui/icons-material/Lock'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {useAppSelector, useAppDispatch} from 'app/hooks'
import {getLogin} from 'slices/auth'
import routes from 'enums/routes'
import logo from 'images/logo.png'
import login from 'images/login.jpg'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Fields = styled.form<{color: string}>`
    background-color: ${props => props.color};
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
`

const Form = styled.div`
    max-width: 800px;
    max-height: 400px;
    margin: auto 30px;
    align-self: center;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    border: 1px solid #d2a078;
`

const Text = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 1px;
`

const Img = styled.img<{breakpoint: number}>`
    object-fit: cover;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    object-fit: contain;

    ${props => `
        @media (max-width: ${props.breakpoint}px) {
            display: none;
        }
    `}
`

const input = {
    width: '100%',
    marginBottom: '30px'
}

const button = {
    width: '100%'
}

const Signin = () => {
    const [data, setData] = useState({
        login: '',
        pass: ''
    })

    const [hidden, setHidden] = useState(true)

    const authorized = useAppSelector(state => state.auth.authorized)

    const dispatch = useAppDispatch()
    const intl = useIntl()
    const theme = useTheme()

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        dispatch(getLogin({
            login: data.login.trim(),
            pwd: data.pass.trim()
        }))
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const onClick = (e: React.MouseEvent) => {
        setHidden(hidden => !hidden)
    }

    if (authorized) {
        return <Navigate to={routes.Redirect} replace={true} />
    }

    return (
        <Container>
            <img src={logo} style={{margin: '30px', height: '40px', alignSelf: 'flex-start'}} />
            <Form>
                <Fields onSubmit={onSubmit} method='post' color={theme.palette.primary.light}>
                    <div style={{padding: '40px'}}>
                        <Text>Bem-vindo</Text>
                        <TextField
                            onChange={onChange}
                            name='login'
                            variant="outlined"
                            label={intl.formatMessage({id: "login.Login"})}
                            sx={input}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            onChange={onChange}
                            name='pass'
                            variant="outlined"
                            label={intl.formatMessage({id: "login.Password"})}
                            sx={input}
                            type={hidden ? 'password' : 'text'}
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onClick}
                                        >
                                            {hidden ? <Visibility /> : <VisibilityOff />}
                                        </IconButton> 
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            type='submit'
                            sx={button}
                            onClick={onSubmit}
                        >
                            {intl.formatMessage({id: "login.SignIn"})}
                        </Button>
                    </div>
                </Fields>
                <Img src={login} breakpoint={theme.breakpoints.values.md} />
            </Form>
        </Container>
    )
}

export default Signin