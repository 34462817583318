import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 400,
        type: types.Text
    },
    {
        name: 'deadline',
        title: 'Date',
        width: 200,
        type: types.Date
    },
    {
        name: 'status_name',
        title: 'Status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'teamMember_name',
        title: 'Assigned to',
        width: 200,
        type: types.Chip,
        color: 'teamMember_color'
    },
    {
        name: 'project_name',
        title: 'Project',
        width: 300,
        type: types.Text
    },
    {
        name: 'comment',
        title: 'Comment',
        width: 400,
        type: types.Text
    }
]

export const filter = [
    {
        title: 'Filter by Status',
        name: 'statusId',
        type: types.Chips,
        data: Entity.taskStatus,
        default: [1, 2]
    },
    {
        title: 'Filter by Assigned to',
        name: 'teamMemberId',
        type: types.Chip,
        data: Entity.teamMember
    },
    {
        title: 'Filter by Project',
        name: 'projectId',
        type: types.Chip,
        data: Entity.project
    }
]

export const entities = [Entity.teamMember, Entity.taskStatus, Entity.project]