import React, {useState, useRef} from 'react'
import styled from '@emotion/styled'
import MuiChip from '@mui/material/Chip'
import Popover from '@mui/material/Popover'

interface IComponent {
    value: any[]
    itemId?: string
    itemLabel?: string
    itemColor?: string
}

const chip = {
    margin: '0 10px',
    minWidth: 0
}

const chips = {
    margin: '5px',
    maxWidth: 'calc(100% - 10px)',
    flexShrink: 0
}

const Tip = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    border-radius: 50%;
    background: #c8e6c9;
    color: #388e3c;
    font-size: 10px;
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0.5px;
    margin-right: 10px;
`

const Chips = (props: IComponent) => {
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const width = useRef(0)
    
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()

        width.current = e.currentTarget.parentElement!.clientWidth
        setOpen(e.currentTarget.parentElement)
    }

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation()

        setOpen(null)
    }
    
    const hidden = props.value ? props.value.slice(1) : []
    const background = props.value && !!props.value.length && props.itemColor ? props.value[0][props.itemColor] : ''
    
    return (
        <React.Fragment>
            {props.value && !!props.value.length && props.itemId && props.itemLabel &&
                <MuiChip
                    label={props.value[0][props.itemLabel]}
                    sx={{...chip, background: background}}
                />
            }
            {!!hidden.length &&
                <Tip onClick={onClick}>
                    {'+' + hidden.length}
                </Tip>
            }
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: width.current,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: '5px'
                        }
                    }
                }}
            >
                {props.value.map(item =>
                    props.itemId && props.itemLabel &&
                        <MuiChip
                            key={item[props.itemId]}
                            label={item[props.itemLabel]}
                            sx={{...chips, background: props.itemColor ? item[props.itemColor] : ''}}
                        />
                )}
            </Popover>
        </React.Fragment>
    )
}

export default React.memo(Chips)
