import React from 'react'
import MuiChip from '@mui/material/Chip'

interface IComponent {
    value: string
    color: string
}

const style = {
    margin: '0 10px',
    minWidth: 0
}

const Chip = (props: IComponent) => {
    return (
        <React.Fragment>
            {props.value &&
                <MuiChip
                    label={props.value}
                    sx={{...style, background: props.color || ''}}
                />
            }
        </React.Fragment>
    )
}

export default React.memo(Chip)
