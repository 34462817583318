import React from 'react'
import {Link} from 'react-router-dom'
import styled from '@emotion/styled'

interface IComponent {
    value: any
    link?: string
    itemId?: string
    itemLabel?: string
    itemValue?: string
}

const Item = styled(Link)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 10px;
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid;
    line-height: 20px;
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.5px;
    padding-right: 5px;
`

const Links = (props: IComponent) => {
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }

    return (
        <React.Fragment>
            {Array.isArray(props.value) ? props.value.map((item: any) =>
                props.itemId && props.itemLabel && props.itemValue &&
                <Item key={item[props.itemId]} to={item[props.itemValue]} target='_blank' onClick={onClick}>
                    {item[props.itemLabel] + ' '}
                </Item>
            ) :
            props.link && <Item to={props.link} target='_blank' onClick={onClick}>
                {props.value + ' '}
            </Item>}
        </React.Fragment>
    )
}

export default React.memo(Links)
