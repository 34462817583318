import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'area_name',
        title: 'Area',
        width: 300,
        type: types.Chip,
        color: 'area_color'
    },
    {
        name: 'trainer_name',
        title: 'Trainer',
        width: 200,
        type: types.Chip,
        color: ''
    }
]

export const formConfig = [
    {
        title: 'Area',
        type: types.Chip,
        name: 'areaId',
        required: true
    },
    {
        title: 'Trainer',
        type: types.Chip,
        name: 'trainerId',
        required: false,
        optionsDependency: 'areaId'
    }
]

export const filterConfig = [
    {
        title: 'Filter by Area',
        name: 'areaId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainer',
        name: 'trainerId',
        type: types.Chip
    }
]