import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute, Entity, View} from 'api/enums'
import {IReqEntityIds, IReqEntityData, IReqEntity, IReqActionIds} from 'api/interfaces'
import api from 'utils/api'

type Entities = any[]

export type Expense = {
    id: number
    name: string
    cost: number | null
}

type Task = {
    id: number
    name: string
    statusId: number | null
}

type Issue = {
    id: number
    name: string
    deadline: string | null
    projectId: number
    statusId: number
    teamMemberId: number | null
}

type Client = {
    id: number
    name: string
}

type Contact = {
    id: number
    name: string
    email: string
    phone: string
    clientId: number
}

type Member = {
    id: number
    name: string
    color: string
}

type Type = {
    id: number
    name: string
    color: string
    protected: boolean,
    project: boolean,
    academia: boolean
}

type ProjectInvoice = {
    id: number
    ruleId: number
    rule_projectId: number
    rule_project_id: number,
    rule_project_client_id: number
    rule_project_client_name: string
    rule_project_commercialOwnerId: number
    rule_project_contactId: number
    rule_project_context: string
    rule_project_ends: string | null
    rule_project_kickoffDate: string | null
    rule_project_kickoffLink: string
    rule_project_name: string
    rule_project_participants: string
    rule_project_salesValue: string
    rule_project_starts: string | null
    rule_project_statusId: number
    rule_project_status_color: string
    rule_project_status_invoiceable: boolean
    rule_project_status_name: string
    rule_project_status_protected: boolean
    rule_project_typeId: number
    rule_statusId: number
    rule_status_color: string
    rule_status_invoiceable: boolean
    rule_status_name: string
    rule_value: string
    statusId: number
    status_color: string
    status_id: number
    status_name: string
    value: string
}

type InvoiceStatus = {
    id: number
    name: string
    color: string
}

type AcademiaInvoice = {
    id: number
    value: string
    percent: string
    daysAfter: number
    dueDate: string
    projectTraineeId: number
    statusId: number
    item_id: number
    item_presentationVideo: string
    item_finalReport: string
    item_enrollDate: string
    item_projectId: number
    item_traineeId: number
    item_project_id: number
    item_project_name: string
    item_project_starts: string
    item_project_ends: string
    item_project_participants: string
    item_project_context: string
    item_project_kickoffDate: string
    item_project_kickoffLink: string
    item_project_salesValue: string
    item_project_commissionValue: string
    item_project_accepted: boolean
    item_project_typeId: number
    item_project_statusId: number
    item_project_clientId: number | null
    item_project_contactId: number | null
    item_project_commercialOwnerId: number | null
    item_trainee_id: number
    item_trainee_name: string
    item_trainee_photo: string
    item_trainee_phone: string
    item_trainee_email: string
    item_trainee_role: string
    item_trainee_clientId: null
    status_id: number
    status_name: string
    status_color: string
}

type Area = {
    id: number
    name: string
    starts: string
    ends: string
    participants?: string
    kickoffDate: string
    kickoffLink: string
    context?: string
    statusId?: number
    teamMembers?: number[]
}

type ProjectStatus = {
    id: number
    academia: boolean
    color: string
    invoiceable: boolean
    name: string
    protected: boolean
    stage: number
}

type TaskStatus = {
    id: number
    color: string
    name: string
    protected: boolean
}

type SessionStatus = {
    id: number
    color: string
    name: string
    protected: boolean
    stage: number
}

type Project = {
    id: number
    name: string
    starts: string
    ends: string
    [key: string]: any
}

type Trainer = {
    id: number
    name: string
    email: string
    phone: string
    areas: any[]
    hourlyPrice: number | null
    joinLink: string
    photoId: number | null,
    photo_name: string | null,
    photo_url: string | null
}

type Trainee = {
    id: number
    name: string
    photo: string
    phone: string
    email: string
    role: string
    company_id: number | null
    company_name: string
    clientId: number | null
    photo_url: string | null
    photo_name: string | null
    photoId: number | null
}

type SessionType = {
    id: number
    name: string
    color: string
}

type User = {
    id: number
    email: string
    login: string
    name: string
    role_id: number
    role_name: string
}

type Role = {
    id: number
    mask: number
    name: string
}

interface IState {
    [Entity.frequentExpense]: Expense[],
    [Entity.defaultTask]: Task[],
    [Entity.projectTask]: Issue[],
    [Entity.client]: Client[],
    [Entity.clientContact]: Contact[],
    [Entity.teamMember]: Member[],
    [Entity.projectType]: Type[],
    [Entity.projectInvoice]: ProjectInvoice[],
    [Entity.invoiceStatus]: InvoiceStatus[],
    [Entity.academiaInvoice]: AcademiaInvoice[],
    [Entity.area]: Area[],
    [Entity.projectStatus]: ProjectStatus[],
    [Entity.taskStatus]: TaskStatus[],
    [Entity.sessionStatus]: SessionStatus[]
    [Entity.project]: Project[],
    [Entity.trainer]: Trainer[],
    [Entity.trainee]: Trainee[],
    [Entity.sessionType]: SessionType[],
    [Entity.role]: Role[],
    [Entity.smsTemplate]: any,
    [View.projects]: Project[],
    [View.academias]: Project[],
    [View.users]: User[],
    [View.trainerSessionsReserved]: any,
    [View.freeTrainerSlots]: any,
    [View.commissions]: any,
    [Entity.excelDump]: any,
    requests: {[key: string]: boolean},
    pending: boolean
}

const initialState: IState = {
    [Entity.frequentExpense]: [],
    [Entity.defaultTask]: [],
    [Entity.projectTask]: [],
    [Entity.client]: [],
    [Entity.clientContact]: [],
    [Entity.teamMember]: [],
    [Entity.projectType]: [],
    [Entity.projectInvoice]: [],
    [Entity.invoiceStatus]: [],
    [Entity.academiaInvoice]: [],
    [Entity.area]: [],
    [Entity.projectStatus]: [],
    [Entity.taskStatus]: [],
    [Entity.sessionStatus]: [],
    [Entity.project]: [],
    [Entity.trainer]: [],
    [Entity.trainee]: [],
    [Entity.sessionType]: [],
    [Entity.role]: [],
    [Entity.smsTemplate]: [],
    [View.projects]: [],
    [View.academias]: [],
    [View.users]: [],
    [View.trainerSessionsReserved]: [],
    [View.freeTrainerSlots]: [],
    [View.commissions]: [],
    [Entity.excelDump]: [],
    requests: {},
    pending: false
}

export const getEntities = createAsyncThunk('entities/getEntities', async (data: IReqEntity) => {
    const response = await api(ApiRoute.getall, data)
    return response.data
}, {condition: (data, {getState}) => {
        const {entities} = getState() as {entities: IState}
        const key = data.entity || data.view
        const pending = entities.requests[key as string]

        if (pending) {
            return false
        }
    }
})

export const createEntities = createAsyncThunk('entities/createEntities', async (data: IReqEntityData<any>, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.create, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const createImageEntities = createAsyncThunk('entities/createImageEntities', async (data: IReqEntityData<any>) => {
    const response = await api(ApiRoute.createwfiles, data.data)
    return response.data
})

export const updateEntities = createAsyncThunk('entities/updateEntities', async (data: IReqEntityData<Entities>) => {
    const response = await api(ApiRoute.update, data)
    return response.data
})

export const updateImageEntities = createAsyncThunk('entities/updateImageEntities', async (data: IReqEntityData<Entities>) => {
    const response = await api(ApiRoute.updatewfiles, data.data)
    return response.data
})

export const deleteEntities = createAsyncThunk('entities/deleteEntities', async (ids: IReqEntityIds) => {
    const response = await api(ApiRoute.delete, ids)
    return response.data
})

export const actEntity = createAsyncThunk('entities/actEntity', async (data: IReqActionIds & IReqEntity) => {
    const response = await api(ApiRoute.action, data)
    return response.data
})

export const createBackups = createAsyncThunk('entities/createBackups', async () => {
    const response = await api(ApiRoute.createexceldump)
    return response.data
})

export const deleteBackups = createAsyncThunk('entities/deleteBackups', async (data: {ids: number[]}) => {
    const response = await api(ApiRoute.deleteexceldumps, data)
    return response.data
})

export const entitiesSlice = createSlice({
    name: 'entities',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getEntities.pending, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                state.pending = true
                state.requests[key as string] = true
            })
            .addCase(getEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                state[key as keyof typeof initialState] = action.payload
                state.pending = false
                state.requests[key as string] = false
            })
            .addCase(getEntities.rejected, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                state.pending = false
                state.requests[key as string] = false
            })

            .addCase(createEntities.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState] as Entities
                entities.push(...action.payload)

                state[key as keyof typeof initialState] = entities as any
                state.pending = false
            })
            .addCase(createEntities.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createImageEntities.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createImageEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState] as Entities
                entities.push(...action.payload)

                state[key as keyof typeof initialState] = entities as any
                state.pending = false
            })
            .addCase(createImageEntities.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteEntities.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState]
                const data = entities.filter((entity: any) => entity.id && !action.meta.arg.ids.includes(entity.id))
                state[key as keyof typeof initialState] = data as any
                state.pending = false
            })
            .addCase(deleteEntities.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateEntities.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState] as Entities

                action.payload.forEach((item: any) => {
                    const index = entities.findIndex(entity => entity.id === item.id)
                    entities[index] = item
                })

                state[key as keyof typeof initialState] = entities as any
                state.pending = false
            })
            .addCase(updateEntities.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateImageEntities.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateImageEntities.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState] as Entities

                action.payload.forEach((item: any) => {
                    const index = entities.findIndex(entity => entity.id === item.id)
                    entities[index] = item
                })

                state[key as keyof typeof initialState] = entities as any
                state.pending = false
            })
            .addCase(updateImageEntities.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(actEntity.pending, (state, action) => {
                state.pending = true
            })
            .addCase(actEntity.fulfilled, (state, action) => {
                const key = action.meta.arg.entity || action.meta.arg.view
                const entities = state[key as keyof typeof initialState] as Entities
                
                action.payload.forEach((item: any) => {
                    const index = entities.findIndex(entity => entity.id === item.id)
                    entities[index] = item
                })

                state[key as keyof typeof initialState] = entities as any
                state.pending = false
            })
            .addCase(actEntity.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createBackups.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createBackups.fulfilled, (state, action) => {
                const data = state[Entity.excelDump]
                data.push(...action.payload)
                state[Entity.excelDump] = data
                state.pending = false
            })
            .addCase(createBackups.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteBackups.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteBackups.fulfilled, (state, action) => {
                const data = state[Entity.excelDump].filter((entity: any) => entity.id && !action.meta.arg.ids.includes(entity.id))
                state[Entity.excelDump] = data
                state.pending = false
            })
            .addCase(deleteBackups.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default entitiesSlice