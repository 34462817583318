import {createSlice} from '@reduxjs/toolkit'

import {ApiErrorCode, Action} from 'api/enums'

import {getLogin, changePass} from 'slices/auth'

import {getProjects, createProjects, updateProjects, deleteProjects,
    getProjectSessions, createProjectSessions, deleteProjectSessions, updateProjectSessions,
    getParticipants, createParticipants, updateParticipants, deleteParticipants, createParticipantsImage, updateParticipantsImage,
    getTasks, createTasks, updateTasks, deleteTasks,
    getProjectAppointments, createProjectAppointments, updateProjectAppointments, deleteProjectAppointments,
    getExpenseExtras, createExpenseExtras, updateExpenseExtras, deleteExpenseExtras,
    getExpenseTrainers, updateExpenseTrainers, updateProjectFiles} from 'slices/projects'

import {getForecast} from 'slices/forecast'

import {createAcademias, deleteAcademias, getAcademias, updateAcademias,
    getAcademiaSessions, createAcademiaSessions, updateAcademiaSessions, deleteAcademiaSessions,
    getAcademiaAppointments, createAcademiaAppointments, updateAcademiaAppointments, deleteAcademiaAppointments,
    getAcademiaParticipants, createAcademiaParticipants, updateAcademiaParticipants, deleteAcademiaParticipants,
    createAcademiaParticipantsImage, updateAcademiaParticipantsImage, updateAcademiaFiles,
    getAcademiaTasks, createAcademiaTasks, updateAcademiaTasks, deleteAcademiaTasks, getAcademiaSessionsReserved} from 'slices/academias'

import {getEntities, createEntities, createImageEntities, updateEntities, updateImageEntities, deleteEntities, actEntity, deleteBackups, createBackups} from 'slices/entities'

import {getSheet} from 'slices/sheets'

import {getAcademias as getTraineeAcademias, getAreas as getTraineeAreas, getTimeslots as getTraineeTimeslots,
    bookSession, cancelSession, updateFiles as updateTraineeFiles, updateInformation as updateTraineeInformation} from 'slices/trainee'

import {getTrainerTimeslots, updateTrainerTimeslot, getTrainings,
    getTrainerSessions, getTrainerBookings, updateTrainerSessions} from 'slices/trainer'

import messages from 'enums/messages'

const Errors = {
    [ApiErrorCode.PROJECT_HAS_NO_SALES_VALUE]: messages.ProjectHasNoSalesValue,
    [ApiErrorCode.PROJECT_HAS_NO_INVOICE_RULES]: messages.ProjectHasNoInvoiceRules,
    [ApiErrorCode.PROJECT_STATUS_ROLLBACK]: messages.ProjectStatusRolledBack,
    [ApiErrorCode.ACADEMIA_HAS_NO_TRAINEES]: messages.AcademiaHasNoTrainees,
    [ApiErrorCode.SESSION_OVERLAP]: messages.SessionsOverlap,
    [ApiErrorCode.LOGIN_NOT_UNIQUE]: messages.LoginExists,
    [ApiErrorCode.EMAIL_NOT_UNIQUE]: messages.EmailExists
}

const Actions = {
    [Action.sendprojectinvoice]: messages.DocumentSendingSuccess,
    [Action.sendacademiainvoice]: messages.DocumentSendingSuccess,
    [Action.createusertrainer]: messages.AccCreatingSuccess,
    [Action.createusertrainee]: messages.AccCreatingSuccess,
    [Action.createuserteammember]: messages.AccCreatingSuccess,
    [Action.resetpassword]: messages.ResetPassSuccess
}

const getText = (code: number, primary?: string, secondary?: string) => {
    if (code === ApiErrorCode.SESSION_OVERLAP && primary && secondary) {
        return Errors[code as keyof typeof Errors] + ` ${primary} - ${secondary} `
    }

    return Errors[code as keyof typeof Errors]
}

interface IMessage {
    text: string,
    variant: 'error' | 'info' | 'success' | 'warning',
    open: boolean
}

const initialState: IMessage = {
    text: messages.RegularError,
    variant: 'error',
    open: false
}

const messageSlice = createSlice({
    name: 'message',
    initialState: initialState,
    reducers: {
        messageReset: state => {
            state.open = false
        },
        messageSet: (state, action) => {
            state.text = action.payload.text
            state.variant = action.payload.variant
            state.open = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogin.fulfilled, (state, action) => {
                if (!action.payload.user) {
                    state.text = messages.WrongCredentials
                    state.variant = 'error'
                    state.open = true
                }
            })
            .addCase(getLogin.rejected, (state) => {
                state.text = messages.LoginError
                state.variant = 'error'
                state.open = true
            })

            .addCase(changePass.fulfilled, (state, action) => {
                state.text = messages.ChangePassSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(changePass.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const text = code && code === ApiErrorCode.BAD_ARGUMENTS ?  messages.ChangePassError : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            /* Projects */

            .addCase(getProjects.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getProjectSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createProjectSessions.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createProjectSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteProjectSessions.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteProjectSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateProjectSessions.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateProjectSessions.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const data = payload ? payload.errordata[0] : undefined
                const text = code ? getText(code, data.projectArea_project_name, data.projectArea_area_name) : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(createProjects.fulfilled, (state, action) => {
                state.text = messages.ProjectsCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createProjects.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const data = payload ? payload.errordata[0] : undefined
                const text = code ? getText(code, data.projectArea_project_name, data.projectArea_area_name) : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteProjects.fulfilled, (state, action) => {
                state.text = messages.ProjectsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteProjects.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateProjects.fulfilled, (state, action) => {
                state.text = messages.ProjectsUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateProjects.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const text = code ? Errors[code as keyof typeof Errors] : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(getParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createParticipantsImage.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createParticipantsImage.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateParticipantsImage.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateParticipantsImage.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createTasks.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteTasks.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateTasks.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getProjectAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createProjectAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createProjectAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteProjectAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteProjectAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateProjectAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateProjectAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getExpenseTrainers.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateExpenseTrainers.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getExpenseExtras.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createExpenseExtras.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteExpenseExtras.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateExpenseExtras.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateProjectFiles.fulfilled, (state, action) => {
                state.text = messages.DocumentsUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateProjectFiles.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Academias */

            .addCase(getAcademias.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademias.fulfilled, (state, action) => {
                state.text = messages.AcademiasCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademias.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteAcademias.fulfilled, (state, action) => {
                state.text = messages.AcademiasDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteAcademias.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademias.fulfilled, (state, action) => {
                state.text = messages.AcademiasUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademias.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const text = code ? Errors[code as keyof typeof Errors] : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(getAcademiaSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademiaSessions.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademiaSessions.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const data = payload ? payload.errordata[0] : undefined
                const text = code ? getText(code, data.projectArea_project_name, data.projectArea_area_name) : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteAcademiaSessions.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteAcademiaSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaSessions.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaSessions.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const data = payload ? payload.errordata[0] : undefined
                const text = code ? getText(code, data.projectArea_project_name, data.projectArea_area_name) : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(getAcademiaAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademiaAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademiaAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteAcademiaAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteAcademiaAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaAppointments.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaAppointments.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getAcademiaParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademiaParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademiaParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademiaParticipantsImage.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademiaParticipantsImage.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteAcademiaParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteAcademiaParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaParticipants.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaParticipants.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaParticipantsImage.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaParticipantsImage.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getAcademiaTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createAcademiaTasks.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createAcademiaTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteAcademiaTasks.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteAcademiaTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaTasks.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaTasks.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateAcademiaFiles.fulfilled, (state, action) => {
                state.text = messages.DocumentsUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateAcademiaFiles.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getAcademiaSessionsReserved.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Entities */

            .addCase(getEntities.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createEntities.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createEntities.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const text = code ? Errors[code as keyof typeof Errors] : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(createImageEntities.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createImageEntities.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteEntities.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteEntities.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateEntities.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateEntities.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateImageEntities.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateImageEntities.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(actEntity.fulfilled, (state, action) => {
                const type = action.meta.arg.action
                const text = type ? Actions[type as keyof typeof Actions] : messages.ActionSuccess
                state.text = text
                state.variant = 'success'
                state.open = true
            })
            .addCase(actEntity.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(deleteBackups.fulfilled, (state, action) => {
                state.text = messages.RecordsDeletingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(deleteBackups.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(createBackups.fulfilled, (state, action) => {
                state.text = messages.RecordCreatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(createBackups.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Forecast */

            .addCase(getForecast.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Sheet */

            .addCase(getSheet.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Trainee app */

            .addCase(getTraineeAreas.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTraineeTimeslots.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTraineeAcademias.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(bookSession.fulfilled, (state, action) => {
                state.text = messages.SessionBook
                state.variant = 'success'
                state.open = true
            })
            .addCase(bookSession.rejected, (state, action) => {
                const payload = action.payload as any
                const code = payload ? payload.errorcode : undefined
                const text = code && code === ApiErrorCode.SESSION_OVERLAP ?  messages.SessionIsNotAvailable : messages.RegularError
                state.text = text
                state.variant = 'error'
                state.open = true
            })

            .addCase(cancelSession.fulfilled, (state, action) => {
                state.text = messages.SessionCancel
                state.variant = 'success'
                state.open = true
            })
            .addCase(cancelSession.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateTraineeFiles.fulfilled, (state, action) => {
                state.text = messages.DocumentsUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateTraineeFiles.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateTraineeInformation.fulfilled, (state, action) => {
                state.text = messages.InformationUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateTraineeInformation.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            /* Trainer app */

            .addCase(getTrainings.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTrainerTimeslots.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateTrainerTimeslot.fulfilled, (state, action) => {
                state.text = messages.TimeslotsUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateTrainerTimeslot.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTrainerSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(getTrainerBookings.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })

            .addCase(updateTrainerSessions.fulfilled, (state, action) => {
                state.text = messages.RecordUpdatingSuccess
                state.variant = 'success'
                state.open = true
            })
            .addCase(updateTrainerSessions.rejected, (state) => {
                state.text = messages.RegularError
                state.variant = 'error'
                state.open = true
            })
    }
})

export const {messageReset, messageSet} = messageSlice.actions

export default messageSlice
