import {configureStore} from '@reduxjs/toolkit'
import auth from 'slices/auth'
import projects from 'slices/projects'
import message from 'slices/message'
import academias from 'slices/academias'
import forecast from 'slices/forecast'
import trainee from 'slices/trainee'
import trainer from 'slices/trainer'
import entities from 'slices/entities'
import sheets from 'slices/sheets'

export const store = configureStore({
    reducer: {
        auth: auth.reducer,
        projects: projects.reducer,
        message: message.reducer,
        academias: academias.reducer,
        forecast: forecast.reducer,
        trainee: trainee.reducer,
        trainer: trainer.reducer,
        entities: entities.reducer,
        sheets: sheets.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch