import React from 'react'
import MuiChip from '@mui/material/Chip'
import MuiAutocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'
import {Color as Option} from 'components/EditForm/interfaces'

const Item = styled.li`
    && {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

interface IComponent {
    value: string,
    title?: string,
    helper?: string,
    name: string,
    options: Option[],
    disabled?: boolean,
    size?: 'small',
    onChange: (name: string, value: string) => void
}

const Color = (props: IComponent) => {
    const onChange = (e: any, value: string | null) => {
        props.onChange(props.name, value || '')
    }

    const getLabel = (option: string) => {
        const item = props.options.find(item => item.color === option)
        return item ? item.name : ''
    }

    const getInput = (params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            label={props.title}
            helperText={props.helper}
            InputLabelProps={{
                shrink: true
            }}
            FormHelperTextProps={{
                sx: {
                    fontSize: 13,
                    letterSpacing: '0.5px'
                }
            }}  
            size={props.size}
            sx={props.size && {input: {fontSize: 14}}}
        />
    }

    const getOption = (params: React.HTMLAttributes<HTMLLIElement>, option: string) => {
        const item = props.options.find(item => item.color === option)

        return (      
            <Item {...params} key={option}>
                {item &&
                    <React.Fragment>
                        {item.color ?
                            <MuiChip label={item.name} sx={{background: item.color, margin: '5px 0'}} /> : item.name
                        }
                    </React.Fragment>
                }
            </Item>
        )
    }

    const options = props.options.map(item => item.color)

    return (
        <MuiAutocomplete
            fullWidth
            options={options}
            getOptionLabel={getLabel}
            onChange={onChange}
            value={props.value || null}
            renderInput={getInput}
            renderOption={getOption}
            disabled={props.disabled}
        />
    )
}

export default React.memo(Color)
