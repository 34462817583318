import axios from 'axios'

const getUrl = (): string | null => {
    const env = (window as any)?._env_
    if (env && env.INSTANCES && env.PRIMARY_INSTANCE) {
        const instances: any = {}
        env.INSTANCES.split(';').map((i: string) => {
            const split = i.split('=')
            instances[split[0]] = split[1]
        })
        return instances[env.PRIMARY_INSTANCE] || null
    }
    return null
}

// const SERVER_URL = getUrl() || 'https://artesao.eu:8014/'

const SERVER_URL = 'https://api.carlarocha.eu:8010'

axios.defaults.withCredentials = true

const api = <T, D>(path: string, data?: T) => {
    return axios({
        method: 'post',
        baseURL: SERVER_URL,
        url: path,
        data: data
    })
}

export default api