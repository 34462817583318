import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import {IReqId, IReqData, IProject, IReqIds, IAcademiaSession, IAcademiaArea,
    IProjectTrainee, IProjectTraineeCreate, IProjectTask} from 'api/interfaces'
import api from 'utils/api'

type Task = {
    id: number
    name: string
    deadline: string | null
    projectId: number
    statusId: number
    teamMemberId: number | null,
    [key: string]: any
}

type Participants = {
    id: number
    projectId: number
    traineeId: number | null
    trainee_id: number | null
    presentationVideo: string
    finalReport: string
    trainee_name: string
    trainee_photo_name: string | null
    trainee_phone: string
    trainee_email: string
    trainee_role: string
    trainee_company_name: string
    trainee_clientId: number | null
    [key: string]: any
}

type Area = {
    id: number,
    area_name: string,
    projectId: number
    areaId: number,
    trainer_name: string,
    [key: string]: any
}

type Session = {
    id: number,
    starts: string,
    projectAreaId: number,
    ends: string,
    statusId: number | null
    traineeId: number | null,
    [key: string]: any
}

type Academia = {
    id: number,
    name: string,
    [prop: string]: any
}

interface IState {
    info: Academia | null
    academias: Academia[]
    sessions: Session[]
    appointments: Area[]
    participants: Participants[]
    tasks: Task[]
    bookings: Session[]
    requests: {[key: string]: boolean}
    pending: boolean,
    notExist: boolean
}

const initialState: IState = {
    info: null,
    academias: [],
    sessions: [],
    appointments: [],
    participants: [],
    tasks: [],
    bookings: [],
    requests: {},
    pending: false,
    notExist: false
}

export const getAcademias = createAsyncThunk('academia/getAcademias', async () => {
    const response = await api(ApiRoute.getacademias)
    return response.data
}, {condition: (data, {getState}) => {
        const {academias} = getState() as {academias: IState}
        const pending = academias.requests.academias

        if (pending) {
            return false
        }
    }
})

export const createAcademias = createAsyncThunk('academia/createAcademias', async (data: IReqData<IProject>) => {
    const response = await api(ApiRoute.createacademias, data)
    return response.data
})

export const updateAcademias = createAsyncThunk('academia/updateAcademias', async (data: IReqData<IProject>, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.updateacademias, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const deleteAcademias = createAsyncThunk('academia/deleteAcademias', async (data: IReqIds) => {
    const response = await api(ApiRoute.deleteacademias, data)
    return response.data
})

export const getAcademia = createAsyncThunk('academia/getAcademia', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademia, data)
    return response.data
})

export const getAcademiaSessions = createAsyncThunk('academia/getAcademiaSessions', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademiasessions, data)
    return response.data
})

export const createAcademiaSessions = createAsyncThunk('academia/createAcademiaSessions', async (data: IReqData<IAcademiaSession>, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.createacademiasessions, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const updateAcademiaSessions = createAsyncThunk('academia/updateAcademiaSessions', async (data: IReqData<IAcademiaSession>, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.updateacademiasessions, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const deleteAcademiaSessions = createAsyncThunk('academia/deleteAcademiaSessions', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteacademiasessions, ids)
    return response.data
})

export const getAcademiaAppointments = createAsyncThunk('academia/getAcademiaAppointments', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademiaareas, data)
    return response.data
})

export const createAcademiaAppointments = createAsyncThunk('academia/createAcademiaAppointments', async (data: IReqData<IAcademiaArea>) => {
    const response = await api(ApiRoute.createacademiaareas, data)
    return response.data
})

export const updateAcademiaAppointments = createAsyncThunk('academia/updateAcademiaAppointments', async (data: IReqData<IAcademiaArea>) => {
    const response = await api(ApiRoute.updateacademiaareas, data)
    return response.data
})

export const deleteAcademiaAppointments = createAsyncThunk('academia/deleteAcademiaAppointments', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteacademiaareas, ids)
    return response.data
})

export const getAcademiaParticipants = createAsyncThunk('academia/getParticipants', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojecttrainees, data)
    return response.data
})

export const createAcademiaParticipants = createAsyncThunk('academia/createParticipants', async (data: IReqData<IProjectTraineeCreate>) => {
    const response = await api(ApiRoute.createprojecttrainees, data)
    return response.data
})

export const updateAcademiaParticipants = createAsyncThunk('academia/updateParticipants', async (data: IReqData<IProjectTrainee>) => {
    const response = await api(ApiRoute.updateprojecttrainees, data)
    return response.data
})

export const createAcademiaParticipantsImage = createAsyncThunk('academia/createParticipantsImage', async (data: FormData) => {
    const response = await api(ApiRoute.createwfiles, data)
    return response.data
})

export const updateAcademiaParticipantsImage = createAsyncThunk('academia/updateParticipantsImage', async (data: FormData) => {
    const response = await api(ApiRoute.updatewfiles, data)
    return response.data
})

export const deleteAcademiaParticipants = createAsyncThunk('academia/deleteParticipants', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteprojecttrainees, ids)
    return response.data
})

export const getAcademiaTasks = createAsyncThunk('academia/getAcademiaTasks', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojecttasks, data)
    return response.data
})

export const createAcademiaTasks = createAsyncThunk('academia/createAcademiaTasks', async (data: IReqData<IProjectTask>) => {
    const response = await api(ApiRoute.createprojecttasks, data)
    return response.data
})

export const updateAcademiaTasks = createAsyncThunk('academia/updateAcademiaTasks', async (data: IReqData<IProjectTask>) => {
    const response = await api(ApiRoute.updateprojecttasks, data)
    return response.data
})

export const deleteAcademiaTasks = createAsyncThunk('academia/deleteAcademiaTasks', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteprojecttasks, ids)
    return response.data
})

export const updateAcademiaFiles = createAsyncThunk('academia/updateAcademiaFiles', async (data: {data: FormData, type: string}) => {
    const response = data.type === 'attach' ? await api(ApiRoute.attachwfiles, data.data) :
        await api(ApiRoute.detachwfiles, data.data)
    return response.data
})

export const getAcademiaSessionsReserved = createAsyncThunk('academia/getAcademiaSessionsReserved', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademiasessionsreserved, data)
    return response.data
})

export const academiasSlice = createSlice({
    name: 'academias',
    initialState,
    reducers: {
        resetAcademia: state => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAcademias.pending, (state, action) => {
                state.pending = true
                state.requests.academias = true
            })
            .addCase(getAcademias.fulfilled, (state, action) => {
                state.academias = action.payload
                state.pending = false
                state.requests.academias = false
            })
            .addCase(getAcademias.rejected, (state, action) => {
                state.pending = false
                state.requests.academias = false
            })

            .addCase(getAcademia.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademia.fulfilled, (state, action) => {
                state.info = action.payload
                state.notExist = !action.payload
                state.pending = false
            })
            .addCase(getAcademia.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademias.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademias.fulfilled, (state, action) => {
                const academias = state.academias
                academias.push(...action.payload)
                
                state.academias = academias
                state.pending = false
            })
            .addCase(createAcademias.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteAcademias.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteAcademias.fulfilled, (state, action) => {
                state.info = null
                state.notExist = true
                state.pending = false
            })
            .addCase(deleteAcademias.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademias.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademias.fulfilled, (state, action) => {
                state.info = action.payload[0]
                state.pending = false
            })
            .addCase(updateAcademias.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademiaSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademiaSessions.fulfilled, (state, action) => {
                state.sessions = action.payload
                state.pending = false
            })
            .addCase(getAcademiaSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademiaSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademiaSessions.fulfilled, (state, action) => {
                const sessions = state.sessions
                sessions.push(...action.payload)

                state.sessions = sessions
                state.pending = false
            })
            .addCase(createAcademiaSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteAcademiaSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteAcademiaSessions.fulfilled, (state, action) => {
                const sessions = state.sessions.filter(session => session.id && !action.meta.arg.ids.includes(session.id))
                state.sessions = sessions
                state.pending = false
            })
            .addCase(deleteAcademiaSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaSessions.fulfilled, (state, action) => {
                const sessions = state.sessions

                action.payload.forEach((item: Session) => {
                    const index = sessions.findIndex(session => session.id === item.id)
                    sessions[index] = item
                })

                state.sessions = sessions
                state.pending = false
            })
            .addCase(updateAcademiaSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademiaAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademiaAppointments.fulfilled, (state, action) => {
                state.appointments = action.payload
                state.pending = false
            })
            .addCase(getAcademiaAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademiaAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademiaAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments
                appointments.push(...action.payload)

                state.appointments = appointments
                state.pending = false
            })
            .addCase(createAcademiaAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteAcademiaAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteAcademiaAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments.filter(item => item.id && !action.meta.arg.ids.includes(item.id))
                state.appointments = appointments
                state.pending = false
            })
            .addCase(deleteAcademiaAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments

                action.payload.forEach((item: Area) => {
                    const index = appointments.findIndex(appointment => appointment.id === item.id)
                    appointments[index] = item
                })

                state.appointments = appointments
                state.pending = false
            })
            .addCase(updateAcademiaAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademiaParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademiaParticipants.fulfilled, (state, action) => {
                state.participants = action.payload
                state.pending = false
            })
            .addCase(getAcademiaParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademiaParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademiaParticipants.fulfilled, (state, action) => {
                const participants = state.participants
                participants.push(...action.payload)

                state.participants = participants
                state.pending = false
            })
            .addCase(createAcademiaParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademiaParticipantsImage.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademiaParticipantsImage.fulfilled, (state, action) => {
                const participants = state.participants
                participants.push(...action.payload)

                state.participants = participants
                state.pending = false
            })
            .addCase(createAcademiaParticipantsImage.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteAcademiaParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteAcademiaParticipants.fulfilled, (state, action) => {
                const participants = state.participants.filter(participant => participant.id && !action.meta.arg.ids.includes(participant.id))
                state.participants = participants
                state.pending = false
            })
            .addCase(deleteAcademiaParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaParticipants.fulfilled, (state, action) => {
                const participants = state.participants

                action.payload.forEach((item: Participants) => {
                    const index = participants.findIndex(participant => participant.id === item.id)
                    participants[index] = item
                })

                state.participants = participants
                state.pending = false
            })
            .addCase(updateAcademiaParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaParticipantsImage.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaParticipantsImage.fulfilled, (state, action) => {
                const participants = state.participants

                action.payload.forEach((item: Participants) => {
                    const index = participants.findIndex(participant => participant.id === item.id)
                    participants[index] = item
                })

                state.participants = participants
                state.pending = false
            })
            .addCase(updateAcademiaParticipantsImage.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademiaTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademiaTasks.fulfilled, (state, action) => {
                state.tasks = action.payload
                state.pending = false
            })
            .addCase(getAcademiaTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createAcademiaTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createAcademiaTasks.fulfilled, (state, action) => {
                const tasks = state.tasks
                tasks.push(...action.payload)

                state.tasks = tasks
                state.pending = false
            })
            .addCase(createAcademiaTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteAcademiaTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteAcademiaTasks.fulfilled, (state, action) => {
                const tasks = state.tasks.filter(task => task.id && !action.meta.arg.ids.includes(task.id))
                state.tasks = tasks
                state.pending = false
            })
            .addCase(deleteAcademiaTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaTasks.fulfilled, (state, action) => {
                const tasks = state.tasks

                action.payload.forEach((item: Task) => {
                    const index = tasks.findIndex(task => task.id === item.id)
                    tasks[index] = item
                })

                state.tasks = tasks
                state.pending = false
            })
            .addCase(updateAcademiaTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateAcademiaFiles.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateAcademiaFiles.fulfilled, (state, action) => {
                state.info = action.payload[0]
                state.pending = false
            })
            .addCase(updateAcademiaFiles.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademiaSessionsReserved.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademiaSessionsReserved.fulfilled, (state, action) => {
                state.bookings = action.payload
                state.pending = false
            })
            .addCase(getAcademiaSessionsReserved.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export const {resetAcademia} = academiasSlice.actions

export default academiasSlice