import React, {useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Schedule from '@mui/icons-material/Schedule'
import {DigitalClock} from '@mui/x-date-pickers'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {TimeField} from '@mui/x-date-pickers/TimeField'

interface IComponent {
    value: string | null
    label: string
    name: string
    min?: string | null
    max?: string | null
    onChange: (name: string, value: string | null) => void
}

const TimeInput = (props: IComponent) => {
    const [open, setOpen] = useState<Element | null>(null)
    const [date, setDate] = useState<Dayjs | null>(props.value ? dayjs(props.value) : null)
    const [error, setError] = useState<boolean>(false)

    const format = 'HH:mm'

    const onChange = (value: dayjs.Dayjs | null, context: any) => {
        if (context.validationError) {
            setError(true)
        } else {
            setError(false)
        }

        setDate(value)
    }

    const onSelect = (value: dayjs.Dayjs | null) => {
        setDate(value)
        props.onChange(props.name, dayjs(value).toISOString())
    }

    const onBlur = () => {
        if (!error) {
            props.onChange(props.name, date ? dayjs(date).toISOString() : null)
        } else {
            setDate(props.value ? dayjs(props.value) : null)
        }
    }

    const handleClick = (e: React.MouseEvent) => {
        setOpen(e.currentTarget)
    }

    const handleClose = () => {
        setOpen(null)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                onChange={onChange}
                onBlur={onBlur}
                value={date}
                InputLabelProps={{ shrink: true }}
                label={props.label}
                name={props.name}
                variant="outlined"
                fullWidth
                format={format}
                ampm={false}
                minTime={props.min ? dayjs(props.min) : undefined}
                maxTime={props.max ? dayjs(props.max) : undefined}
                disableIgnoringDatePartForTimeValidation={true}
                InputProps={{
                    placeholder: '',
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton onClick={handleClick}>
                                <Schedule />
                            </IconButton>
                        </InputAdornment>
                }}
            />
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            display: 'flex'
                        }
                    }
                }}
            >
                <DigitalClock
                    value={props.value ? dayjs(props.value) : null}
                    onChange={onSelect}
                    ampm={false}
                    timeStep={60}
                    minTime={props.min ? dayjs(props.min) : undefined}
                    maxTime={props.max ? dayjs(props.max) : undefined}
                />
            </Popover>
        </LocalizationProvider>
    )
}

export default React.memo(TimeInput)
