import {useRef} from 'react'
import {Outlet} from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getInit} from 'slices/auth'
import {messageReset} from 'slices/message'

dayjs.extend(utc)
dayjs.extend(timezone)

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
`

const Root = () => {
    const init = useRef<boolean>(false)

    const text = useAppSelector(state => state.message.text)
    const variant = useAppSelector(state => state.message.variant)
    const open = useAppSelector(state => state.message.open)

    const auth = useAppSelector(state => state.auth.pending)
    const projects = useAppSelector(state => state.projects.pending)
    const academias = useAppSelector(state => state.academias.pending)
    const entities = useAppSelector(state => state.entities.pending)
    const forecast = useAppSelector(state => state.forecast.pending)

    const trainee = useAppSelector(state => state.trainee.pending)

    const trainer = useAppSelector(state => state.trainer.pending)

    const dispatch = useAppDispatch()

    if (!init.current) {
        init.current = true
        const tz = dayjs().format('Z')
        dispatch(getInit({tz}))
    }

    const onClose = (e: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        dispatch(messageReset())
    }

    const pending = auth || projects || academias || forecast || entities || trainee || trainer
    
    return (
        <Container>
            {pending && <LinearProgress color='warning' sx={{position: 'fixed', width: '100%', zIndex: 1400}} />}
            <Outlet />
            <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
                <Alert severity={variant} elevation={3} sx={{ width: '100%', padding: '5px 35px', letterSpacing: 0.5 }}>
                    {text}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default Root