export const types = {
    Text: 'text',
    Textarea: 'textarea',
    Date: 'date',
    DateTime: 'datetime',
    Chip: 'chip',
    Chips: 'chips',
    Image: 'image',
    Suggestions: 'suggestions',
    Number: 'number',
    Currency: 'currency',
    Phone: 'phone',
    AcademiaInvoice: 'academiaInvoice',
    ProjectInvoice: 'projectInvoice',
    Link: 'link',
    Color: 'color',
    File: 'file',
    Percent: 'percent',
    Mention: 'mention',

    Button: 'button',
    Custom: 'custom'
}

export const defaultValues = {
    [types.Text]: '',
    [types.Textarea]: '',
    [types.Date]: null,
    [types.DateTime]: null,
    [types.Chip]: null,
    [types.Chips]: [],
    [types.Image]: '',
    [types.Currency]: '',
    [types.Number]: null,
    [types.ProjectInvoice]: [],
    [types.AcademiaInvoice]: [],
    [types.Suggestions]: '',
    [types.Phone]: '',
    [types.Color]: '',
    [types.File]: '',
    [types.Mention]: '',
    [types.Link]: '',
    [types.Percent]: null
}