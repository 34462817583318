import React, {useEffect, useState, useMemo, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {filter} from '@framelink/utils'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getTrainerParticipants} from 'slices/trainer'
import {getSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import {TopBarHeight} from 'enums/styles'
import {Sheet} from 'api/enums'
import {IContext} from 'containers/Trainer/Training/interfaces'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string,
    color?: string
}

const defaultSheetFields: any = []

const Participants = () => {
    const [checked, setCheck] = useState<number[]>([])
    const participants = useAppSelector(state => state.trainer.participants)
    const sheet = useAppSelector(state => state.sheets.data[Sheet.trainerAcademiaParticipants])

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const {textFilter}: IContext = useOutletContext()

    useEffect(() => {
        if (academiaId) {
            dispatch(getTrainerParticipants({id: Number(academiaId)}))
            dispatch(getSheet({name: Sheet.trainerAcademiaParticipants}))
        }
    }, [dispatch, academiaId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const participantsFiltered = useMemo(() => {
        return filter(participants, textFilter, ['trainee_name', 'trainee_phone', 'trainee_email', 'trainee_company_name', 'trainee_role'])
    }, [participants, textFilter])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)
    
    return (
        <React.Fragment>
            <Container margin={TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    order={order}
                    rows={participantsFiltered}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                />
            </Container>
        </React.Fragment>
    )
}

export default Participants