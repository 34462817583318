import React from 'react'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import Download from '@mui/icons-material/Download'

interface IComponent {
    link: string
}

const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const DownloadButton = (props: IComponent) => {
    return (
        <Container>
            <IconButton color='warning' href={props.link}>
                <Download fontSize='small' />
            </IconButton>
        </Container>
    )
}

export default React.memo(DownloadButton)
