import {ICountry, IIso2Lookup, ICountryCodes} from '../interfaces'
import data from '../enums/countries'

// we will build this in the loop below
const allCountryCodes: ICountryCodes = {}
const iso2Lookup: IIso2Lookup = {}
const allCountries: ICountry[] = []

const addCountryCode = (iso2: string, dialCode: string, priority = 0) => {
    if (!(dialCode in allCountryCodes)) {
        allCountryCodes[dialCode] = []
    }

    allCountryCodes[dialCode][priority] = iso2
}

for (let i = 0; i < data.length; i++) {
    // countries
    const c = data[i]
    allCountries[i] = {
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        format: c[3],
        priority: c[4] || 0,
        // hasAreaCodes: !!c[5]
    }

    // area codes
    if (c[5]) {
        for (let j = 0; j < c[5].length; j++) {
            // full dial code is country code + dial code
            const dialCode = c[2] + c[5][j]
            addCountryCode(c[1], dialCode)
        }
    }

    iso2Lookup[allCountries[i].iso2] = i

    // dial codes
    addCountryCode(c[1], c[2], c[4])
}

export default {allCountries, iso2Lookup, allCountryCodes}