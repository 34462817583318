import React, {useEffect, useState, useCallback, useLayoutEffect, useMemo} from 'react'
import {Outlet, Navigate, useParams, useLocation} from 'react-router-dom'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getTrainings, resetAcademia} from 'slices/trainer'
import Breadcrumbs from 'components/Breadcrumbs'
import TextFilter from 'components/TextFilter'
import routes from 'enums/routes'
import {TopBarHeight} from 'enums/styles'

const Top = styled.div<{size: number}>`
    height: ${props => props.size}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const chip = {
    width: '180px',
    marginLeft: 'auto'
}


const breadcrumbs = [
    {
        name: 'section',
        path: `/${routes.Academias}`
    },
    {
        name: 'name'
    }
]


const Training = () => {
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [textFilter, setTextFilter] = useState<string>('')
    const [appliedFilters, setAppliedFilters] = useState<boolean>(false)

    const academias = useAppSelector(state => state.trainer.academias)

    const dispatch = useAppDispatch()

    const {academiaId} = useParams()
    const {pathname} = useLocation()
   
    useEffect(() => {
        dispatch(getTrainings())

        return () => {
            dispatch(resetAcademia())
        }
    }, [dispatch])

    useLayoutEffect(() => {
        setAppliedFilters(false)
    }, [pathname])

    const handleFilterOpen = () => {
        setOpenFilter(true)
    }

    const handleFilterClose = (filters?: {appliedFilters: boolean}) => {
        if (filters) {
            setAppliedFilters(filters.appliedFilters)
        }

        setOpenFilter(false)
    }

    const handleFilterText = useCallback((value: string) => {
        setTextFilter(value)
    }, [])

    const academia = useMemo(() => {
        return academias.find(item => item.id === Number(academiaId))
    }, [academiaId, academias])

    const filter = pathname.includes(routes.Sessions)
    const search = pathname.includes(routes.Participants)

    return (
        <React.Fragment>
            <Top size={TopBarHeight}>
                {academia &&
                    <React.Fragment>
                        <Breadcrumbs margin={'0 20px 0 0'} items={breadcrumbs} data={{name: academia.name, section: 'Academias'}} />
                        {filter &&
                            <Chip
                                icon={<FilterAlt />}
                                label={'Filters'}
                                sx={chip}
                                onClick={handleFilterOpen}
                                variant={appliedFilters ? 'filled' : 'outlined'}
                                color='secondary'
                            />
                        }
                        {search &&
                            <TextFilter onChange={handleFilterText} value={textFilter} />
                        }
                    </React.Fragment>
                }
            </Top>
            {!!academias.length && !academia ?
                <Navigate to={`/${routes.Trainings}`} replace={true} /> :
                <Outlet context={{textFilter, openFilter, handleFilterClose}} />
            }
        </React.Fragment>
    )
}

export default Training