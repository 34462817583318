import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react'
import {Link, Navigate, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import Calendar from 'components/Calendar'
import EventPopup from 'containers/Trainee/EventPopup'
import {Area, getAcademia, getAreas, getTimeslots, bookSession, cancelSession} from 'slices/trainee'
import {Event, EventHandlers} from 'components/Calendar/interfaces'
import Menu from 'components/Menu'
import {defaultEventColor} from 'enums/styles'
import routes from 'enums/routes'
import ListItem from './ListItem'

const Container = styled.div`
    display: flex;
    height: 100%;
`

const Title = styled.div`
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #dddddd;
    position: sticky;
    top: 60px;
    background: #ffffff;
    flex-shrink: 0;
    z-index: 1;
`

const Text = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 25px;
`

const Course = () => {
    const [menuItem, setMenuItem] = useState<number | null>(null)

    const calendarRef = useRef<EventHandlers>(null)

    const academia = useAppSelector(state => state.trainee.info)
    const notExist = useAppSelector(state => state.trainee.notExist)
    const areas = useAppSelector(state => state.trainee.areas)
    const slots = useAppSelector(state => state.trainee.slots)

    const {academiaId} = useParams()
    const intl = useIntl()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (academiaId) {
            dispatch(getAcademia({id: Number(academiaId)}))
            dispatch(getAreas({id: Number(academiaId)}))
            dispatch(getTimeslots({id: Number(academiaId)}))
        }
    }, [dispatch, academiaId])

    const handleFilter = useCallback((id: number) => {
        if (menuItem === id) {
            setMenuItem(null)
        } else {
            setMenuItem(id)
        }
    }, [menuItem])

    const onConfirm = (slotId: string, title: string, start: string, menuItemId: number | null) => {
        calendarRef.current!.listPopupClose()

        if (menuItemId) {
            dispatch(bookSession({
                data: [{
                    id: Number(slotId),
                    starts: start,
                    projectAreaId: menuItemId
                }]
            }))
        }
    }

    const onCancel = (sessionId?: number) => {
        calendarRef.current!.listPopupClose()

        if (sessionId) {
            dispatch(cancelSession({ids: [sessionId]}))
        }
    }

    const getEvent = (id: string) => {
        const currentItem = areas.find((item: any) => item.id === menuItem)

        if (currentItem && !currentItem.session_id) {
            const slot = slots.find((item: any) => item.id === Number(id))

            if (slot) {
                return {
                    id: slot.id,
                    title: currentItem.trainer_name,
                    start: slot.starts,
                    booked: false,
                    menuItemId: menuItem
                }
            }
        } else {
            const area = areas.find((item: any) => item.id === Number(id))

            if (area) {
                return {
                    id: area.id,
                    title: area.trainer_name,
                    start: area.session_starts,
                    booked: true,
                    menuItemId: menuItem,
                    sessionId: area.session_id,
                    link: area.trainer_joinLink,
                    phone: area.trainer_phone
                }
            }
        }
    }

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = getEvent(id)

        if (event) {
            return <EventPopup
                id={event.id}
                title={event.title}
                start={event.start}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onClose={onClose}
                menuItemId={event.menuItemId}
                booked={event.booked}
                link={event.link}
                sessionId={event.sessionId}
                phone={event.phone}
            />
        }
    }

    const events = useMemo(() => {
        if (menuItem) {
            const session = areas.find(area => area.id === menuItem)

            if (session) {
                if (session.session_id) {
                    return [{
                        id: session.id.toString(),
                        title: session.trainer_name,
                        start: session.session_starts,
                        end: session.session_ends,
                        backgroundColor: session.area_color ? session.area_color : defaultEventColor,
                        borderColor: session.area_color ? session.area_color : defaultEventColor,
                        textColor: '#666666'
                    }]
                } else {
                    return slots.reduce((data: Event[], slot: any) => {
                        if (session.trainer_id === slot.trainerId) {
                            data.push({
                                id: slot.id,
                                title: session.trainer_name,
                                start: slot.starts,
                                end: slot.ends,
                                backgroundColor: session.area_color ? session.area_color + '33' : defaultEventColor + '33',
                                borderColor: session.area_color ? session.area_color : defaultEventColor,
                                textColor: '#666666'
                            })
                        }
            
                        return data 
                    }, [])
                }
            } else {
                return []
            }
        } else {
            return areas.reduce((data: Event[], area: Area) => {
                if (area.session_id) {
                    data.push({
                        id: area.id.toString(),
                        title: area.trainer_name,
                        start: area.session_starts,
                        end: area.session_ends,
                        backgroundColor: area.area_color ? area.area_color : defaultEventColor,
                        borderColor: area.area_color ? area.area_color : defaultEventColor,
                        textColor: '#666666'
                    })
                }
    
                return data 
            }, [])
        }
    }, [slots, areas, menuItem])
    
    return (
        <React.Fragment>
            {notExist ?
                <Navigate to={`/${routes.Courses}`} replace={true} /> :   
                <Container>
                    <Menu width={300}>
                        <React.Fragment>
                            <Title>
                                <IconButton component={Link} to={`/${routes.Courses}/${academiaId}`}>
                                    <ChevronLeft />
                                </IconButton>
                                <Text>{academia && academia.name}</Text>
                            </Title>
                            <List>
                                {areas.map(item =>
                                    <ListItem
                                        key={item.id}
                                        onClick={handleFilter}
                                        id={item.id}
                                        label={item.area_name}
                                        background={item.area_color}
                                        mark={!!item.session_id}
                                        name={item.trainer_name}
                                        email={item.trainer_email}
                                        phone={item.trainer_phone}
                                        selected={item.id === menuItem}
                                    />
                                )}
                            </List>
                        </React.Fragment>
                    </Menu>
                    <Calendar
                        defaultView={'dayGridMonth'}
                        locale={intl.locale as any}
                        ref={calendarRef}
                        modeChangeControl={true}
                        events={events}
                        eventPopupContent={eventPopupContent}
                    />
                </Container>
            }
        </React.Fragment>
    )
}

export default Course