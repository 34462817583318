import {useCallback, useEffect, useState, useMemo} from 'react'
import {useNavigate} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Group from '@mui/icons-material/Group'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getTrainings} from 'slices/trainer'
import {getEntities} from 'slices/entities'
import Card from 'components/Card'
import {types} from 'enums/fields'
import routes from 'enums/routes'
import {Entity} from 'api/enums'

const icon = {
    fontSize: 18
}

const chip = {
    marginLeft: 'auto',
    width: '180px'
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
        {
            label: 'Team members',
            icon: <Group sx={icon} />,
            type: types.Chips,
            value: 'teamMembers',
            props: {
                id: 'member_id',
                text: 'member_name',
                background: 'member_color'
            }
        }
    ]
}

const Trainings = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement | null>(null)
    const [status, setStatus] = useState<number>(0)

    const academias = useAppSelector(state => state.trainer.academias)
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTrainings())
        dispatch(getEntities({entity: Entity.projectStatus}))
    }, [dispatch])

    const onClick = useCallback((id: number) => {
        navigate(`/${routes.Training}/${id}/${routes.Sessions}`)
    }, [])

    const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleFilterClose = () => {
        setOpenFilter(null)
    }

    const filterItems = (id: number) => {
        if (id === status) {
            setStatus(0)
        } else {
            setStatus(id)
        }

        handleFilterClose()
    }

    const academiasFiltered = useMemo(() => status ? academias.filter(academia => {
        const filterStatus = academia.status_id === status

        return filterStatus

    }) : academias, [academias, status])

    const currentStatus = statuses.find(item => item.id === status)

    return (
        <div style={{margin: '20px'}}>
            <div style={{display: 'flex', marginBottom: '20px'}}>
                <Chip
                    icon={<TaskAlt />}
                    label={currentStatus ? currentStatus.name : 'Status'}
                    sx={{...chip, background: currentStatus ? currentStatus.color : ''}}
                    onClick={handleFilterOpen}
                    data-name='status'
                />
                <Menu
                    anchorEl={openFilter}
                    open={!!openFilter}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                marginTop: '10px',
                                width: '180px'
                            }
                        }
                    }}
            >
                {Object.values(statuses).map(item => (
                    <MenuItem onClick={() => filterItems(item.id)}
                        key={item.id}
                        value={item.id}
                        selected={status === item.id}
                    >
                        <Typography variant="inherit" noWrap>
                            {item.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Menu>
            </div>
            <Grid container spacing={2}>
                {academiasFiltered.map(academia => {
                    return (
                        <Grid item xs={12} md={3} key={academia[config.id]}>
                            <Card
                                config={config}
                                data={academia}
                                onClick={onClick}
                                color='#faf5f1'
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default Trainings