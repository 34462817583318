import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProjectSessions, createProjectSessions, deleteProjectSessions, updateProjectSessions, getProjectAppointments} from 'slices/projects'
import {getEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import FilterForm from 'components/FilterForm'
import Bottom from 'components/SheetBottom'
import {corporateForm, corporateFilter} from 'enums/projects/sessions'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {IContext} from 'containers/Employee/Project/interfaces'
import {Entity, Sheet} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string
}

type Filters = {
    projectAreaId: number | null,
    projectArea_trainerId: number | null,
    traineeId: number | null,
    statusId: number | null
}

const defaultSheetFields: any = []

const CorporateSessions = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [sessionId, setSessionId] = useState<number | null>(null)
    const [filters, setFilters] = useState<Filters>({projectAreaId: null, projectArea_trainerId: null, traineeId: null, statusId: null})
    const editFormTitle = useRef<string>('')

    const info = useAppSelector(state => state.projects.info)
    const sessions = useAppSelector(state => state.projects.sessions)
    const appointments = useAppSelector(state => state.projects.appointments)
    const trainees = useAppSelector(state => state.entities[Entity.trainee])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])
    const statuses = useAppSelector(state => state.entities[Entity.sessionStatus])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.corporateProjectSessions])
    const user = useAppSelector(state => state.auth.user)

    const {projectId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()

    useEffect(() => {
        if (projectId && info) {
            dispatch(getProjectSessions({id: Number(projectId), type: info.typeId}))
            dispatch(getProjectAppointments({id: Number(projectId)}))
            dispatch(getEntities({entity: Entity.sessionStatus}))
            dispatch(getEntities({entity: Entity.trainer}))
            dispatch(getEntities({entity: Entity.trainee}))
            dispatch(getSheet({name: Sheet.corporateProjectSessions}))
        }
    }, [dispatch, projectId, info])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'projectAreaId') {
            return appointments.map(item => {
                return {
                    id: item.id,
                    name: item.area_name
                }
            })
        }

        if (name === 'projectArea_trainerId') {
            return trainers
        }

        if (name === 'traineeId') {
            return trainees
        }

        if (name === 'statusId') {
            return statuses
        }

        return []
    }, [appointments, trainers, trainees, statuses])

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setSessionId(documentId)
            editFormTitle.current = 'Change record'
        } else {
            editFormTitle.current = 'Create record'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSessionId(null)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(projectId)

        if (sessionId) {
            const session = sessions.find(item => item.id === sessionId)
            
            obj.id = sessionId
            obj.session_id = session!.session_id
            dispatch(updateProjectSessions({data: [{...obj}], type: info ? info.typeId : 0}))
        } else {
            dispatch(createProjectSessions({data: [{...obj}], type: info ? info.typeId : 0}))
        }

        handleClose()
    }

    const handleDelete = () => {
        dispatch(deleteProjectSessions({ids: checked, type: info ? info.typeId : 0}))
        setCheck([])
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'projectAreaId') {
            return appointments.map(item => {
                return {
                    id: item.id,
                    name: item.area_name,
                    description: item.trainer_name
                }
            })
        } else if (name === 'traineeId') {
            return trainees
        } else if (name === 'statusId') {
            return statuses
        } else {
            return []
        }
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.corporateProjectSessions,
                data: [{
                    name: Sheet.corporateProjectSessions,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.corporateProjectSessions,
                data: [{
                    name: Sheet.corporateProjectSessions,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const initialValues = useMemo(() => {
        const session = sessions.find(item => item.id === sessionId)

        return {
            projectAreaId: session ? session.projectAreaId : null,
            starts: session ? session.starts : null,
            traineeId: session ? session.traineeId : null,
            statusId: session ? session.statusId : null,
            hours: session ? session.hours : null,
        }
    }, [sessions, sessionId])

    const currentSessions = useMemo(() => Object.values(filters).find(item => !!item) ? sessions.filter(session => {
        const appointmentFilter = filters.projectAreaId ? session.projectAreaId === filters.projectAreaId : true
        const trainerFilter = filters.projectArea_trainerId ? session.projectArea_trainerId === filters.projectArea_trainerId : true
        const traineeFilter = filters.traineeId ? session.traineeId === filters.traineeId : true
        const statusFilter = filters.statusId ? session.statusId === filters.statusId : true
    
        return appointmentFilter && traineeFilter && trainerFilter && statusFilter
    }) : sessions, [sessions, filters])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false
    
    return (
        <React.Fragment>
            <Container margin={BottomBarHeight + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={currentSessions}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom height={BottomBarHeight} checked={!!checked.length} handleDelete={handleDelete} handleCreate={handleOpen} />
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={corporateForm}
                getOptions={getOptions}
                initialValues={initialValues}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={corporateFilter}
            />
        </React.Fragment>
    )
}

export default CorporateSessions