import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import MuiButton from '@mui/material/Button'
import Clear from '@mui/icons-material/Delete'
import Numeric from 'components/FormInputs/Numeric'

interface IComponent {
    values: InvoiceRule[]
    participantId: number
    disabled: boolean
    onChange: (data: InvoiceRule[]) => void
}

type InvoiceRule = {
    id?: number
    percent: string
    daysAfter: number
    projectTraineeId: number
    [key: string]: any
}

const Rule = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const Title = styled.div`
    font-size: 15px;
    color: #666;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding-bottom: 20px;
    width: 100%;
`

const Item = styled.div`
    margin-right: 20px;
    flex: 1;
`

const InvoiceRules = (props: IComponent) => {
    const [values, setValue] = useState(props.values)

    const intl = useIntl()

    const onChange = (name: string, value: number, id: number) => {
        const data = values.map((item, index) => {
            if (index === id) {
                return {
                    ...item,
                    [name]: value
                }
            } else {
                return item
            }
        })
        
        setValue(data)

        props.onChange(data)
    }

    const onClick = (id: number) => {
        const data = values.filter((item, index) => index !== id)

        setValue(data)

        props.onChange(data)
    }

    const onCreate = () => {
        const data = [
            ...values,
            {
                percent: '0',
                daysAfter: 0,
                projectTraineeId: props.participantId
            }
        ]

        setValue(data)

        props.onChange(data)
    }

    return (
        <Container>
            <Title>Invoice rules</Title>
            {values.map((item, index) => {
                return <Rule key={index}>
                    <Item>
                        <Numeric
                            value={item.daysAfter}
                            title={intl.formatMessage({id: "invoice.DaysAfterEnrollment"})}
                            name='daysAfter'
                            onChange={(name: string, value: number) => onChange(name, value, index)}
                            controlled={true}
                            disabled={props.disabled}
                            decimalScale={0}
                        />
                    </Item>
                    <Item>
                        <Numeric
                            value={item.percent ? Number(item.percent) : 0}
                            title={intl.formatMessage({id: "invoice.Percent"})}
                            name='percent'
                            onChange={(name: string, value: number) => onChange(name, value, index)}
                            controlled={true}
                            disabled={props.disabled}
                        />
                </Item>
                <IconButton onClick={() => onClick(index)} disabled={values.length === 1 || props.disabled}>
                    <Clear />
                </IconButton>
                </Rule>
            })}
            <MuiButton disabled={values.length === 3 || props.disabled} variant='contained' color='secondary' sx={{marginTop: 2}} onClick={onCreate}>
                {intl.formatMessage({id: "invoice.AddInvoiceRule"})}
            </MuiButton>
        </Container>
    )
}

export default React.memo(InvoiceRules)
