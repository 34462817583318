import Sheet from 'components/Sheet'
import {filter, entities, totals} from 'enums/invoices/academia'
import {Entity, Action, Sheet as Sheets} from 'api/enums'
import {useAppDispatch} from 'app/hooks'
import {actEntity} from 'slices/entities'
import SendButton from 'components/Fields/SendButton'
import {InvoiceStatusReady} from 'enums/utils'

const AcademiaInvoices = () => {
    const dispatch = useAppDispatch()

    const getCustomItem = (value: any, column: any, data: any) => {
        return (
            <SendButton
                name={data.item_trainee_name}
                hidden={data[column.name] !== InvoiceStatusReady}
                documentId={data.id}
                onClick={onClick}
            />
        )
    }

    const onClick = (id: number) => {
        dispatch(actEntity({
            entity: Entity.academiaInvoice,
            action: Action.sendacademiainvoice,
            ids: [id]
        }))
    }

    return (
        <Sheet
            data={Entity.academiaInvoice}
            primaryId={'id'}
            filterFields={filter as any}
            entities={entities as any}
            sheet={Sheets.academiaInvoice}
            totals={totals}
            getCustomItem={getCustomItem}
        />
    )
}

export default AcademiaInvoices