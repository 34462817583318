import React from 'react'
import {useIntl} from 'react-intl'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'

interface IComponent {
    open: boolean
    disabled?: boolean
    title?: string
    children: JSX.Element,
    handleClose: () => void
    handleConfirm: () => void
}

const Modal = (props: IComponent) => {
    const theme = useTheme()
    const intl = useIntl()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const transitionDuration = mobile ? 0 : {enter: theme.transitions.duration.enteringScreen, exit: theme.transitions.duration.leavingScreen}

    return (
        <Dialog open={props.open} scroll='body' fullWidth onClose={props.handleClose} fullScreen={mobile} transitionDuration={transitionDuration}>
            {props.title && <DialogTitle>{props.title}</DialogTitle>}
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>{intl.formatMessage({id: "app.Cancel"})}</Button>
                <Button onClick={props.handleConfirm} variant='contained' disabled={props.disabled}>{intl.formatMessage({id: "app.Confirm"})}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default React.memo(Modal)
