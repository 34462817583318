import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getTrainerSessions, updateTrainerSessions} from 'slices/trainer'
import {getAcademiaAppointments} from 'slices/academias'
import {getEntities} from 'slices/entities'
import {getSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import FilterForm from 'components/FilterForm'
import {form, filter} from 'enums/trainer/sessions'
import {TopBarHeight} from 'enums/styles'
import {SessionStatusScheduled, SessionStatusDone} from 'enums/utils'
import {IContext} from 'containers/Trainer/Training/interfaces'
import {Entity, Sheet} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string
}

type Filters = {
    projectAreaId: number | null,
    traineeId: number | null,
    statusId: number | null
}

const defaultSheetFields: any = []

const Sessions = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [sessionId, setSessionId] = useState<number | null>(null)
    const [filters, setFilters] = useState<Filters>({projectAreaId: null, traineeId: null, statusId: null})
    const editFormTitle = useRef<string>('')

    const sessions = useAppSelector(state => state.trainer.sessions)
    const appointments = useAppSelector(state => state.academias.appointments)
    const trainees = useAppSelector(state => state.entities[Entity.trainee])
    const statuses = useAppSelector(state => state.entities[Entity.sessionStatus])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.trainerAcademiaSessions])

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()

    useEffect(() => {
        if (academiaId) {
            dispatch(getTrainerSessions({id: Number(academiaId)}))
            dispatch(getAcademiaAppointments({id: Number(academiaId)}))
            dispatch(getEntities({entity: Entity.sessionStatus}))
            dispatch(getEntities({entity: Entity.trainee}))
            dispatch(getSheet({name: Sheet.trainerAcademiaSessions}))
        }
    }, [dispatch, academiaId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setSessionId(documentId)
            editFormTitle.current = 'Change record'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSessionId(null)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(academiaId)

        if (sessionId) {
            obj.id = sessionId
            dispatch(updateTrainerSessions({data: [{...obj}]}))
        }

        handleClose()
    }

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'projectAreaId') {
            return appointments.map(item => {
                return {
                    id: item.area_id,
                    name: item.area_name
                }
            })
        }

        if (name === 'traineeId') {
            return trainees
        }

        if (name === 'statusId') {
            return statuses
        }

        return []
    }, [appointments, trainees, statuses])

    const getOptions = useCallback((name: string) => {
        if (name === 'statusId') {
            const session = sessions.find(item => item.id === sessionId)

            if (session) {
                return statuses.filter(item => item.stage === session.status_stage || item.stage === session.status_stage + 1)
            }
        }

        return []
    }, [statuses, sessions, sessionId])

    const setEditable = useCallback((name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'report') {
            return dependencyValue === SessionStatusDone ? true : false
        }

        return true
    }, [])

    const initialValues = useMemo(() => {
        const session = sessions.find(item => item.id === sessionId)

        return {
            statusId: session ? session.statusId : null,
            summary: session ? session.summary : '',
            report: session ? session.report : ''
        }
    }, [sessions, sessionId])

    const config = useMemo(() => {
        const session = sessions.find(item => item.id === sessionId)

        if (session) {
            if (session.statusId === SessionStatusScheduled) {
                const index = form.findIndex(item => item.name === 'statusId')
                const data = [...form]

                data.splice(index, 1, {...form[index], disabled: false})

                return data
            } else {
                return form
            }
        }

        return form
    }, [sessions, sessionId])

    const currentSessions = useMemo(() => Object.values(filters).find(item => !!item) ? sessions.filter(session => {
        const appointmentFilter = filters.projectAreaId ? session.projectArea_areaId === filters.projectAreaId : true
        const traineeFilter = filters.traineeId ? session.traineeId === filters.traineeId : true
        const statusFilter = filters.statusId ? session.statusId === filters.statusId : true
    
        return appointmentFilter && traineeFilter && statusFilter
    }) : sessions, [sessions, filters])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    return (
        <React.Fragment>
            <Container margin={TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    order={order}
                    rows={currentSessions}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                />
            </Container>
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={config}
                initialValues={initialValues}
                getOptions={getOptions}
                setEditable={setEditable}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filter}
            />
        </React.Fragment>
    )
}

export default Sessions