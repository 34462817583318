import {types} from 'enums/fields'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 300,
        type: types.Text
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    }
]