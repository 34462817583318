import {types} from 'enums/fields'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 300,
        type: types.Chip,
        color: 'color'
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Color',
        type: types.Color,
        name: 'color',
        required: false,
        data: 'palette'
    }
]