import React, {useMemo, useState, useEffect, useCallback, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import dayjs from 'dayjs'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Person from '@mui/icons-material/Person'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Mail from '@mui/icons-material/Mail'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Group from '@mui/icons-material/Group'
import Subject from '@mui/icons-material/Subject'
import Payments from '@mui/icons-material/Payments'
import Create from '@mui/icons-material/Create'
import Percent from '@mui/icons-material/Percent'
import Sell from '@mui/icons-material/Sell'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getExpenseTrainers, getExpenseExtras, createExpenseExtras, updateExpenseExtras, deleteExpenseExtras,
    updateExpenseTrainers, updateProjects} from 'slices/projects'
import {getEntities} from 'slices/entities'
import Card from 'components/Card'
import ProposalSheet from 'components/ProposalSheet'
import EditForm from 'components/EditForm'
import Breadcrumbs from 'components/Breadcrumbs'
import InvoiceRules from 'components/InvoiceRules/Project'
import {types} from 'enums/fields'
import {DateFormate, DateTimeFormate} from 'enums/utils'
import {proposalForm, expenseForm} from 'enums/projects/proposal'
import {currencyFormatter, numberFormatter} from 'utils/formatter'
import {Entity, View} from 'api/enums'
import routes from 'enums/routes'

const breadcrumbs = [
    {
        name: 'section',
        path: `/${routes.Projects}`
    },
    {
        name: 'name'
    }
]

type ExpenseExtras = {
    id: number,
    name: string
    price: number
    units: number
    projectId: number,
    total: number
}

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
`

const icon = {
    fontSize: 18
}

const chip = {
    width: '180px',
    marginBottom: 2,
    marginLeft: 'auto'
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Type',
            icon: <AutoFixHigh sx={icon} />,
            type: types.Chip,
            value: 'type_name',
            props: {
                id: 'type_id',
                text: 'type_name',
                background: 'type_color'
            }
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Number of participants',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'participants'
        },
        {
            label: 'Contact',
            icon: <Mail sx={icon} />,
            type: types.Chip,
            value: 'contact_name'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
        {
            label: 'Team members',
            icon: <Group sx={icon} />,
            type: types.Chips,
            value: 'teamMembers',
            props: {
                id: 'member_id',
                text: 'member_name',
                background: 'member_color'
            }
        },
        {
            label: 'Sales value',
            icon: <Sell sx={icon} />,
            type: types.Currency,
            value: 'salesValue'
        },
        {
            label: 'Trainers cost',
            icon: <Payments sx={icon} />,
            type: types.Currency,
            value: 'sessions'
        },
        {
            label: 'Additional expenses cost',
            icon: <Payments sx={icon} />,
            type: types.Currency,
            value: 'extras'
        },
        {
            label: 'Margin',
            icon: <Percent sx={icon} />,
            type: types.Text,
            value: 'margin'
        },
        {
            label: 'Invoice Rules',
            icon: <Percent sx={icon} />,
            type: types.ProjectInvoice,
            value: 'invoiceRules',
            props: {
                id: 'id',
                text: 'status_name',
                background: 'status_color',
                value: 'value'
            }
        },
        {
            label: 'Context',
            icon: <Subject sx={icon} />,
            type: types.Text,
            value: 'context'
        }
    ]
}

const extrasFields = [
    {
        title: 'Expense',
        width: 3,
        name: 'name',
        type: types.Text,
        readonly: false
    },
    {
        title: 'Price',
        width: 2,
        name: 'price',
        type: types.Number,
        readonly: false
    },
    {
        title: 'Units',
        width: 2,
        name: 'units',
        type: types.Number,
        readonly: false
    },
    {
        title: 'Total',
        width: 2,
        name: 'total',
        type: types.Number,
        readonly: true
    },
    {
        title: '',
        name: '',
        width: 1,
        type: types.Button,
        readonly: false
    }
]

const sessionsFields = [
    {
        title: 'Trainer',
        width: 2,
        name: 'projectArea_trainer_name',
        type: types.Text,
        readonly: true
    },
    {
        title: 'Moment',
        width: 2,
        name: 'moment',
        type: types.Text,
        readonly: true
    },
    {
        title: 'Hourly cost',
        width: 2,
        name: 'projectExpenseTrainer_price',
        additionalName: 'projectArea_trainer_hourlyPrice',
        type: types.Number,
        readonly: false
    },
    {
        title: 'Hours',
        width: 2,
        name: 'hours',
        type: types.Number,
        readonly: true
    },
    {
        title: 'Total',
        width: 2,
        name: 'total',
        type: types.Number,
        readonly: true
    }
]

const Proposal = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [openForm, setOpenForm] = useState<boolean>(false)
    const [openExpenseForm, setOpenExpenseForm] = useState<boolean>(false)
    const [incorrectInvoice, setIncorrectInvoice] = useState<boolean>(false)

    const invoice = useRef(null)

    const project = useAppSelector(state => state.projects.info)
    const sessions = useAppSelector(state => state.projects.expenses)
    const extras = useAppSelector(state => state.projects.extras)
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])
    const frequentExpenses = useAppSelector(state => state.entities[Entity.frequentExpense])

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const {projectId} = useParams()

    useEffect(() => {
        dispatch(getExpenseTrainers({id: Number(projectId)}))
        dispatch(getExpenseExtras({id: Number(projectId)}))
        dispatch(getEntities({entity: Entity.frequentExpense}))
        dispatch(getEntities({entity: Entity.projectStatus}))
    }, [projectId, dispatch])

    const invoiceOptions = useMemo(() => statuses.filter(item => item.invoiceable), [statuses])

    const {expenseExtras, totalExtras} = useMemo(() => {
        const expenseExtras: ExpenseExtras[] = []

        const totalExtras = extras.reduce((acc, item) => {
            const total = item.price * item.units

            expenseExtras.push({
                ...item,
                total: total
            })

            return acc + total

        }, 0)

        return {expenseExtras, totalExtras}

    }, [extras])

    const {expenseSessions, totalSessions} = useMemo(() => {
        const expenseSessions: any[] = []

        const totalSessions = sessions.reduce((acc, item) => {
            const price = item.projectExpenseTrainer_price || item.projectArea_trainer_hourlyPrice
            const hours = item.hours

            const total = price && hours ? Number(price) * Number(hours) : null

            expenseSessions.push({
                ...item,
                total: total
            })

            return acc + (total ? total : 0)

        }, 0)

        return {expenseSessions, totalSessions}

    }, [sessions])

    const data: {[key: string]: any} = useMemo(() => {
        const margin = project && project.salesValue && (totalExtras || totalSessions) ?
            (Number(project.salesValue) - totalExtras - totalSessions) / (totalExtras + totalSessions) * 100 : 0

        return {
            ...project,
            extras: totalExtras,
            sessions: totalSessions,
            margin: numberFormatter(margin)
        }

    }, [project, totalSessions, totalExtras])

    const proposalInitialValues = useMemo(() => {
        return {
            salesValue: project ? project.salesValue : null,
            invoiceRules: [],
        }
    }, [project])

    const expenseInitialValues = useMemo(() => {
        return {
            id: null,
            name: '',
            cost: 0,
            units: 0
        }
    }, [])

    const handleChangeExtras = useCallback((name: string, value: string | number, id: number) => {
        const extra = extras.find(item => item.id === id)

        if (extra) {
            const obj = {
                ...extra,
                [name]: value
            }

            dispatch(updateExpenseExtras({data: [obj]}))
        }
    }, [extras, dispatch])

    const handleDeleteExtras = useCallback((id?: number) => {
        if (id) {
            dispatch(deleteExpenseExtras({ids: [id]}))
        }
    }, [dispatch])

    const handleChangeSessions = useCallback((name: string, value: string | number, id: number) => {
        const session = sessions.find(item => item.id === id)

        if (session) {
            const obj = {
                projectExpenseTrainer_price: name === 'projectExpenseTrainer_price' || name === 'projectArea_trainer_hourlyPrice' ? value : session.projectExpenseTrainer_price || session.projectArea_trainer_hourlyPrice || 0,
                id: id
            }

            dispatch(updateExpenseTrainers({view: View.sessionExpenses, data: [obj]}))
        }
    }, [sessions, dispatch])

    const onItemClick = useCallback((name: string) => {
        if (data) {
            const field = config.fields.find(item => item.value === name)
            const value = data[name]

            if (field && value) {
                const text = field.type === types.Date ? dayjs(value).format(DateFormate) :
                    field.type === types.DateTime ? dayjs(value).format(DateTimeFormate) :
                    field.type === types.Currency ? currencyFormatter(value) :
                    field.type === types.Chips ? value.map((item: any) => item.member_name).join('\n') :
                    field.type === types.ProjectInvoice ? value.filter((item: any) => !!item.value).map((item: any) => item.status_name + ' - ' + item.value).join('\n') :
                    data[name]

                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text)
                }
            }
        }
    }, [data])

    const handleProposalFormOpen = useCallback(() => {
        setOpenForm(open => !open)
    }, [])

    const handleProposalConfirm = useCallback((values: any) => {
        const obj: any = {
            id: Number(projectId),
            salesValue: values.salesValue
        }

        if (invoice.current) {
            const invoiceRules = Object.keys(invoice.current).map(item => {
                return {
                    value: invoice.current ? invoice.current[item] : 0,
                    statusId: Number(item)
                }
            })

            obj.invoiceRules = invoiceRules
        }
            
        dispatch(updateProjects({data: [{...obj}]}))

        handleProposalFormOpen()
    }, [projectId, dispatch, handleProposalFormOpen])

    const handleExpenseFormOpen = useCallback(() => {
        setOpenExpenseForm(open => !open)
    }, [])

    const handleCreateExtraConfirm = useCallback((values: any) => {
        if (projectId) {
            const obj = {
                name: values.name,
                price: values.cost,
                units: values.units,
                projectId: Number(projectId)
            }

            dispatch(createExpenseExtras({data: [obj]}))
        }

        handleExpenseFormOpen()
    }, [projectId, dispatch, handleExpenseFormOpen])

    const getOptions = useCallback((name: string) => {
        if (name === 'id') {
            return frequentExpenses
        }

        return []
    }, [frequentExpenses])

    const getAutocomplete = useCallback((name: string, value: any) => {
        if (name === 'id') {
            const data = frequentExpenses.find(item => item.id === value)

            return {
                name: data ? data.name : '',
                cost: data ? data.cost : 0
            }
        } else {
            return undefined
        }
    }, [frequentExpenses])

    const getRulesComponent = useCallback((name: string) => {
        if (name === 'invoiceRules') {
            const values: {[key: string]: number} = {}

            project && project.invoiceRules.forEach(item => {
                values[item.status_id.toString()] = Number(item.value)
            })

            return <InvoiceRules values={values} options={invoiceOptions} onChange={handleChangeInvoice} />
        }

        return <div></div>  
    }, [project, invoiceOptions])

    const handleChangeInvoice = (value: any) => {
        invoice.current = value
        const arr: number[] = Object.values(value)
        const sum = arr.reduce((acc, item) => acc + item, 0)

        if (sum && sum !== 100) {
            setIncorrectInvoice(true)
        } else {
            setIncorrectInvoice(false)
        }
    }

    const emptyTop = project && project.accepted && mobile

    return (
        <div style={{padding: '20px'}}>
            {project &&
                <React.Fragment>
                    {!emptyTop && <Top>
                        <Breadcrumbs margin={'0 20px 20px 0'} items={breadcrumbs} data={{name: project.name, section: 'Projects'}} />
                        {project && !project.accepted &&
                            <Chip
                                variant='outlined'
                                color='secondary'
                                label={intl.formatMessage({id: "proposal.ChangeProposal"})}
                                onClick={handleProposalFormOpen}
                                sx={chip}
                                icon={<Create />}
                            />
                        }
                    </Top>}
                    <Card
                        config={config}
                        data={data}
                        presentation
                        tooltips={intl.formatMessage({id: "proposal.ClickToCopy"})}
                        onItemClick={onItemClick}
                    />
                </React.Fragment>
            }
            <div style={{padding: '20px 0'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ProposalSheet
                            title={intl.formatMessage({id: "proposal.TrainersResources"})}
                            fields={sessionsFields}
                            data={expenseSessions}
                            margin={10}
                            onChange={handleChangeSessions}
                            total={totalSessions}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProposalSheet
                            title={intl.formatMessage({id: "proposal.AdditionalExpenses"})}
                            fields={extrasFields}
                            data={expenseExtras}
                            margin={10}
                            handleCreate={handleExpenseFormOpen}
                            onChange={handleChangeExtras}
                            onIconClick={handleDeleteExtras}
                            total={totalExtras}
                        />
                    </Grid>
                </Grid>
            </div>
            <EditForm
                open={openForm}
                handleClose={handleProposalFormOpen}
                handleConfirm={handleProposalConfirm}
                title={intl.formatMessage({id: "proposal.ChangeProposal"})}
                config={proposalForm}
                initialValues={proposalInitialValues}
                getComponent={getRulesComponent}
                disabled={incorrectInvoice}
            />
            <EditForm
                open={openExpenseForm}
                handleClose={handleExpenseFormOpen}
                handleConfirm={handleCreateExtraConfirm}
                title={intl.formatMessage({id: "proposal.CreateExpense"})}
                config={expenseForm}
                getOptions={getOptions}
                initialValues={expenseInitialValues}
                getAutocomplete={getAutocomplete}
            />
        </div>
    )
}

export default Proposal