import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import api from 'utils/api'

type Item = {
    name: string
    config?: {
        fieldsOrder: string[],
        fields: Field[]
        [key: string]: any
    }
}

type Field = {
    name: number | string
    width: number
    type?: string
    color?: string
    itemId?: string
    itemLabel?: string
    itemColor?: string
    itemValue?: string
    link?: string
    status?: string
    days?: string
}

interface IState {
    data: {[key: string]: Item}
    requests: {[key: string]: boolean}
    pending: boolean
}

const initialState: IState = {
    data: {},
    requests: {},
    pending: false
}

export const getSheet = createAsyncThunk('sheets/getSheet', async (data: {name: string}) => {
    const response = await api(ApiRoute.getspreadsheet, data)
    return response.data
}, {condition: (data, {getState}) => {
        const {sheets} = getState() as {sheets: IState}
        const key = data.name
        const pending = sheets.requests[key as string]

        if (pending) {
            return false
        }
    }
})

export const updateSheet = createAsyncThunk('sheets/updateSheet', async (data: {name: string, data: Item[]}) => {
    const response = await api(ApiRoute.savespreadsheet, {data: data.data})
    return response.data
})

export const sheetsSlice = createSlice({
    name: 'sheets',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getSheet.pending, (state, action) => {
                const key = action.meta.arg.name
                state.pending = true
                state.requests[key as string] = true
            })
            .addCase(getSheet.fulfilled, (state, action) => {
                const key = action.meta.arg.name
                state.data[key as keyof typeof initialState] = action.payload ? action.payload : {name: key}
                state.pending = false
                state.requests[key as string] = false
            })
            .addCase(getSheet.rejected, (state, action) => {
                const key = action.meta.arg.name
                state.pending = false
                state.requests[key as string] = false
            })

            .addCase(updateSheet.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateSheet.fulfilled, (state, action) => {
                const key = action.meta.arg.name
                state.data[key as keyof typeof initialState] = action.payload[0]
                state.pending = false
            })
            .addCase(updateSheet.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default sheetsSlice