import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getAcademiaAppointments, createAcademiaAppointments, updateAcademiaAppointments, deleteAcademiaAppointments} from 'slices/academias'
import {getEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import FilterForm from 'components/FilterForm'
import Bottom from 'components/SheetBottom'
import {formConfig, filterConfig} from 'enums/academias/areas'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {IContext} from 'containers/Employee/Academia/interfaces'
import {Entity, Sheet} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string
}

type Filters = {
    areaId: number | null,
    trainerId: number | null
}

const defaultSheetFields: any = []

const AcademiaAreas = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [appointmentId, setAppointmentId] = useState<number | null>(null)
    const [filters, setFilters] = useState<Filters>({areaId: null, trainerId: null})
    const editFormTitle = useRef<string>('')

    const appointments = useAppSelector(state => state.academias.appointments)
    const areas = useAppSelector(state => state.entities[Entity.area])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.academiaProgram])
    const user = useAppSelector(state => state.auth.user)

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()

    useEffect(() => {
        if (academiaId) {
            dispatch(getAcademiaAppointments({id: Number(academiaId)}))
            dispatch(getEntities({entity: Entity.trainer}))
            dispatch(getEntities({entity: Entity.area}))
            dispatch(getSheet({name: Sheet.academiaProgram}))
        }
    }, [dispatch, academiaId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'areaId') {
            return areas
        }

        if (name === 'trainerId') {
            return trainers
        }

        return []
    }, [areas, trainers])

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setAppointmentId(documentId)
            editFormTitle.current = 'Change record'
        } else {
            editFormTitle.current = 'Create record'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setAppointmentId(null)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(academiaId)

        if (appointmentId) {
            obj.id = appointmentId
            dispatch(updateAcademiaAppointments({data: [{...obj}]}))
        } else {
            dispatch(createAcademiaAppointments({data: [{...obj}]}))
        }

        handleClose()
    }

    const handleDelete = () => {
        dispatch(deleteAcademiaAppointments({ids: checked}))
        setCheck([])
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'areaId') {
            return areas
        } else if (name === 'trainerId') {
            return dependencyField && dependencyValue ?
                trainers.filter(trainer => trainer.areas.find(area => area.areaId === dependencyValue)) : trainers
        } else {
            return []
        }
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaProgram,
                data: [{
                    name: Sheet.academiaProgram,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaProgram,
                data: [{
                    name: Sheet.academiaProgram,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const initialValues = useMemo(() => {
        const appointment = appointments.find(item => item.id === appointmentId)

        return {
            areaId: appointment ? appointment.areaId : null,
            trainerId: appointment ? appointment.trainerId : null
        }
    }, [appointments, appointmentId])

    const currentAppointments = useMemo(() => Object.values(filters).find(item => !!item) ? appointments.filter(appointment => {
        const areaFilter = filters.areaId ? appointment.areaId === filters.areaId : true
        const trainerFilter = filters.trainerId ? appointment.trainerId === filters.trainerId : true
    
        return areaFilter && trainerFilter
    }) : appointments, [appointments, filters])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Container margin={BottomBarHeight + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={currentAppointments}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom height={BottomBarHeight} checked={!!checked.length} handleDelete={handleDelete} handleCreate={handleOpen} />
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={formConfig}
                getOptions={getOptions}
                initialValues={initialValues}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filterConfig}
            />
        </React.Fragment>
    )
}

export default AcademiaAreas