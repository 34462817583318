import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 300,
        type: types.Text
    },
    {
        name: 'status_name',
        title: 'Create on project status',
        width: 300,
        type: types.Chip,
        color: 'status_color'
    },
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Create on project status',
        type: types.Chip,
        name: 'statusId',
        required: false,
        data: Entity.projectStatus,
    }
]

export const entities = [Entity.projectStatus]