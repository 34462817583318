import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'name',
        title: 'Project',
        width: 400,
        type: types.Text
    },
    {
        name: 'commercialOwner_name',
        title: 'Commercial owner',
        width: 200,
        type: types.Chip,
        color: 'commercialOwner_color'
    },
    {
        name: 'client_name',
        title: 'Client',
        width: 200,
        type: types.Chip
    },
    {
        name: 'ends',
        title: 'End date',
        width: 200,
        type: types.Date,
    },
    {
        name: 'commissionValue',
        title: 'Commission',
        width: 200,
        type: types.Percent
    },
    {
        name: 'value',
        title: 'Commission value',
        width: 200,
        type: types.Currency
    }
]

export const filterConfig = [
    {
        title: 'Filter by Project',
        name: 'id',
        type: types.Chip
    },
    {
        title: 'Filter by Client',
        name: 'clientId',
        type: types.Chip
    },
    {
        title: 'Filter by Commercial owner',
        name: 'commercialOwnerId',
        type: types.Chip
    },
    {
        title: 'End date',
        name: 'ends',
        start: 'start',
        end: 'end',
        type: types.Date
    }
]

export const formConfig = [
    {
        title: 'Commission',
        type: types.Number,
        name: 'commissionValue',
        required: false
    },
    {
        title: 'Commercial owner',
        type: types.Chip,
        name: 'commercialOwnerId',
        required: false
    }
]