import React, {useMemo} from 'react'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import {currencyFormatter, numberFormatter} from 'utils/formatter'

interface IComponent {
    value: number | null
    type: 'currency' | 'number'
}

const Input = styled.div<{border: string, text: string}>`
    height: 100%;
    width: 100%;
    padding: 0 10px;
    background: transparent;
    border: 2px solid transparent;
    color: ${props => props.text};
`

const Numeric = (props: IComponent) => {
    const theme = useTheme()

    const value = useMemo(() => {
        return props.type === 'currency' ? currencyFormatter(props.value) : numberFormatter(props.value)
    }, [props.value, props.type])
    
    return (
        <Input
            border={theme.palette.primary.main}
            text={theme.palette.text.primary}
        >
            {value}
        </Input>
    )
}

export default React.memo(Numeric)
