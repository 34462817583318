import React from 'react'
import TextField from '@mui/material/TextField'

interface IComponent {
    value: string,
    title?: string,
    name: string,
    multiline?: boolean,
    disabled: boolean,
    onChange?: (name: string, value: string) => void,
    size?: 'small',
    controlled: boolean
}

const Text = (props: IComponent) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.controlled) {
            if (typeof props.onChange === 'function') {
                props.onChange(props.name, e.target.value)
            }
        }
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!props.controlled) {
            if (props.value !== e.target.value) {
                if (typeof props.onChange === 'function') {
                    props.onChange(props.name, e.target.value)
                }
            }
        }
    }

    return (
        <TextField
            disabled={props.disabled}
            value={props.controlled ? props.value : undefined}
            defaultValue={props.controlled ? undefined : props.value}
            InputLabelProps={{ shrink: true }}
            label={props.title}
            name={props.name}
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            multiline={!!props.multiline}
            minRows={props.multiline ? 3 : undefined}
            sx={props.size && {input: {fontSize: 14}}}
            size={props.size}
        />
    )
}

export default React.memo(Text)
