import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Chip from '@mui/material/Chip'
import PlaylistRemove from '@mui/icons-material/PlaylistRemove'
import Create from '@mui/icons-material/Create'
import Person from '@mui/icons-material/Person'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Mail from '@mui/icons-material/Mail'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Group from '@mui/icons-material/Group'
import Subject from '@mui/icons-material/Subject'
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet'
import Phone from '@mui/icons-material/Phone'
import Grading from '@mui/icons-material/Grading'
import EventAvailable from '@mui/icons-material/EventAvailable'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {updateProjects, deleteProjects, updateProjectFiles} from 'slices/projects'
import {getEntities, createEntities} from 'slices/entities'
import Card from 'components/Card'
import EditForm from 'components/EditForm'
import Breadcrumbs from 'components/Breadcrumbs'
import {types} from 'enums/fields'
import {DocumentActions, AcademiaCorporate} from 'enums/utils'
import {updateForm} from 'enums/projects/projects'
import routes from 'enums/routes'
import {Entity, View} from 'api/enums'

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
`

const chip = {
    width: '180px',
    marginBottom: 2
}

const icon = {
    fontSize: 18
}

const infoConfig = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Type',
            icon: <AutoFixHigh sx={icon} />,
            type: types.Chip,
            value: 'type_name',
            props: {
                id: 'type_id',
                text: 'type_name',
                background: 'type_color'
            }
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Sales close date',
            icon: <EventAvailable sx={icon} />,
            type: types.Date,
            value: 'salesCloseDate'
        },
        {
            label: 'Number of participants',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'participants'
        },
        {
            label: 'Contact',
            icon: <Mail sx={icon} />,
            type: types.Chip,
            value: 'contact_name'
        },
        {
            label: 'Contact email',
            icon: <Mail sx={icon} />,
            type: types.Text,
            value: 'contact_email'
        },
        {
            label: 'Contact phone',
            icon: <Phone sx={icon} />,
            type: types.Text,
            value: 'contact_phone'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
        {
            label: 'Team members',
            icon: <Group sx={icon} />,
            type: types.Chips,
            value: 'teamMembers',
            props: {
                id: 'id',
                text: 'member_name',
                background: 'member_color'
            }
        },
        {
            label: 'Commercial owner',
            icon: <AccountBalanceWallet sx={icon} />,
            type: types.Chip,
            value: 'commercialOwner_name',
            props: {
                id: 'commercialOwnerId',
                text: 'commercialOwner_name',
                background: 'commercialOwner_color'
            }
        },
        {
            label: 'Context',
            icon: <Subject sx={icon} />,
            type: types.Text,
            value: 'context'
        },
        {
            label: 'Documents',
            icon: <Grading sx={icon}/>,
            type: types.File,
            value: 'allDocs',
            editable: true,
            props: {
                id: 'fileId',
                text: 'file_name',
                value: 'file_link'
            }
        }
    ]
}

const breadcrumbs = [
    {
        name: 'section',
        path: `/${routes.Projects}`
    },
    {
        name: 'name'
    }
]

const Overview = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    const project = useAppSelector(state => state.projects.info)
    const projectTypes = useAppSelector(state => state.entities[Entity.projectType])
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])
    const members = useAppSelector(state => state.entities[Entity.teamMember])
    const contacts = useAppSelector(state => state.entities[Entity.clientContact])
    const clients = useAppSelector(state => state.entities[Entity.client])

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const projectStatuses = useMemo(() => {
        return statuses.filter(status => {
            const stage = project ? project.status_stage : 0

            return !status.academia && (stage === status.stage || stage + 1 === status.stage)
        })
    }, [statuses, project])

    const filteredProjectTypes = useMemo(() => {
        return projectTypes.filter(type => type.project && type.id !== AcademiaCorporate)
    }, [projectTypes])

    const handleFormOpen = () => {
        dispatch(getEntities({entity: Entity.client}))
        dispatch(getEntities({entity: Entity.clientContact}))
        dispatch(getEntities({entity: Entity.teamMember}))
        dispatch(getEntities({entity: Entity.projectStatus}))
        dispatch(getEntities({entity: Entity.projectType}))

        setOpenForm(true)
    }

    const handleFormClose = () => {
        setOpenForm(false)
    }

    const handlePopupOpen = () => {
        setOpenPopup(openPopup => !openPopup)
    }

    const handleDelete = () => {
        if (project) {
            const id = project.id
            dispatch(deleteProjects({ids: [id]}))
        }

        handlePopupOpen()
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}

        if (project) {
            obj.id = project.id
            
            dispatch(updateProjects({data: [{...obj}]}))
        }

        handleFormClose()
    }

    const handleUpload = (name: string, value: File[] | number) => {
        if (project) {
            if (typeof value === 'number') {
                const form = new FormData()

                const data = [
                    {
                        id: project.id,
                        allDocs: [value]
                    }
                ]

                form.append('entity', Entity.project)
                form.append('view', View.projectDetailed)
                form.append('data', JSON.stringify(data))

                dispatch(updateProjectFiles({data: form, type: DocumentActions.detach}))
            } else {
                const form = new FormData()

                const data = [
                    {
                        id: project.id,
                        allDocs: [...value].map((item: File, index: number) => {
                            const id = `$file=${index + 1}`
                            form.append(id, item)

                            return id
                        })
                    }
                ]

                form.append('entity', Entity.project)
                form.append('view', View.projectDetailed)
                form.append('data', JSON.stringify(data))

                dispatch(updateProjectFiles({data: form, type: DocumentActions.attach}))
            }
        }
    }

    const handleCreate = (name: string, value: string, dependencyValue: any) => {
        if (name === 'clientId') {
            dispatch(createEntities({
                entity: Entity.client,
                data: [{name: value}]
            }))
        }

        if (name === 'contactId') {
            dispatch(createEntities({
                entity: Entity.clientContact,
                data: [{
                    name: value,
                    clientId: dependencyValue
                }]
            }))
        }
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'typeId') {
            return filteredProjectTypes
        }

        if (name === 'statusId') {
            return projectStatuses
        }

        if (name === 'clientId') {
            return clients
        }
        
        if (name === 'contactId') {
            return dependencyField && dependencyValue ?
                contacts.filter((contact: {[key: string]: any}) => contact[dependencyField] === dependencyValue) : contacts
        }
        
        if (name === 'teamMembers') {
            return members
        }

        if (name === 'commercialOwnerId') {
            return members.filter(item => dependencyValue.includes(item.id))
        }
        
        return []
    }

    const initialValues = useMemo(() => {
        return {
            name: project ? project.name : '',
            starts: project ? project.starts : null,
            ends: project ? project.ends : null,
            salesCloseDate: project ? project.salesCloseDate : null,
            participants: project ? project.participants : '',
            context: project ? project.context : '',
            typeId: project ? project.typeId : null,
            statusId: project ? project.statusId : null,
            clientId: project ? project.clientId : null,
            contactId: project ? project.contactId : null,
            teamMembers: project ? project.teamMembers.map((item: any) => item.teamMemberId) : [],
            commercialOwnerId: project ? project.commercialOwnerId : null,
        }
    }, [project])

    const config = useMemo(() => {
        return project ? updateForm.map(item => {
            const data = {...item}

            if (project.accepted) {
                if (item.name === 'starts' || item.name === 'ends' || item.name === 'clientId' || item.name === 'salesCloseDate') {
                    data.disabled = true
                }
            }

            if (item.name === 'typeId' && project.typeId === AcademiaCorporate) {
                data.disabled = true
            }

            return data
        }) : updateForm

    }, [project])

    return (
        <div style={{padding: '20px'}}>
            {project &&
                <React.Fragment>
                    <Top>
                        <Breadcrumbs margin={'0 20px 20px 0'} items={breadcrumbs} data={{name: project.name, section: 'Projects'}} />
                        <div style={{display: 'flex', overflow: 'hidden'}}>
                            <Chip
                                variant='outlined'
                                color='secondary'
                                label={intl.formatMessage({id: "project.ChangeProject"})}
                                onClick={handleFormOpen}
                                sx={{...chip, marginRight: 2}}
                                icon={<Create />}
                            />
                            <Chip
                                variant='outlined'
                                color='error'
                                label={intl.formatMessage({id: "project.DeleteProject"})}
                                onClick={handlePopupOpen}
                                sx={chip}
                                icon={<PlaylistRemove />}
                            />
                        </div>
                    </Top>
                    <Card
                        config={infoConfig}
                        data={project}
                        presentation
                        onChange={handleUpload}
                    />
                    <EditForm
                        open={openForm}
                        handleClose={handleFormClose}
                        handleConfirm={handleConfirm}
                        onCreate={handleCreate}
                        title={intl.formatMessage({id: "project.ChangeProject"})}
                        config={config}
                        getOptions={getOptions}
                        initialValues={initialValues}
                    />
                    <Dialog
                        open={openPopup}
                        onClose={handlePopupOpen}
                    >
                        <DialogTitle>
                            {intl.formatMessage({id: "app.ConfirmYourAction"})}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {intl.formatMessage({id: "project.WantToDeleteProject"}, {name: project.name})}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePopupOpen}>{intl.formatMessage({id: "app.Cancel"})}</Button>
                            <Button onClick={handleDelete} variant='contained'>{intl.formatMessage({id: "app.Confirm"})}</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            }
        </div>
    )
}

export default Overview