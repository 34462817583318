import {types} from 'enums/fields'

export const sheet = [
    {
        name: 'projectArea_area_name',
        title: 'Area',
        width: 300,
        type: types.Chip,
        color: 'projectArea_area_color'
    },
    {
        name: 'starts',
        title: 'Date',
        width: 200,
        type: types.DateTime
    },
    {
        name: 'trainee_name',
        title: 'Trainee',
        width: 200,
        type: types.Chip
    },
    {
        name: 'status_name',
        title: 'Status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'summary',
        title: 'Summary',
        width: 200,
        type: types.Textarea
    },
    {
        name: 'report',
        title: 'Report',
        width: 200,
        type: types.Textarea
    }
]

export const form = [
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: true,
        disabled: true,
        editDependency: ''
    },
    {
        title: 'Summary',
        type: types.Textarea,
        name: 'summary',
        required: false,
        editDependency: ''
    },
    {
        title: 'Report',
        type: types.Textarea,
        name: 'report',
        required: false,
        editDependency: 'statusId'
    }
]

export const filter = [
    {
        title: 'Filter by Area',
        name: 'projectAreaId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainee',
        name: 'traineeId',
        type: types.Chip
    },
    {
        title: 'Filter by Status',
        name: 'statusId',
        type: types.Chip
    }
]