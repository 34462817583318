import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProjectAppointments, createProjectAppointments, updateProjectAppointments, deleteProjectAppointments} from 'slices/projects'
import {getEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import Bottom from 'components/SheetBottom'
import {formConfig} from 'enums/projects/appointments'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {Entity, Sheet} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string
}

const defaultSheetFields: any = []

const Program = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [appointmentId, setAppointmentId] = useState<number | null>(null)
    const editFormTitle = useRef<string>('')

    const appointments = useAppSelector(state => state.projects.appointments)
    const areas = useAppSelector(state => state.entities[Entity.area])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.projectProgram])
    const user = useAppSelector(state => state.auth.user)

    const {projectId} = useParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (projectId) {
            dispatch(getProjectAppointments({id: Number(projectId)}))
            dispatch(getSheet({name: Sheet.projectProgram}))
        }
    }, [dispatch, projectId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleOpen = (documentId?: number) => {
        dispatch(getEntities({entity: Entity.trainer}))
        dispatch(getEntities({entity: Entity.area}))

        if (documentId) {
            setAppointmentId(documentId)
            editFormTitle.current = 'Change record'
        } else {
            editFormTitle.current = 'Create record'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setAppointmentId(null)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(projectId)

        if (appointmentId) {
            obj.id = appointmentId
            dispatch(updateProjectAppointments({data: [{...obj}]}))
        } else {
            dispatch(createProjectAppointments({data: [{...obj}]}))
        }

        handleClose()
    }

    const handleDelete = () => {
        dispatch(deleteProjectAppointments({ids: checked}))
        setCheck([])
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'areaId') {
            return areas
        } else if (name === 'trainerId') {
            return dependencyField && dependencyValue ?
                trainers.filter(trainer => trainer.areas.find(area => area.areaId === dependencyValue)) : trainers
        } else {
            return []
        }
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.projectProgram,
                data: [{
                    name: Sheet.projectProgram,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.projectProgram,
                data: [{
                    name: Sheet.projectProgram,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const initialValues = useMemo(() => {
        const appointment = appointments.find(item => item.id === appointmentId)

        return {
            areaId: appointment ? appointment.areaId : null,
            trainerId: appointment ? appointment.trainerId : null
        }
    }, [appointments, appointmentId])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Container margin={BottomBarHeight + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={appointments}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom height={BottomBarHeight} checked={!!checked.length} handleDelete={handleDelete} handleCreate={handleOpen} />
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={formConfig}
                getOptions={getOptions}
                initialValues={initialValues}
            />
        </React.Fragment>
    )
}

export default Program