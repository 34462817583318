import React from 'react'
import styled from '@emotion/styled'
import Text from 'components/FormInputs/Text'
import Date from 'components/FormInputs/Date'
import Chip from 'components/FormInputs/Chip'
import Suggestions from 'components/FormInputs/Suggestions'
import Numeric from 'components/FormInputs/Numeric'
import Colors from 'components/FormInputs/Color'
import File from 'components/FormInputs/File'
import Mentions from 'components/FormInputs/Mentions'
import Phone from 'components/FormInputs/Phone'
import {types} from 'enums/fields'
import {Option, Color, Value} from 'components/EditForm/interfaces'

const Container = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: inline-flex;
`

interface IComponent {
    value: Value,
    name: string,
    title: string,
    helper?: string,
    type: string,
    min?: Value,
    max?: Value,
    onChange: (name: string, value: Value) => void,
    options: Option[] | string[],
    disabled: boolean,
    adding?: boolean,
    onCreate: (name: string, value: string) => void,
    getComponent?: (name: string, value: Value) => JSX.Element
}

const FormField = (props: IComponent) => {
    const getField = () => {
        switch (props.type) {
            case types.Date:
                return (
                    <Date
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        datetime={false}
                        min={props.min as string}
                        max={props.max as string}
                        disabled={props.disabled}
                    />
                )

            case types.DateTime:
                return (
                    <Date
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        datetime={true}
                        min={props.min as string}
                        max={props.max as string}
                        disabled={props.disabled}
                    />
                )

            case types.Chip:
                return (
                    <Chip
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        options={props.options as Option[]}
                        disabled={props.disabled}
                        adding={props.adding}
                        onCreate={props.onCreate}
                        helper={props.helper}
                    />
                )

            case types.Chips:
                return (
                    <Chip
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        options={props.options as Option[]}
                        multiple
                        disabled={props.disabled}
                        adding={props.adding}
                        onCreate={props.onCreate}
                        helper={props.helper}
                    />
                )

            case types.Suggestions:
                return (
                    <Suggestions
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        options={props.options as string[]}
                        disabled={props.disabled}
                    />
                )

            case types.Number:
                return (
                    <Numeric
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        disabled={props.disabled}
                        controlled={true}
                    />
                )

            case types.Textarea:
                return (
                    <Text
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        multiline={true}
                        disabled={props.disabled}
                        controlled={true}
                    />
                )

            case types.Phone:
                return (
                    <Phone
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        disabled={props.disabled}
                    />
                )

            case types.Color:
                return (
                    <Colors
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        options={props.options as Color[]}
                        disabled={props.disabled}
                    />
                )

            case types.Image:
                return (
                    <File
                        value={props.value ? typeof props.value === 'string' ? props.value : props.value.name : ''}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        disabled={props.disabled}
                        accept={"image/*"}
                    />
                )

            case types.File:
                return (
                    <File
                        value={props.value ? typeof props.value === 'string' ? props.value : props.value.name : ''}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        disabled={props.disabled}
                    />
                )

            case types.Mention:
                return (
                    <Mentions
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        disabled={props.disabled}
                        options={props.options}
                    />
                )


            case types.ProjectInvoice: {
                const component = typeof props.getComponent === 'function' ?
                    props.getComponent(props.name, props.value) : undefined

                return component
            }

            case types.AcademiaInvoice: {
                const component = typeof props.getComponent === 'function' ?
                    props.getComponent(props.name, props.value) : undefined

                return component
            }

            default:
                return (
                    <Text
                        value={props.value}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        multiline={false}
                        disabled={props.disabled}
                        controlled={true}
                    />
                )
        }
    }
    
    return (
        <Container>
            {getField()}
        </Container>
    )
}

export default React.memo(FormField)
