import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react'
import Card from 'components/CardsList/Card'

type Item = {
    [key: string]: any
}

interface IField {
    label: string
    icon: JSX.Element
    type: string
    value: string
    props?: {
        id: string
        text?: string
        background?: string
        value?: string
    }
}

interface IComponent {
    containerComponent: any
    itemsCount: number
    paperHeight: number
    onClick?: (id: any) => void
    data: Item[]
    color?: string
    config: {
        id: string
        title: string
        fields: IField[]
    }
}

const marginRight = 20
const marginBottom = 20

const CardsList = (props: IComponent) => {
    const [startIndex, setStartIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(0)
    const [paperWidth, setPaperWidth] = useState(0)

    const containerRef = useRef<HTMLDivElement | null>(null)

    const groupsCount = useMemo(() => Math.ceil(props.data.length / props.itemsCount), [props.data.length, props.itemsCount])
    const groupHeight = useMemo(() => props.paperHeight + marginBottom, [props.paperHeight])

    const getVisibleItems = useCallback(() => {
        if (props.containerComponent) {
            const {scrollTop} = props.containerComponent
            const {clientHeight} = props.containerComponent

            const count = Math.ceil(clientHeight  / groupHeight)
            const currentIndex = Math.trunc(scrollTop / groupHeight)
            const start = Math.max(currentIndex - 1, 0)
            const end = currentIndex + count + 1

            setStartIndex(start * props.itemsCount)
            setEndIndex(end * props.itemsCount)
        }
    }, [props.containerComponent, props.itemsCount, groupHeight])

    const getWidth = useCallback(() => {
        if (props.containerComponent) {
            const style = window.getComputedStyle(props.containerComponent)
            const paddingLeft = parseInt(style.paddingLeft) || 0
            const paddingRight = parseInt(style.paddingRight) || 0
            const containerWidth = props.containerComponent.clientWidth - paddingLeft - paddingRight
            const width = containerWidth / props.itemsCount - marginRight * (props.itemsCount - 1) / props.itemsCount

            setPaperWidth(width)
        }
    }, [props.containerComponent, props.itemsCount])

    const updateLayout = useCallback(() => {
        getWidth()
        getVisibleItems()
    }, [getWidth, getVisibleItems])

    useEffect(() => {
        updateLayout()
    }, [updateLayout])

    useEffect(() => {
        if (props.containerComponent) {
            props.containerComponent.addEventListener('scroll', getVisibleItems)
        }

        return () => {
            if (props.containerComponent) {
                props.containerComponent.removeEventListener('scroll', getVisibleItems)
            }
        }

    }, [props.containerComponent, getVisibleItems])

    useEffect(() => {
        window.addEventListener('resize', updateLayout)

        return () => {
            window.removeEventListener('resize', updateLayout)
        }
    }, [updateLayout])

    const onClick = useCallback((id: any) => {
        if (typeof props.onClick === 'function') {
            props.onClick(id)
        }
    }, [props.onClick])

    const getListItem = (index: number, data: any) => {
        return <div
            key={data[props.config.id]}
            style={{position: 'absolute',
                height: props.paperHeight + 'px',
                width: paperWidth + 'px',
                transform: `translate(${(paperWidth + marginRight) * (index % props.itemsCount)}px, ${Math.trunc(index / props.itemsCount) * groupHeight}px)`}}>
                <Card
                    config={props.config}
                    data={data}
                    onClick={onClick}
                    color={props.color}
                />
            </div>
    }

    const getList = () => {
        const list = []

        for (let index = startIndex; index < endIndex; index++) {
            const data = props.data[index]

            if (!data) break

            list.push(getListItem(index, data))
        }

        return list
    }

    const list = getList()

    const height = useMemo(() => groupsCount * groupHeight, [groupsCount, groupHeight])

    return (
        <div ref={containerRef} style={{height: '100%'}}>
            <div style={{height: height + 'px', position: 'relative'}}>
                {list}
            </div>
        </div>
    )
}

export default React.memo(CardsList)