import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {IHeader, View, Views} from 'components/Calendar/interfaces'

const Container = styled.div`
    position: absolute;
    height: 60px;
    padding: 10px;
    display: flex;
    left: 200px;
    align-items: center;
`

const views: Views = {
    Month: 'dayGridMonth',
    Week: 'dayGridWeek',
    Day: 'dayGridDay',
    List: 'listMonth'
}

const Header = (props: IHeader) => {
    const intl = useIntl()

    const modeChange = (e: SelectChangeEvent<unknown>) => {
        props.modeChange(e.target.value as View)
    }

    return (
        <Container>
            <Select
                value={props.view}
                onChange={modeChange}
                size='small'
                sx={{
                    width: 100,
                    fontSize: 14,
                    letterSpacing: '0.5px',

                    '.MuiSelect-select': {
                        padding: '6px 14px'
                    }
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }
                }}
            >
                {Object.keys(views).map(item =>
                    <MenuItem key={item} value={views[item]}>
                        {intl.formatMessage({id: `calendar.${item}`})}
                    </MenuItem>
                )}
            </Select>
        </Container>
    )
}

export default Header