import React from 'react'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import TimeInput from './TimeInput'

const Container = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: inline-flex;
`

const Input = styled.div`
    width: 100%;
    margin-right: 20px;
`

const Button = styled.div`
    display: flex;
    align-items: center;
`

interface IComponent {
    id: number
    start: string | null
    end: string | null
    onChange: (id: number, name?: string, value?: string | null) => void
}

const Fields = (props: IComponent) => {
    const onChange = (name: string, value: string | null) => {
        props.onChange(props.id, name, value)
    }

    const onDelete = () => {
        props.onChange(props.id)
    }

    return (
        <Container>
            <Input>
                <TimeInput
                    onChange={onChange}
                    value={props.start}
                    label={'Start'}
                    name={'starts'}
                    max={props.end}
                />
            </Input>
            <Input>
                <TimeInput
                    onChange={onChange}
                    value={props.end}
                    label={'End'}
                    name={'ends'}
                    min={props.start}
                />
            </Input>
            <Button>
                <IconButton onClick={onDelete}>
                    <Delete />
                </IconButton>
            </Button>
        </Container>
    )
}

export default React.memo(Fields)
