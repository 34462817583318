import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getEntities, updateEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import FilterForm from 'components/FilterForm'
import EditForm from 'components/EditForm'
import {filterConfig, formConfig} from 'enums/commissions'
import {TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {Entity, View, Sheet} from 'api/enums'

const Top = styled.div<{height: number}>`
    height: ${props => props.height}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    justify-content: space-between;
`

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
`

const chip = {
    width: '180px',
    marginLeft: 'auto'
}

const defaultSheetFields: any = []

const Commissions = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [filters, setFilters] = useState<{id: number | null, clientId: number | null, commercialOwnerId: number | null, end: string | null, start: string | null}>({id: null, clientId: null, commercialOwnerId: null, end: null, start: null})
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [openEditForm, setOpenEditForm] = useState<boolean>(false)
    const [projectId, setProjectId] = useState<number | null>(null)

    const editFormTitle = useRef<string>('')

    const projects = useAppSelector(state => state.entities[View.commissions])
    const clients = useAppSelector(state => state.entities[Entity.client])
    const members = useAppSelector(state => state.entities[Entity.teamMember])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.commissions])
    const user = useAppSelector(state => state.auth.user)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEntities({view: View.commissions}))
        dispatch(getEntities({entity: Entity.client}))
        dispatch(getEntities({entity: Entity.teamMember}))
        dispatch(getSheet({name: Sheet.commissions}))
    }, [dispatch])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: any) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleOpen = useCallback((documentId?: number) => {
        if (documentId) {
            setProjectId(documentId)
            editFormTitle.current = 'Change record'
        }

        setOpenEditForm(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpenEditForm(false)
        setProjectId(null)
    }, [])

    const handleConfirm = useCallback((values: any) => {
        const obj = {...values}

        if (projectId) {
            obj.id = projectId

            dispatch(updateEntities({
                view: View.commissions,
                data: [obj]
            }))

            setProjectId(null)
        }

        handleClose()
    }, [projectId, dispatch, handleClose])

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.commissions,
                data: [{
                    name: Sheet.commissions,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.commissions,
                data: [{
                    name: Sheet.commissions,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const getOptions = (name: string) => {
        if (name === 'commercialOwnerId') {
            const project = commissions.find((item: any) => item.id === projectId)

            return project ? project.teamMembers.map((item: any) => {
                return {
                    id: item.teamMemberId,
                    name: item.member_name
                }
            }) : []
        }
        
        return []
    }

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'clientId') {
            return clients
        }

        if (name === 'id') {
            return projects.map((item: any) => {
                return {
                    id: item.id,
                    name: item.name
                }
            })
        }

        if (name === 'commercialOwnerId') {
            return members
        }

        return []

    }, [clients, projects, members])

    const handleFilterOpen = useCallback(() => {
        setOpenFilter(openFilter => !openFilter)
    }, [])

    const handleFilterConfirm = useCallback((values: any) => {
        setFilters({
            ...filters,
            ...values
        })

        handleFilterOpen()
    }, [filters, handleFilterOpen])

    const commissions = useMemo(() => {
        return projects.map((item: any) => {
            return {
                ...item,
                value: Number(item.salesValue) && Number(item.commissionValue) ? Number(item.salesValue) / 100 * Number(item.commissionValue) : 0
            }
        })
    }, [projects])

    const currentCommissions = useMemo(() => filters.clientId || filters.id || filters.commercialOwnerId ||
        filters.end || filters.start ? commissions.filter((item: any) => {
            const clientFilter = filters.clientId ? item.clientId === filters.clientId : true
            const projectFilter = filters.id ? item.id === filters.id : true
            const memberFilter = filters.commercialOwnerId ? item.commercialOwnerId === filters.commercialOwnerId : true
            const startFilter = filters.start ? dayjs(item.ends).isAfter(dayjs(filters.start)) : true
            const endFilter = filters.end ? dayjs(item.ends).isBefore(dayjs(filters.end)) : true
        
            return clientFilter && projectFilter && memberFilter && startFilter && endFilter
    }) : commissions, [commissions, filters])

    const appliedFilters = !!Object.values(filters).find(item => !!item)

    const initialValues = useMemo(() => {
        const project = commissions.find((item: any) => item.id === projectId)

        return {
            commissionValue: project ? project.commissionValue : 0,
            commercialOwnerId: project ? project.commercialOwnerId : null
        }
    }, [commissions, projectId])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Top height={TopBarHeight}>
                <Chip
                    icon={<FilterAlt />}
                    label={'Filters'}
                    sx={chip}
                    onClick={handleFilterOpen}
                    variant={appliedFilters ? 'filled' : 'outlined'}
                    color='secondary'
                />
            </Top>
            <Container margin={TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={currentCommissions}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <EditForm
                open={openEditForm}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={formConfig}
                initialValues={initialValues}
                getOptions={getOptions}
            />
            <FilterForm
                open={openFilter}
                handleClose={handleFilterOpen}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filterConfig}
            />
        </React.Fragment>
    )
}

export default Commissions