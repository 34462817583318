import React from 'react'
import MentionsInput from 'components/MentionsInput'

interface IComponent {
    value: string,
    title?: string,
    name: string,
    options: any,
    disabled: boolean,
    onChange: (name: string, value: string) => void
}

const Mentions = (props: IComponent) => {
    const onChange = (value: string) => {
        props.onChange(props.name, value)
    }

    return (
        <MentionsInput
            value={props.value}
            title={props.title}
            options={props.options}
            onChange={onChange}
        />
    )
}

export default React.memo(Mentions)
