const messages = {
    WrongCredentials: 'Incorrect login or password',
    RegularError: 'Something goes wrong',
    LoginError: 'Sign in is currently unavailable',

    LoginExists: 'This login already exists',
    EmailExists: 'This email already exists',

    ProjectHasNoSalesValue: 'Project cannot be accepted because it has no sales value',
    ProjectHasNoInvoiceRules: 'Project cannot be accepted because it doesn\'t have invoice rules',
    ProjectStatusRolledBack: 'Project status cannot be rolled back',
    AcademiaHasNoTrainees: 'Academia setup cannot be done because it doesn\'t have participants',

    RecordCreatingSuccess: 'Record has been successfully created',
    RecordsDeletingSuccess: 'Records have been successfully deleted',
    RecordUpdatingSuccess: 'Record has been successfully updated',

    ProjectsCreatingSuccess: 'Project has been successfully created',
    ProjectsDeletingSuccess: 'Project has been successfully deleted',
    ProjectsUpdatingSuccess: 'Project has been successfully updated',

    AcademiasCreatingSuccess: 'Academia has been successfully created',
    AcademiasDeletingSuccess: 'Academia has been successfully deleted',
    AcademiasUpdatingSuccess: 'Academia has been successfully updated',

    ActionSuccess: 'Action has been successfully done',

    AccCreatingSuccess: 'Account has been successfully created',

    SessionBook: 'Session has been successfully booked',
    SessionCancel: 'Session has been successfully canceled',

    DocumentSendingSuccess: 'Document has been successfully sent',

    DocumentsUpdatingSuccess: 'Documents have been successfully updated',

    InformationUpdatingSuccess: 'Information has been successfully updated',

    SessionsOverlap: 'Session overlaps another existing session',

    SessionIsNotAvailable: 'Session is not available',

    TimeslotsUpdatingSuccess: 'The schedule has been successfully updated',

    ResetPassSuccess: 'Password was successfully reset',

    ChangePassSuccess: 'Password was successfully changed',
    ChangePassError: 'Incorrect current password',
}

export default messages
