import React from 'react'
import styled from '@emotion/styled'
import {Outlet, Link, useLocation, useParams} from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import School from '@mui/icons-material/School'
import Groups from '@mui/icons-material/Groups'
import PendingActions from '@mui/icons-material/PendingActions'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Menu from 'components/Menu'
import routes from 'enums/routes'

const Button = styled.div`
    padding: 10px;
`

const main = [
    {
        title: 'Academias',
        path: routes.Trainings,
        icon: <School />
    },
    {
        title: 'Schedule',
        path: routes.Schedule,
        icon: <CalendarMonth />
    }
]

const trainingRoutes = [
    {
        title: 'Sessions',
        path: routes.Sessions,
        icon: <PendingActions />
    },
    {
        title: 'Participants',
        path: routes.Participants,
        icon: <Groups />
    }
]

const Trainer = () => {
    const {pathname} = useLocation()
    const {academiaId} = useParams()

    return (
        <React.Fragment>
            <Menu>
                {academiaId ?
                    <React.Fragment>
                        <List>
                            {trainingRoutes.map((item, index) => {
                                const to = `/${routes.Training}/${academiaId}/${item.path}`

                                return (
                                    <ListItem key={item.path} disablePadding>
                                        <ListItemButton component={Link} to={to} selected={to === pathname}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Divider />
                        <Button>
                            <IconButton component={Link} to={routes.Trainings}>
                                <ChevronLeft />
                            </IconButton>
                        </Button>
                    </React.Fragment>
                    :
                    <List>
                        {main.map((item, index) => (
                            <ListItem key={item.path} disablePadding>
                                <ListItemButton component={Link} to={item.path} selected={pathname === `/${item.path}`}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                }
            </Menu>
            <Box sx={{flexGrow: 1, minWidth: 0}}>
                <Outlet />
            </Box>
        </React.Fragment>
    )
}

export default Trainer