import React from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'

const Container = styled.div<{height: number}>`
    height: ${props => props.height}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    justify-content: flex-end;
`

interface IComponent {
    height: number
    checked: boolean
    hiddenButtons?: boolean
    createButtonText?: string
    deleteButtonText?: string
    createButtonDisabled?: boolean
    deleteButtonDisabled?: boolean
    handleDelete: () => void
    handleCreate: () => void
}

const Bottom = (props: IComponent) => {
    const intl = useIntl()

    return (
        <Container height={props.height}>
            {!props.hiddenButtons &&
                <React.Fragment>
                    {props.checked &&
                        <Tooltip title={props.deleteButtonText ? props.deleteButtonText : intl.formatMessage({id: "spreadsheet.DeleteRecords"})} placement="top">
                            <span>
                                <Fab onClick={props.handleDelete} color="error" size='small' disabled={props.deleteButtonDisabled}>
                                    <Delete />
                                </Fab>
                            </span>
                        </Tooltip>
                    }
                    <Tooltip title={props.createButtonText ? props.createButtonText : intl.formatMessage({id: "spreadsheet.CreateRecord"})} placement="top">
                        <span>
                            <Fab onClick={() => props.handleCreate()} disabled={props.createButtonDisabled} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                                <Add />
                            </Fab>
                        </span>
                    </Tooltip>
                </React.Fragment>
            }
        </Container>
    )
}

export default React.memo(Bottom)