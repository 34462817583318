import {Entity, Sheet as Sheets} from 'api/enums'
import Sheet from 'components/Sheet'
import {form, entities} from 'enums/entities/templates'

const Templates = () => {
    return (
        <Sheet
            data={Entity.smsTemplate}
            primaryId={'id'}
            textFilter={['name']}
            editFormFields={form}
            entities={entities}
            sheet={Sheets.smsTemplate}
        />
    )
}

export default Templates