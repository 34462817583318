import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'projectArea_area_name',
        title: 'Area',
        width: 300,
        type: types.Chip,
        color: 'projectArea_area_color'
    },
    {
        name: 'starts',
        title: 'Date',
        width: 200,
        type: types.DateTime
    },
    {
        name: 'hours',
        title: 'Hours',
        width: 200,
        type: types.Number
    },
    {
        name: 'projectArea_trainer_name',
        title: 'Trainer',
        width: 200,
        type: types.Chip,
        color: ''
    },
    {
        name: 'trainee_name',
        title: 'Trainee',
        width: 200,
        type: types.Chip,
        color: ''
    },
    {
        name: 'status_name',
        title: 'Status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'report',
        title: 'Report',
        width: 200,
        type: types.Text
    }
]

export const editFormFields = [
    {
        title: 'Area',
        type: types.Chip,
        name: 'projectAreaId',
        required: true
    },
    {
        title: 'Date',
        type: types.DateTime,
        name: 'starts',
        required: true
    },
    {
        title: 'Hours',
        type: types.Number,
        name: 'hours',
        required: false
    },
    {
        title: 'Trainee',
        type: types.Chip,
        name: 'traineeId',
        required: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: false
    },
    {
        title: 'Report',
        type: types.Textarea,
        name: 'report',
        required: false,
        disabled: true
    }
]

export const createFormFields = [
    {
        title: 'Area',
        type: types.Chip,
        name: 'projectAreaId',
        required: true
    },
    {
        title: 'Date',
        type: types.DateTime,
        name: 'starts',
        required: true
    },
    {
        title: 'Hours',
        type: types.Number,
        name: 'hours',
        required: false
    },
    {
        title: 'Trainee',
        type: types.Chip,
        name: 'traineeId',
        required: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: false
    }
]

export const filterConfig = [
    {
        title: 'Filter by Area',
        name: 'projectAreaId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainer',
        name: 'projectArea_trainerId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainee',
        name: 'traineeId',
        type: types.Chip
    },
    {
        title: 'Filter by Status',
        name: 'statusId',
        type: types.Chip
    }
]