import React from 'react'
import Autocomplete from 'components/Autocomplete'
import {Option} from 'components/EditForm/interfaces'

interface IComponent {
    value: number | number[] | null,
    title?: string,
    helper?: string,
    name: string,
    options: Option[],
    multiple?: boolean,
    disabled: boolean,
    adding?: boolean,
    onChange: (name: string, value: number | number[] | null) => void
    onCreate?: (name: string, value: string) => void,
    size?: 'small'
}

const Chip = (props: IComponent) => {
    const onChange = (name: string, value: number | number[] | null) => {
        props.onChange(name, value)
    }

    return (
        <React.Fragment>
            <Autocomplete
                options={props.options}
                onChange={onChange}
                value={props.value}
                title={props.title}
                name={props.name}
                multiple={props.multiple}
                disabled={props.disabled}
                adding={props.adding}
                helper={props.helper}
                onCreate={props.onCreate}
                size={props.size}
            />
        </React.Fragment>
    )
}

export default React.memo(Chip)
