import React from 'react'
import {styled} from '@mui/material/styles'
import Notes from '@mui/icons-material/Notes'
import Event from '@mui/icons-material/Event'
import Link from '@mui/icons-material/Link'
import FilterNone from '@mui/icons-material/FilterNone'
import FormatListBulleted from '@mui/icons-material/FormatListBulleted'
import EuroSymbol from '@mui/icons-material/EuroSymbol'
import Percent from '@mui/icons-material/Percent'
import AttachFile from '@mui/icons-material/AttachFile'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Share from '@mui/icons-material/Share'
import Numbers from '@mui/icons-material/Numbers'
import Phone from '@mui/icons-material/Phone'
import {types} from 'enums/fields'

interface IComponent {
    name: number | string,
    title: string,
    width: number,
    type: string
}

const Title = styled('div')((props) => ({
    width: '100%',
    letterSpacing: '0.5px',
    fontWeight: 500,
    fontSize: '14px',
    color: props.theme.palette.info.dark,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}))

const icon = {
    fontSize: 20,
    margin: '0 10px',
    color: 'info.dark'
}

const icons = {
    [types.Text]: <Notes sx={icon} />,
    [types.Textarea]: <Notes sx={icon} />,
    [types.Date]: <Event sx={icon} />,
    [types.DateTime]: <Event sx={icon} />,
    [types.Chip]: <Link sx={icon} />,
    [types.Chips]: <FormatListBulleted sx={icon} />,
    [types.Image]: <FilterNone sx={icon} />,
    [types.Currency]: <EuroSymbol sx={icon} />,
    [types.Phone]: <Phone sx={icon} />,
    [types.Percent]: <Percent sx={icon} />,
    [types.AcademiaInvoice]: <Percent sx={icon} />,
    [types.ProjectInvoice]: <Percent sx={icon} />,
    [types.File]: <AttachFile sx={icon} />,
    [types.Custom]: <ErrorOutline sx={icon} />,
    [types.Link]: <Share sx={icon} />,
    [types.Number]: <Numbers sx={icon} />
}

const Cell = (props: IComponent) => {
    return (
        <React.Fragment>
            {icons[props.type]}
            <Title>
                {props.title}
            </Title>
        </React.Fragment>
    )
}

export default React.memo(Cell)
