import {useEffect, useState, useRef, useMemo} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getTrainerBookings, getTrainerTimeslots, updateTrainerTimeslot} from 'slices/trainer'
import EventPopup from 'containers/Trainer/EventPopup'
import EditForm from 'containers/Trainer/EditForm'
import Calendar from 'components/Calendar'
import {EventHandlers} from 'components/Calendar/interfaces'
import {defaultEventColor} from 'enums/styles'

dayjs.extend(utc)

const Container = styled.div`
    display: flex;
    height: 100%;
`

const Schedule = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [copied, setCopied] = useState<{id: number, starts: string | null, ends: string | null}[]>([])

    const calendarRef = useRef<EventHandlers>(null)
    const day = useRef<string | null>(null)
    const daySlots = useRef<{id: number, starts: string | null, ends: string | null}[]>([])

    const sessions = useAppSelector(state => state.trainer.bookings)
    const slots = useAppSelector(state => state.trainer.slots)

    const intl = useIntl()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTrainerBookings())
        dispatch(getTrainerTimeslots())
    }, [dispatch])

    const handleOpen = (e: any) => {
        const date = dayjs.utc(e.dateStr).toISOString()
        const dateSlots = slots.filter(item => item.starts_day === date)
        day.current = date
        daySlots.current = dateSlots
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = (values: any) => {
        const date = dayjs(day.current)

        const y = date.year()
        const m = date.month()
        const d = date.date()

        const data = values.map((item: any) => {
            return {
                starts: dayjs(item.starts).set('year', y).set('month', m).set('date', d).toISOString(),
                ends: dayjs(item.ends).set('year', y).set('month', m).set('date', d).toISOString(),
            }
        })

        dispatch(updateTrainerTimeslot({data: data, filter: {starts_day: {equals: day.current}}}))

        handleClose()
    }

    const handleCopy = (values: any) => {
        setCopied(values)
    }

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = sessions.find(item => item.id === Number(id))

        if (event) {
            return <EventPopup
                id={event.id}
                name={event.trainee_name}
                title={event.projectArea_project_name}
                start={event.starts}
                link={event.projectArea_trainer_joinLink}
                onClose={onClose}
                type={event.sessionType_name}
                color={event.sessionType_color}
                typeId={event.sessionType_id}
                location={event.location}
            />
        }
    }

    const sessionsEvents = useMemo(() => {
        return sessions.map((item, index) => {
            return {
                id: item.id.toString(),
                title: item.trainee_name ? `${item.trainee_name} - ${item.projectArea_project_name}` : item.projectArea_project_name,
                start: item.starts,
                end: item.ends,
                backgroundColor: item.projectArea_area_color ? item.projectArea_area_color : defaultEventColor,
                borderColor: item.projectArea_area_color ? item.projectArea_area_color : defaultEventColor,
                textColor: '#666666'
            }
        })
    }, [sessions])

    const slotsEvents = useMemo(() => {
        return slots.map((item, index) => {
            return {
                id: item.id.toString(),
                title: '',
                start: item.starts,
                end: item.ends,
                backgroundColor: defaultEventColor + '33',
                borderColor: defaultEventColor,
                textColor: '#666666'
            }
        })
    }, [slots])

    const events = useMemo(() => {
        return [...sessionsEvents, ...slotsEvents]
    }, [sessionsEvents, slotsEvents])

    return (
        <Container>
            <Calendar
                defaultView={'dayGridMonth'}
                locale={intl.locale as any}
                ref={calendarRef}
                modeChangeControl={true}
                events={events}
                eventPopupContent={eventPopupContent}
                dateClick={handleOpen}
            />
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCopy={handleCopy}
                title={'Create timeslots'}
                initialValues={daySlots.current}
                contentPaste={copied}
            />
        </Container>
    )
}

export default Schedule