import React, {useRef, useState} from 'react'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import {ClickAwayListener} from '@mui/base/ClickAwayListener'

interface IComponent {
    defaultValue?: string
    disabled?: boolean
    onBlur?: (e: React.FocusEventHandler) => void
    onChange: (value: string) => void
    onSelect?: (value: string) => void
    options: string[]
    regex?: string
    matchAny?: boolean
    minChars?: number
    trigger?: string | string[]
    value?: string
    spacer?: string
    title?: string
}

const defaultProps = {
    defaultValue: '',
    disabled: false,
    onBlur: () => {},
    onChange: () => {},
    onSelect: () => {},
    options: [],
    regex: '^[A-Za-z0-9\\-_]+$',
    matchAny: false,
    minChars: 0,
    trigger: '@',
    value: null,
    spacer: ' ',
    title: ''
}

const MentionsInput = (props: IComponent) => {    
    const [helperVisible, setHelperVisible] = useState(false)
    const [trigger, setTrigger] = useState<string | null>(null)
    const [selection, setSelection] = useState(0)
    const [match, setMatch] = useState({length: 0, start: 0})
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)

    const recentValue = useRef(props.defaultValue)
    const refInput = useRef<HTMLElement | undefined>(undefined)

    const getMatch = (str: string, caret: number, providedOptions: any) => {
        const pattern = props.regex ? new RegExp(props.regex) : defaultProps.regex
        const triggers = props.trigger ? Array.isArray(props.trigger) ? props.trigger : [props.trigger] : [defaultProps.trigger]
        const triggersMatch = arrayTriggerMatch(triggers, pattern)
        const providedOptionsObject: any = {}

        triggers.sort()

        if (Array.isArray(providedOptions)) {
            triggers.forEach((triggerStr: string) => {
                providedOptionsObject[triggerStr as keyof typeof providedOptionsObject] = providedOptions
            })
        }

        let slugData = null

        for (let triggersIndex = 0; triggersIndex < triggersMatch.length; triggersIndex++) {
            const {triggerStr, triggerMatch, triggerLength} = triggersMatch[triggersIndex]

            for (let i = caret - 1; i >= 0; --i) {
                const substr = str.substring(i, caret)
                const match = substr.match(pattern)
                let matchStart = -1

                if (triggerLength > 0) {
                    const triggerIdx = triggerMatch ? i : i - triggerLength + 1

                    if (triggerIdx < 0) {
                        break
                    }

                    if (isTrigger(triggerStr, str, triggerIdx)) {
                        matchStart = triggerIdx + triggerLength
                    }

                    if (!match && matchStart < 0) {
                        break
                    }
                } else {
                    if (match && i > 0) {
                        continue
                    }

                    matchStart = i === 0 && match ? 0 : i + 1

                    if (caret - matchStart === 0) {
                        break
                    }
                }

                if (matchStart >= 0) {
                    const triggerOptions = providedOptionsObject[triggerStr]

                    if (triggerOptions == null) {
                        continue
                    }

                    const matchedSlug = str.substring(matchStart, caret)

                    const options = triggerOptions.filter((slug: string) => {
                        const idx = slug.toLowerCase().indexOf(matchedSlug.toLowerCase())
                        return idx !== -1 && (props.matchAny || idx === 0)
                    })

                    const currTrigger = triggerStr
                    const matchLength = matchedSlug.length

                    slugData = {
                        trigger: currTrigger, matchStart, matchLength, options
                    }
                }
            }
        }

        return slugData
    }

    const arrayTriggerMatch = (triggers: string[], expression: any) => {
        const triggersMatch = triggers.map((trigger: string) => ({
            triggerStr: trigger,
            triggerMatch: trigger.match(expression),
            triggerLength: trigger.length
        }))

        return triggersMatch
    }

    const isTrigger = (trigger: string, str: string, i: number) => {
        if (!trigger || !trigger.length) {
            return true
        }

        if (str.substring(i, i + trigger.length) === trigger) {
            return true
        }

        return false
    }

    const handleBlur = (e: any) => {
        resetHelper()

        if (typeof props.onBlur === 'function') {
            props.onBlur(e)
        }
    }

    const handleChange = (e: any) => {
        const str = e.target.value
        const caret = e.target.selectionEnd

        recentValue.current = str

        if (!str.length) {
            setHelperVisible(false)
        }

        setValue(e.target.value)

        if (!str.length || !caret) {
            return props.onChange(e.target.value)
        }
// !!!
        updateHelper(str, caret, props.options)

        if (!props.value) {
            setValue(e.target.value)
        }

        return props.onChange(e.target.value)
    }

    const handleKeyDown = (event: any) => {
        const optionsCount = options.length

        if (helperVisible) {
            switch (event.code) {
                case 'ArrowUp':
                    event.preventDefault()

                    if (optionsCount > 0) {
                        setSelection(Math.max(0, optionsCount + selection - 1) % optionsCount)
                    }

                    break
                case 'ArrowDown':
                    event.preventDefault()

                    if (optionsCount > 0) {
                        setSelection((selection + 1) % optionsCount)
                    }

                    break
                case 'Enter':
                    event.preventDefault()

                    handleSelection(selection)

                    break
                case 'Tab':
                    event.preventDefault()

                    handleSelection(selection)

                    break
            }
        }
    }

    const handleSelection = (idx: number) => {
        const spacer = props.spacer || defaultProps.spacer
        const slug = options[idx]
        const recent = recentValue.current || ''
        const part1 = trigger ? recent.substring(0, match.start - trigger.length) : ''
        const part2 = recent.substring(match.start + match.length)

        const event: any = {
            target: refInput.current
        }

        const changedStr = trigger + slug

        event.target.value = `${part1}${changedStr}${spacer}${part2}`

        handleChange(event)

        if (typeof props.onSelect === 'function') {
            props.onSelect(event.target.value)
        }

        resetHelper()

        const advanceCaretDistance = part1.length + changedStr.length + spacer.length

        updateCaretPosition(advanceCaretDistance)
    }

    const updateCaretPosition = (caret: any) => {
        setCaretPosition(caret)
    }

    const setCaretPosition = (caret: number) => {
        if (refInput.current) {
            (refInput.current as any).setSelectionRange(caret, caret)
        }
    }

    const updateHelper = (str: string, caret: number, options: any) => {
        const slug = getMatch(str, caret, options)

        if (slug) {
            const minChars = props.minChars ? props.minChars : defaultProps.minChars

            if (slug.matchLength >= minChars && (slug.options.length > 1 || (slug.options.length === 1 && (slug.options[0].length !== slug.matchLength || slug.options[0].length === 1)))) {
                setHelperVisible(true)
                setOptions(slug.options)
                setTrigger(slug.trigger)
                setMatch({start: slug.matchStart, length: slug.matchLength})
            } else {
                resetHelper()
            }
        } else {
            resetHelper()
        }
    }

    const resetHelper = () => {
        setHelperVisible(false)
        setSelection(0)
    }

    const renderAutocompleteList = () => {
        if (!helperVisible) {
            return
        }

        if (options.length === 0) {
            return
        }

        if (selection >= options.length) {
            setSelection(0)

            return
        }

        const optionNumber = options.length

        const helperOptions = options.slice(0, optionNumber).map((item: any, id: number) => {
            return (
                <MenuItem
                    key={item}
                    onClick={() => handleSelection(id)}
                    className={id === selection ? 'Mui-focusVisible' : undefined}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    {item}
                </MenuItem>
            )
        })

        return refInput.current && refInput.current.offsetParent && (
            <ClickAwayListener onClickAway={resetHelper}>
                <Popper
                    open={true}
                    anchorEl={refInput.current.offsetParent}
                    sx={{zIndex: 1400, width: refInput.current.offsetParent.clientWidth + 'px'}}
                    placement={'bottom'}
                >
                    <Paper>
                        <MenuList>
                            {helperOptions}
                        </MenuList>
                    </Paper>
                </Popper>
            </ClickAwayListener>
        )
    }

    const inputValue = typeof props.value !== 'undefined' && props.value !== null ? props.value : value ? value : props.defaultValue ? props.defaultValue : ''

    return (
        <React.Fragment>
            <TextField
                disabled={props.disabled}
                value={inputValue}
                InputLabelProps={{ shrink: true }}
                label={props.title || defaultProps.title}
                variant="outlined"
                fullWidth
                multiline
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputRef={refInput}
            />
            {renderAutocompleteList()}
        </React.Fragment>
    )
}

export default MentionsInput