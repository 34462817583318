import React from 'react'
import styled from '@emotion/styled'
import Fields from 'components/ProposalSheet/Fields'
import Cell from 'components/ProposalSheet/Cell'
import {types} from 'enums/fields'

const Container = styled.div`
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
`

type Field = {
    title: string,
    width: number,
    name: string,
    type: string,
    readonly: boolean,
    additionalName?: string
}

type Option = {
    id: number,
    name: string
}

type Item = {
    id: number,
    [key: string]: any
}

interface IComponent {
    fields: Field[],
    data: Item,
    margin: number,
    onChange?: (name: string, value: any, id: number) => void
    onClick?: (id?: number) => void,
    getOptions?: (name: string) => Option[]
}

const ListItem = (props: IComponent) => {
    const getOptions = (name: string) => {
        if (typeof props.getOptions === 'function') {
            return props.getOptions(name)
        } else {
            return []
        }
    }

    return (
        <Container>
            {props.fields.map(field => {
                const primary = props.data[field.name]

                const value = primary ? primary :
                    field.additionalName ? props.data[field.additionalName] : primary

                const name = primary ? field.name : field.additionalName ? field.additionalName : field.name

                const options = field.type === types.Suggestions ?
                        getOptions(name) : []
                

                return <Cell key={field.name} width={field.width} count={props.fields.length} margin={props.margin}>
                    <Fields
                        id={props.data.id}
                        value={value}
                        name={name}
                        type={field.type}
                        onChange={props.onChange}
                        disabled={field.readonly}
                        onClick={props.onClick}
                        options={options}
                    />
                </Cell>
            })}
    </Container>
    )
}

export default React.memo(ListItem)
