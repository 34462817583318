import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProjectSessions} from 'slices/projects'
import {getEntities} from 'slices/entities'
import Grid from 'components/Calendar'
import EventPopup from 'components/EventPopup'
import FilterForm from 'components/FilterForm'
import {EventHandlers} from 'components/Calendar/interfaces'
import {defaultEventColor, TopBarHeight} from 'enums/styles'
import {mainFilter} from 'enums/projects/sessions'
import {IContext} from 'containers/Employee/Academia/interfaces'
import {Entity} from 'api/enums'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

const Calendar = () => {
    const [filters, setFilters] = useState<{areaId: number | null, trainerId: number | null}>({areaId: null, trainerId: null})
    const calendarRef = useRef<EventHandlers>(null)

    const sessions = useAppSelector(state => state.projects.sessions)
    const info = useAppSelector(state => state.projects.info)
    const areas = useAppSelector(state => state.entities[Entity.area])
    const trainers = useAppSelector(state => state.entities[Entity.trainer])

    const {projectId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()
    const intl = useIntl()

    useEffect(() => {
        if (projectId && info) {
            dispatch(getProjectSessions({id: Number(projectId), type: info.typeId}))
            dispatch(getEntities({entity: Entity.area}))
            dispatch(getEntities({entity: Entity.trainer}))
        }
    }, [dispatch, projectId, info])

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'areaId') {
            return areas
        }

        if (name === 'trainerId') {
            return trainers
        }

        return []
    }, [areas, trainers])

    const eventPopupContent = (id: string, onClose: () => void) => {
        const event = sessions.find(item => item.id === Number(id))

        if (event) {
            return <EventPopup
                id={event.id}
                name={event.trainer_name}
                title={event.area_name}
                start={event.session_starts}
                onClose={onClose}
            />
        }
    }

    const currentSessions = useMemo(() => filters.areaId || filters.trainerId ? sessions.filter(session => {
        const areaFilter = filters.areaId ? session.areaId === filters.areaId : true
        const trainerFilter = filters.trainerId ? session.trainerId === filters.trainerId : true
    
        return areaFilter && trainerFilter
    }) : sessions, [sessions, filters])

    const events = useMemo(() => {
        return currentSessions.map((item, index) => {
            return {
                id: item.id.toString(),
                title: item.trainer_name ? `${item.trainer_name} - ${item.area_name}` : item.area_name,
                start: item.session_starts,
                end: item.session_ends,
                backgroundColor: item.area_color ? item.area_color : defaultEventColor,
                borderColor: item.area_color ? item.area_color : defaultEventColor,
                textColor: '#666666'
            }
        })
    }, [currentSessions])
    
    return (
        <Container margin={TopBarHeight}>
            <Grid
                defaultView={'dayGridMonth'}
                locale={intl.locale as any}
                ref={calendarRef}
                modeChangeControl={true}
                events={events}
                eventPopupContent={eventPopupContent}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={mainFilter}
            />
        </Container>
    )
}

export default Calendar