export enum ApiErrorCode {
    INTERNAL = 0,
    BAD_ARGUMENTS = 101,
    NO_DATA = 102,
    LOGIN_NOT_UNIQUE = 201,
    EMAIL_NOT_UNIQUE = 202,
    DB_CONNECTION_FAILED = 301,
    UNKNOWN_ENTITY = 401,
    UNKNOWN_ACTION = 421,
    PROJECT_HAS_NO_SALES_VALUE = 501,
    PROJECT_HAS_NO_INVOICE_RULES = 502,
    ACADEMIA_HAS_NO_TRAINEES = 503,
    PROJECT_INVALID_STATUS = 504,
    PROJECT_STATUS_ROLLBACK = 505,
    FILE_UPLOAD_ERROR = 601,
    SESSION_OVERLAP = 701,
    TIMESLOT_OVERLAP = 801
}

export enum Plugins {
    auth = 'auth',
    fileserver = 'fileserver',
    appointments = 'appointments'
}

export enum Action {
    sendprojectinvoice = 'sendprojectinvoice',
    sendacademiainvoice = 'sendacademiainvoice',
    createusertrainee = 'createusertrainee',
    createusertrainer = 'createusertrainer',
    resetpassword = 'resetpassword',
    changepassword = 'changepassword',
    createuserteammember = 'createuserteammember'
}

export enum View {
    projects = 'projects',
    projectDetailed = 'projectDetailed',
    academias = 'academias',
    academiaDetailed = 'academiaDetailed',
    trainerAcademias = 'trainerAcademias',
    traineeProjects = 'traineeProjects',
    projectTrainees = 'projectTrainees',
    commissions = 'commissions',
    projectSessions = 'projectSessions',
    academiaAreas = 'academiaAreas',
    academiaSessions = 'academiaSessions',
    academiaSessionsReserved = 'academiaSessionsReserved',
    trainerSessions = 'trainerSessions',
    trainerSessionsReserved = 'trainerSessionsReserved',
    traineeSessions = 'traineeSessions',
    trainerExpenses = 'trainerExpenses',
    sessionExpenses = 'sessionExpenses',
    projectTasks = 'projectTasks',
    globalCalendar = 'globalCalendar',
    freeTrainerSlots = 'freeTrainerSlots',
    users = 'users'
}

export enum Entity {
    role = 'role',
    user = 'user',
    userData = 'userData',

    area = 'area',
    client = 'client',
    project = 'project',
    trainer = 'trainer',
    trainee = 'trainee',
    teamMember = 'teamMember',
    defaultTask = 'defaultTask',
    clientContact = 'clientContact',
    frequentExpense = 'frequentExpense',

    projectArea = 'projectArea',
    projectSession = 'projectSession',
    projectTrainee = 'projectTrainee',
    projectTask = 'projectTask',
    projectExpenseExtra = 'projectExpenseExtra',
    projectExpenseTrainer = 'projectExpenseTrainer',
    projectInvoiceRule = 'projectInvoiceRule',
    projectInvoice = 'projectInvoice',
    academiaInvoiceRule = 'academiaInvoiceRule',
    academiaInvoice = 'academiaInvoice',
    trainerSlot = 'trainerSlot',

    file = 'file',
    projectTraineeFile = 'projectTraineeFile',

    sessionType = 'sessionType',
    projectType = 'projectType',

    projectStatus = 'projectStatus',
    sessionStatus = 'sessionStatus',
    taskStatus = 'taskStatus',
    invoiceStatus = 'invoiceStatus',

    smsTemplate = 'smsTemplate',

    excelDump = 'excelDump'
}

export enum ApiRoute {
    signin = 'signin',
    signup = 'signup',
    signout = 'signout',
    getenv = 'getenv',

    getspreadsheet = 'getspreadsheet',
    savespreadsheet = 'savespreadsheet',

    getusers = 'getusers',

    getareas = 'getareas',
    createareas = 'createareas',
    updateareas = 'updateareas',
    deleteareas = 'deleteareas',

    getclients = 'getclients',
    createclients = 'createclients',
    updateclients = 'updateclients',
    deleteclients = 'deleteclients',

    gettrainers = 'gettrainers',
    createtrainers = 'createtrainers',
    updatetrainers = 'updatetrainers',
    deletetrainers = 'deletetrainers',

    gettrainees = 'gettrainees',
    createtrainees = 'createtrainees',
    updatetrainees = 'updatetrainees',
    deletetrainees = 'deletetrainees',

    getsessiontypes = 'getsessiontypes',

    getprojects = 'getprojects',
    getproject = 'getproject',
    createprojects = 'createprojects',
    updateprojects = 'updateprojects',
    deleteprojects = 'deleteprojects',

    getprojecttypes = 'getprojecttypes',
    getprojectstatuses = 'getprojectstatuses',
    getsessionstatuses = 'getsessionstatuses',
    gettaskstatuses = 'gettaskstatuses',

    getprojectsessions = 'getprojectsessions',
    createprojectsessions = 'createprojectsessions',
    updateprojectsessions = 'updateprojectsessions',
    deleteprojectsessions = 'deleteprojectsessions',

    getprojecttrainees = 'getprojecttrainees',
    createprojecttrainees = 'createprojecttrainees',
    updateprojecttrainees = 'updateprojecttrainees',
    deleteprojecttrainees = 'deleteprojecttrainees',

    getprojecttasks = 'getprojecttasks',
    createprojecttasks = 'createprojecttasks',
    updateprojecttasks = 'updateprojecttasks',
    deleteprojecttasks = 'deleteprojecttasks',
    autocompleteprojecttasks = 'autocompleteprojecttasks',

    getprojectexpensetrainers = 'getprojectexpensetrainers',

    getprojectexpenseextras = 'getprojectexpenseextras',
    createprojectexpenseextras = 'createprojectexpenseextras',
    updateprojectexpenseextras = 'updateprojectexpenseextras',
    deleteprojectexpenseextras = 'deleteprojectexpenseextras',

    getclientcontacts = 'getclientcontacts',
    createclientcontacts = 'createclientcontacts',
    updateclientcontacts = 'updateclientcontacts',
    deleteclientcontacts = 'deleteclientcontacts',

    getteammembers = 'getteammembers',

    getacademias = 'getacademias',
    getacademia = 'getacademia',
    createacademias = 'createacademias',
    updateacademias = 'updateacademias',
    deleteacademias = 'deleteacademias',

    getacademiaareas = 'getacademiaareas',
    createacademiaareas = 'createacademiaareas',
    updateacademiaareas = 'updateacademiaareas',
    deleteacademiaareas = 'deleteacademiaareas',

    getacademiasessions = 'getacademiasessions',
    getacademiasessionsreserved = 'getacademiasessionsreserved',
    createacademiasessions = 'createacademiasessions',
    updateacademiasessions = 'updateacademiasessions',
    deleteacademiasessions = 'deleteacademiasessions',

    /* trainee app */

    gettraineeacademias = 'gettraineeacademias',
    gettraineeacademiaareas = 'gettraineeacademiaareas',
    gettraineeacademiaavailability = 'gettraineeacademiaavailability',

    createtraineeacademiasession = 'createtraineeacademiasession',
    canceltraineeacademiasession = 'canceltraineeacademiasession',

    /* trainer app */

    gettrainerslots = 'gettrainerslots',
    updatetrainerslots = 'updatetrainerslots',

    gettrainersessions = 'gettrainersessions',
    gettrainersessionsreserved = 'gettrainersessionsreserved',
    updatetrainersessions = 'updatetrainersessions',

    gettraineracademias = 'gettraineracademias',

    gettrainersessionssummary = 'gettrainersessionssummary',

    /* file server */

    filesgetall = 'filesgetall',
    filesupload = 'filesupload',
    filesinfo = 'filesinfo',

    /* common entities */

    getall = 'getall',

    create = 'create',
    update = 'update',
    delete = 'delete',

    attach = 'attach',
    detach = 'detach',

    createwfiles = 'createwfiles',
    updatewfiles = 'updatewfiles',
    attachwfiles = 'attachwfiles',
    detachwfiles = 'detachwfiles',

    action = 'action',

    /* views */

    getcommissions = 'getcommissions',

    /* forecast */

    getforecast = 'getforecast',

    /* backups */

    createexceldump = 'createexceldump',
    deleteexceldumps = 'deleteexceldumps'

}

export enum Sheet {
    academiaSessions = 'academiaSessions',
    academiaProgram = 'academiaProgram',
    academiaParticipants = 'academiaParticipants',
    academiaTasks = 'academiaTasks',

    corporateProjectSessions = 'corporateProjectSessions',
    mainProjectSessions = 'mainProjectSessions',
    projectProgram = 'projectProgram',
    projectParticipants = 'projectParticipants',
    projectTasks = 'projectTasks',

    tasks = 'tasks',
    academiaInvoice = 'academiaInvoice',
    projectInvoice = 'projectInvoice',
    commissions = 'commissions',

    area = 'area',
    client = 'client',
    trainer = 'trainer',
    trainee = 'trainee',
    teamMember = 'teamMember',
    defaultTask = 'defaultTask',
    clientContact = 'clientContact',
    frequentExpense = 'frequentExpense',
    projectType = 'projectType',
    smsTemplate = 'smsTemplate',
    users = 'users',

    excelDump = 'excelDump',

    trainerAcademiaParticipants = 'trainerAcademiaParticipants',
    trainerAcademiaSessions = 'trainerAcademiaSessions'
}