import Sheet from 'components/Sheet'
import {Entity, Action, Sheet as Sheets} from 'api/enums'
import {form, entities} from 'enums/entities/trainees'
import {useAppDispatch} from 'app/hooks'
import {actEntity} from 'slices/entities'
import CreateButton from 'components/Fields/CreateButton'

const Trainees = () => {
    const dispatch = useAppDispatch()

    const getCustomItem = (value: any, column: any, data: any) => {
        return (
            <CreateButton
                name={data.name}
                hidden={data[column.name]}
                documentId={data.id}
                onClick={onClick}
            />
        )
    }

    const onClick = (id: number) => {
        dispatch(actEntity({
            entity: Entity.trainee,
            action: Action.createusertrainee,
            ids: [id]
        }))
    }

    return (
        <Sheet
            data={Entity.trainee}
            primaryId={'id'}
            textFilter={['name', 'phone', 'email', 'company_name', 'role']}
            editFormFields={form}
            entities={entities as any}
            sheet={Sheets.trainee}
            bottom
            getCustomItem={getCustomItem}
        />
    )
}

export default Trainees