import React from 'react'
import Text from 'components/FormInputs/Text'
import Numeric from 'components/FormInputs/Numeric'
import Button from 'components/ProposalSheet/Button'
import {types} from 'enums/fields'

type Option = {
    id: number,
    name: string
}

interface IComponent {
    id: number,
    value: any,
    name: string,
    title?: string,
    helper?: string,
    type: string,
    min?: any,
    max?: any,
    onChange?: (name: string, value: any, id: number) => void,
    onClick?: (id?: number) => void,
    disabled: boolean,
    options: Option[] | string[],
}

const Fields = (props: IComponent) => {
    const onChange = (name: string, value: any) => {
        if (typeof props.onChange === 'function') {
            props.onChange(name, value, props.id)
        }
    }

    const getField = () => {
        switch (props.type) {
            case types.Button:
                return (
                    <Button
                        id={props.id}
                        onClick={props.onClick}
                    />
                )

            case types.Number:
                return (
                    <Numeric
                        value={props.value as number}
                        name={props.name}
                        onChange={onChange}
                        disabled={props.disabled}
                        size='small'
                        controlled={false}
                    />
                )

            default:
                return (
                    <Text
                        value={props.value as string}
                        name={props.name}
                        onChange={onChange}
                        disabled={props.disabled}
                        size='small'
                        controlled={false}
                    />
                )
        }
    }

    return (
        <React.Fragment>
            {getField()}
        </React.Fragment>
    )
}

export default React.memo(Fields)
