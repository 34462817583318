import Sheet from 'components/Sheet'
import {form, entities} from 'enums/entities/contacts'
import {Entity, Sheet as Sheets} from 'api/enums'

const Contacts = () => {
    return (
        <Sheet
            data={Entity.clientContact}
            primaryId={'id'}
            textFilter={['name']}
            editFormFields={form}
            entities={entities as any}
            sheet={Sheets.clientContact}
            bottom
        />
    )
}

export default Contacts