import React from 'react'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Item from 'components/CardsList/Item'

const Name = styled.div`
    margin-bottom: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const paper = {
    padding: '20px',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
    backgroundColor: 'primary.light'
}

interface IField {
    label: string
    icon: JSX.Element
    type: string
    value: string
    props?: {
        id: string
        text?: string
        background?: string
        value?: string
    }
}

interface IComponent {
    onClick?: (id: any) => void
    data: any
    color?: string
    config: {
        id: string
        title: string
        fields: IField[]
    }
}

const Card = (props: IComponent) => {
    const onClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick(props.data[props.config.id])
        }
    }

    return (
        <Paper onClick={onClick} sx={{...paper, background: props.color}}>
            {props.config.title && <Name>{props.data[props.config.title]}</Name>}
            {props.config.fields.map(field => {

                return <Item
                        key={field.value}
                        field={field}
                        data={props.data}
                    />
            })}
        </Paper>
    )
}

export default React.memo(Card)