import React, {useRef, useState, useCallback, useImperativeHandle} from 'react'
import FullCalendar from '@fullcalendar/react'
import {EventClickArg, MoreLinkArg, EventApi} from '@fullcalendar/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import Grid from 'components/Calendar/Grid'
import EventPopup from 'components/Calendar/EventPopup'
import ListPopup from 'components/Calendar/ListPopup'
import Header from 'components/Calendar/Header'
import {IComponent, View, EventHandlers} from 'components/Calendar/interfaces'

const Container = styled.div<{breakpoint: number}>`
    width: 100%;
    position: relative;

    .fc .fc-toolbar.fc-header-toolbar {
        padding: 10px 0;
        margin-bottom: 0;
        border-bottom: 1px solid #dddddd;
        background: #f5f5f5;
        height: 60px;
    }

    .fc .fc-toolbar-title {
        font-size: 20px;
        font-weight: 500;
        color: #444444;
        letter-spacing: 0.5px;
        text-align: center;
    }

    .fc-theme-standard .fc-scrollgrid {
        border: none;
    }    

    .fc-toolbar-chunk {
        min-width: 200px;
    }

    .fc .fc-button {
        border: none;
	    font-size: 12px;
        padding: 5px 10px;
        border-radius: 0;
    }

    .fc .fc-today-button {
        text-transform: capitalize;
        letter-spacing: 0.5px;
        font-size: 14px;
        font-weight: 500;
    }

    .fc .fc-button-primary {
        background-color: transparent;
        color: #444444;
    }

    .fc .fc-button-primary:focus,
    .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc .fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
    }

    .fc .fc-button-primary:not(:disabled).fc-button-active,
    .fc .fc-button-primary:not(:disabled):active {
        background-color: transparent;
        color: #444444;
    }

    .fc .fc-daygrid-more-link {
        width: 100%;
        color: #444444;
        font-size: 13px;
    }

    .fc .fc-daygrid-more-link:hover {
        background-color: transparent;
    }

    .fc .fc-daygrid-day-frame {
        min-height: 100px;
    }

    .fc-h-event .fc-event-title {
        text-overflow: ellipsis;
    }

    .fc-h-event .fc-event-time {
        overflow: visible;
        font-weight: 500;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px;
        letter-spacing: 0.5px;
        color: #708085;
        font-weight: 500;
        font-size: 12px;
    }

    .fc .fc-daygrid-day-number {
        color: #444444;
        font-size: 13px;
        padding: 5px;
    }

    .fc .fc-event {
        cursor: pointer;
    }

    .fc-theme-standard .fc-list {
        border: none;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background-color: transparent;
    }

    .fc .fc-daygrid-event {
        font-size: 13px;
        margin-top: 2px;
    }

    .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
        padding: 2px;
    }

    .fc .fc-list-day-cushion {
        letter-spacing: 0.5px;
        color: #708085;
        font-weight: 500;
        font-size: 12px;
    }

    .fc-list-event {
        font-size: 13px;
    }

    .fc-list-empty {
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #708085;
    }

    @media (max-width: ${props => props.breakpoint}px) {
        .fc-toolbar-chunk {
            min-width: auto;
        }

        .fc .fc-toolbar-title {
            font-size: 15px;
        }
    }
`

const Calendar = React.forwardRef<EventHandlers, IComponent>((props, ref) => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('md'))

    const [openEventPopup, setOpenEventPopup] = useState<HTMLElement | null>(null)
    const [openListPopup, setOpenListPopup] = useState<boolean>(false)
    const [mode, setMode] = useState<View>(props.defaultView)
    const event = useRef<EventApi | null>(null)
    const moreLink = useRef<MoreLinkArg | null>(null)
    const grid = useRef<FullCalendar>(null)

    const listPopupClose = useCallback(() => {
        setOpenListPopup(false)
    }, [])

    useImperativeHandle(ref, () => ({
        listPopupClose
    }), [listPopupClose])

    const modeChange = (value: View) => {
        if (typeof props.modeChange === 'function') {
            props.modeChange(value)
        } else {
            if (grid.current) {
                const api = grid.current.getApi()
                api.changeView(value)
                setMode(value)
            }
        }
    }

    const eventClick = (data: EventClickArg) => {
        if (typeof props.eventClick === 'function') {
            data.jsEvent.preventDefault()
            props.eventClick(data.event.id)
        } else {
            eventSet(data.event, data.el)
        }
    }

    const eventPopupClose = useCallback(() => {
        setOpenEventPopup(null)
    }, [])

    const moreLinkClick = (data: MoreLinkArg) => {
        moreLink.current = data
        setOpenListPopup(true)
        return <div />
    }

    const eventSet = (data: EventApi, el: HTMLElement) => {
        event.current = data
        setOpenEventPopup(el)
    }

    const listPopupEventClick = (event: EventApi, el: HTMLElement) => {
        if (typeof props.eventClick === 'function') {
            props.eventClick(event.id)
        } else {
            eventSet(event, el)
        }
    }

    const modeChangeToWeek = useCallback(() => {
        if (grid.current) {
            const api = grid.current.getApi()
            api.changeView('dayGridWeek')
            setMode('dayGridWeek')
        }

        listPopupClose()
    }, [listPopupClose])

    return (
        <Container breakpoint={theme.breakpoints.values.md}>
            {!mobile && props.modeChangeControl && <Header modeChange={modeChange} view={mode} />}
            <Grid
                ref={grid}
                moreLinkClick={moreLinkClick}
                defaultView={mobile ? 'listMonth' : props.defaultView}
                locale={props.locale}
                eventDrop={props.eventDrop}
                dateClick={props.dateClick}
                eventClick={eventClick}
                events={props.events}
                editable={props.editable}
                listEmptyContent={props.listEmptyContent}
            />
            {event.current &&
                <EventPopup
                    open={!!openEventPopup}
                    anchorEl={openEventPopup}
                    onClose={eventPopupClose}
                    id={event.current.id}
                    start={event.current.start}
                    eventPopupContent={props.eventPopupContent}
                    parentListClose={listPopupClose}
                />
            }
            {moreLink.current &&
                <ListPopup
                    open={openListPopup}
                    anchorEl={moreLink.current.jsEvent.target as HTMLElement}
                    onClose={listPopupClose}
                    date={moreLink.current.date}
                    events={moreLink.current.allSegs}
                    listPopupEventClick={listPopupEventClick}
                    modeChangeToWeek={modeChangeToWeek}
                />
            }
        </Container>
    )
})

export default React.memo(Calendar)