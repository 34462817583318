import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'trainee_name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'trainee_photo_fileId',
        title: 'Photo',
        width: 200,
        type: types.Image
    },
    {
        name: 'trainee_phone',
        title: 'Phone',
        width: 200,
        type: types.Phone,
        color: ''
    },
    {
        name: 'trainee_email',
        title: 'Email',
        width: 200,
        type: types.Text,
        color: ''
    },
    {
        name: 'trainee_company_name',
        title: 'Company',
        width: 200,
        type: types.Chip
    },
    {
        name: 'trainee_role',
        title: 'Current role',
        width: 200,
        type: types.Text
    },
    {
        name: 'diagnostics_name',
        title: 'Initial diagnostics',
        width: 200,
        type: types.File,
        link: 'diagnostics_link'
    },
    {
        name: 'presentationVideo',
        title: 'Presentation video',
        width: 200,
        type: types.Link,
        link: 'presentationVideo'
    },
    {
        name: 'finalVideo',
        title: 'Final video',
        width: 200,
        type: types.Link,
        link: 'finalVideo'
    },
    {
        name: 'report_name',
        title: 'Report',
        width: 200,
        type: types.File,
        link: 'report_link'
    },
    {
        name: 'diploma_name',
        title: 'Diploma',
        width: 200,
        type: types.File,
        link: 'diploma_link'
    },
    {
        name: 'invoiceRules',
        title: 'Invoice rules',
        width: 500,
        type: types.AcademiaInvoice,
        days: 'daysAfter'
    },
    {
        name: 'docs',
        title: 'Confirmation documents',
        width: 400,
        type: types.File,
        itemId: 'fileId',
        itemLabel: 'file_name',
        itemValue: 'file_link'
    },
]

export const createFormConfig = [
    {
        title: 'Trainee',
        type: types.Chip,
        name: 'traineeId',
        required: false,
        autocomplete: true,
        helper: 'Select an existing trainee or fill in the fields below to create a new participant'
    },
    {
        title: 'Name',
        type: types.Text,
        name: 'trainee_name',
        autocompleteDependent: true,
        required: true
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'trainee_photo_name',
        autocompleteDependent: true,
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'trainee_phone',
        autocompleteDependent: true,
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'trainee_email',
        autocompleteDependent: true,
        required: true
    },
    {
        title: 'Company',
        type: types.Chip,
        name: 'trainee_clientId',
        autocompleteDependent: true,
        required: false,
        adding: true
    },
    {
        title: 'Current role',
        type: types.Text,
        name: 'trainee_role',
        autocompleteDependent: true,
        required: false
    }
]

export const updateFormConfig = [
    {
        title: 'Name',
        type: types.Text,
        name: 'trainee_name',
        required: true
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'trainee_photo_name',
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'trainee_phone',
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'trainee_email',
        required: true
    },
    {
        title: 'Company',
        type: types.Chip,
        name: 'trainee_clientId',
        required: false,
        adding: true
    },
    {
        title: 'Current role',
        type: types.Text,
        name: 'trainee_role',
        required: false
    },
    {
        name: 'diagnostics_name',
        title: 'Initial diagnostics',
        type: types.File,
        required: false
    },
    {
        name: 'presentationVideo',
        title: 'Presentation video',
        type: types.Text,
        required: false
    },
    {
        title: 'Final video',
        type: types.Text,
        name: 'finalVideo',
        required: false
    },
    {
        title: 'Report',
        type: types.File,
        name: 'report_name',
        required: false
    },
    {
        title: 'Diploma',
        type: types.File,
        name: 'diploma_name',
        required: false
    },
    {
        title: 'Invoice rules',
        type: types.AcademiaInvoice,
        name: 'invoiceRules',
        required: false
    }
]