import React, {useMemo} from 'react'
import styled from '@emotion/styled'

interface IComponent {
    value: string | string[]
    color: string
    type: 'project' | 'academia'
    status?: string
    days?: string
}

const Container = styled.div<{color: string}>`
    margin: 0 10px;
    color: ${props => props.color};
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    border-bottom: 1px solid;
    filter: brightness(90%);
    line-height: 20px;
`

const Rule = (props: IComponent) => {
    const value = useMemo(() => {
        if (props.type === 'project') {
            return props.status + ' - ' + props.value
        } else {
            return Array.isArray(props.value) ? props.value.length ? props.value.map((rule: any) =>
                (rule.daysAfter ? rule.daysAfter + ' days after - ' : 'Enroll - ') + rule.percent) : 'Enroll - 100' :
                (props.days ? props.days + ' days after - ' : 'Enroll - ') + props.value
        }
    }, [props.type, props.status, props.value, props.days])

    return (
        <React.Fragment>
            {!!Array.isArray(value) ?
                value.map((item, index) => <Container key={index} color={props.color}>{item}</Container>) :
                <Container color={props.color}>
                    {value}
                </Container>
            }
        </React.Fragment>
    )
}

export default React.memo(Rule)
