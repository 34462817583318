import {types} from 'enums/fields'

export const mainSheet = [
    {
        name: 'area_name',
        title: 'Area',
        width: 300,
        type: types.Chip,
        color: 'area_color'
    },
    {
        name: 'session_starts',
        title: 'Date',
        width: 200,
        type: types.DateTime
    },
    {
        name: 'session_hours',
        title: 'Hours',
        width: 200,
        type: types.Number
    },
    {
        name: 'trainer_name',
        title: 'Trainer',
        width: 200,
        type: types.Chip
    },
    {
        name: 'session_type_name',
        title: 'Type',
        width: 200,
        type: types.Chip,
        color: 'session_type_color'
    },
    {
        name: 'session_location',
        title: 'Location',
        width: 300,
        type: types.Text
    },
    {
        name: 'session_moment',
        title: 'Moment',
        width: 200,
        type: types.Text
    }
]

export const mainForm = [
    {
        title: 'Area',
        type: types.Chip,
        name: 'areaId',
        required: true
    },
    {
        title: 'Date',
        type: types.DateTime,
        name: 'session_starts',
        required: false
    },
    {
        title: 'Hours',
        type: types.Number,
        name: 'session_hours',
        required: false
    },
    {
        title: 'Trainer',
        type: types.Chip,
        name: 'trainerId',
        optionsDependency: 'areaId',
        required: false
    },
    {
        title: 'Type',
        type: types.Chip,
        name: 'session_sessionTypeId',
        required: false
    },
    {
        title: 'Location',
        type: types.Text,
        name: 'session_location',
        required: false
    },
    {
        title: 'Moment',
        type: types.Text,
        name: 'session_moment',
        required: false
    }
]

export const mainFilter = [
    {
        title: 'Filter by Area',
        name: 'areaId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainer',
        name: 'trainerId',
        type: types.Chip
    }
]

export const corporateSheet = [
    {
        name: 'projectArea_area_name',
        title: 'Area',
        width: 300,
        type: types.Chip,
        color: 'projectArea_area_color'
    },
    {
        name: 'starts',
        title: 'Date',
        width: 200,
        type: types.DateTime
    },
    {
        name: 'hours',
        title: 'Hours',
        width: 200,
        type: types.Number
    },
    {
        name: 'projectArea_trainer_name',
        title: 'Trainer',
        width: 200,
        type: types.Chip,
        color: ''
    },
    {
        name: 'trainee_name',
        title: 'Trainee',
        width: 200,
        type: types.Chip,
        color: ''
    },
    {
        name: 'status_name',
        title: 'Status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    }
]

export const corporateForm = [
    {
        title: 'Area',
        type: types.Chip,
        name: 'projectAreaId',
        required: true
    },
    {
        title: 'Date',
        type: types.DateTime,
        name: 'starts',
        required: true
    },
    {
        title: 'Hours',
        type: types.Number,
        name: 'hours',
        required: false
    },
    {
        title: 'Trainee',
        type: types.Chip,
        name: 'traineeId',
        required: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: false
    }
]

export const corporateFilter = [
    {
        title: 'Filter by Area',
        name: 'projectAreaId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainer',
        name: 'projectArea_trainerId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainee',
        name: 'traineeId',
        type: types.Chip
    },
    {
        title: 'Filter by Status',
        name: 'statusId',
        type: types.Chip
    }
]