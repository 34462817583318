import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getAcademiaTasks, createAcademiaTasks, deleteAcademiaTasks, updateAcademiaTasks} from 'slices/academias'
import {getEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import FilterForm from 'components/FilterForm'
import Bottom from 'components/SheetBottom'
import {formConfig, filterConfig} from 'enums/academias/tasks'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {IContext} from 'containers/Employee/Academia/interfaces'
import {Entity, Sheet} from 'api/enums'

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string
}

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

const defaultSheetFields: any = []

const Tasks = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [taskId, setTaskId] = useState<number | null>(null)
    const [filters, setFilters] = useState<{statusId: number | null, teamMemberId: number | null}>({statusId: null, teamMemberId: null})

    const editFormTitle = useRef<string>('')

    const tasks = useAppSelector(state => state.academias.tasks)
    const taskStatuses = useAppSelector(state => state.entities[Entity.taskStatus])
    const suggestions = useAppSelector(state => state.entities[Entity.defaultTask])
    const members = useAppSelector(state => state.entities[Entity.teamMember])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.academiaTasks])
    const user = useAppSelector(state => state.auth.user)

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const context: IContext = useOutletContext()

    useEffect(() => {
        if (academiaId) {
            dispatch(getAcademiaTasks({id: Number(academiaId)}))
            dispatch(getEntities({entity: Entity.teamMember}))
            dispatch(getEntities({entity: Entity.taskStatus}))
            dispatch(getEntities({entity: Entity.defaultTask}))
            dispatch(getSheet({name: Sheet.academiaTasks}))
        }
    }, [dispatch, academiaId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleFilterClose = useCallback(() => {
        context.handleFilterClose()
    }, [context])

    const handleFilterConfirm = useCallback((values: any) => {
        const state = {
            ...filters,
            ...values
        }

        const appliedFilters = !!Object.values(state).find(item => !!item)

        setFilters(state)

        context.handleFilterClose({appliedFilters: appliedFilters})
    }, [filters, context])

    const getFilterOptions = useCallback((name: string) => {
        if (name === 'statusId') {
            return taskStatuses
        }

        if (name === 'teamMemberId') {
            return members
        }

        return []
    }, [taskStatuses, members])

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setTaskId(documentId)
            editFormTitle.current = 'Change record'
        } else {
            editFormTitle.current = 'Create record'
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setTaskId(null)
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(academiaId)

        if (taskId) {
            obj.id = taskId
            dispatch(updateAcademiaTasks({data: [{...obj}]}))
        } else {
            dispatch(createAcademiaTasks({data: [{...obj}]}))
        }

        handleClose()
    }

    const handleDelete = () => {
        dispatch(deleteAcademiaTasks({ids: checked}))
        setCheck([])
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'teamMemberId') {
            return members
        }
        
        if (name === 'statusId') {
            return taskStatuses
        }

        if (name === 'name') {
            return suggestions.reduce((data: string[], item) => {
                if (!data.includes(item.name)) {
                    data.push(item.name)
                }

                return data
            }, [])
        }

        return []
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaTasks,
                data: [{
                    name: Sheet.academiaTasks,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaTasks,
                data: [{
                    name: Sheet.academiaTasks,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const initialValues = useMemo(() => {
        const task = tasks.find(item => item.id === taskId)

        return {
            name: task ? task.name : '',
            deadline: task ? task.deadline : null,
            statusId: task ? task.statusId : null,
            teamMemberId: task ? task.teamMemberId : null,
            comment: task ? task.comment : ''
        }
    }, [tasks, taskId])

    const currentTasks = useMemo(() => filters.statusId || filters.teamMemberId ? tasks.filter(task => {
        const statusFilter = filters.statusId ? task.statusId === filters.statusId : true
        const memberFilter = filters.teamMemberId ? task.teamMemberId === filters.teamMemberId : true
    
        return statusFilter && memberFilter
    }) : tasks, [tasks, filters])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Container margin={BottomBarHeight + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={currentTasks}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom height={BottomBarHeight} checked={!!checked.length} handleDelete={handleDelete} handleCreate={handleOpen} />
            <EditForm
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={editFormTitle.current}
                config={formConfig}
                getOptions={getOptions}
                initialValues={initialValues}
            />
            <FilterForm
                open={context.openFilter}
                handleClose={handleFilterClose}
                handleConfirm={handleFilterConfirm}
                values={filters}
                getOptions={getFilterOptions}
                config={filterConfig}
            />
        </React.Fragment>
    )
}

export default Tasks