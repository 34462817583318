import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'src',
        title: 'Sender',
        width: 200,
        type: types.Text
    },
    {
        name: 'body',
        title: 'Template',
        width: 400,
        type: types.Text
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Sender',
        type: types.Text,
        name: 'src',
        required: true
    },
    {
        title: 'Template',
        type: types.Mention,
        name: 'body',
        required: true
    }
]

export const entities = []