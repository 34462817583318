import React, {useEffect, useState, useMemo} from 'react'
import {Link, Navigate, useLocation, useParams} from 'react-router-dom'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Fab from '@mui/material/Fab'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import School from '@mui/icons-material/School'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Grading from '@mui/icons-material/Grading'
import Event from '@mui/icons-material/Event'
import AttachFile from '@mui/icons-material/AttachFile'
import Verified from '@mui/icons-material/Verified'
import Subject from '@mui/icons-material/Subject'
import Create from '@mui/icons-material/Create'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import Card from 'components/Card'
import EditForm from 'components/EditForm'
import Menu from 'components/Menu'
import {getAcademias, updateFiles, updateInformation} from 'slices/trainee'
import routes from 'enums/routes'
import {types} from 'enums/fields'
import {DocumentActions} from 'enums/utils'
import {Entity, View} from 'api/enums'

const button = {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 1
}

const icon = {
    fontSize: 18
}

const course = {
    id: 'id',
    title: 'project_name',
    fields: [
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'project_starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'project_ends'
        },
        {
            label: 'Initial session',
            icon: <CalendarMonth sx={icon} />,
            type: types.DateTime,
            value: 'project_kickoffDate',
        },
        {
            label: 'Initial session link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'project_kickoffLink'
        },
        {
            label: 'Final session',
            icon: <CalendarMonth sx={icon} />,
            type: types.DateTime,
            value: 'project_finalSessionDate',
        },
        {
            label: 'Final session link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'project_finalSessionLink'
        },
        {
            label: 'Survey link',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'project_surveyLink'
        },
        {
            label: 'Final video',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'finalVideo'
        },
        {
            label: 'Diploma',
            icon: <Verified sx={icon}/>,
            type: types.File,
            value: 'diploma_name',
            props: {
                id: 'diplomaId',
                text: 'diploma_name',
                value: 'diploma_link'
            }
        },
        {
            label: 'Report',
            icon: <Subject sx={icon}/>,
            type: types.File,
            value: 'report_name',
            props: {
                id: 'reportId',
                text: 'report_name',
                value: 'report_link'
            }
        },
        {
            label: 'Documents to sign',
            icon: <Create sx={icon}/>,
            type: types.File,
            value: 'project_docs',
            props: {
                id: 'fileId',
                text: 'file_name',
                value: 'file_link'
            }
        }
    ]
}

const person = {
    id: 'id',
    title: '',
    fields: [
        {
            label: 'Presentation video',
            icon: <AttachFile sx={icon}/>,
            type: types.Link,
            value: 'presentationVideo',
            editable: true
        },
        {
            label: 'Signed documents',
            icon: <Grading sx={icon}/>,
            type: types.File,
            value: 'docs',
            editable: true,
            props: {
                id: 'fileId',
                text: 'file_name',
                value: 'file_link'
            }
        }
    ]
}

const form = [
    {
        title: 'Presentation video',
        type: types.Text,
        name: 'presentationVideo',
        required: false
    }
]

const Courses = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const academias = useAppSelector(state => state.trainee.academias)

    const dispatch = useAppDispatch()
    const {pathname} = useLocation()

    const {academiaId} = useParams()

    useEffect(() => {
        dispatch(getAcademias())
    }, [dispatch])

    const handleFormOpen = () => {
        setOpenForm(open => !open)
    }

    const onChange = (name: string, value: File[] | number) => {
        if (name === 'presentationVideo') {
            setOpenForm(true)
        } else {
            if (academia) {
                if (typeof value === 'number') {
                    const form = new FormData()
    
                    const data = [
                        {
                            id: academia.id,
                            docs: [value]
                        }
                    ]
    
                    form.append('entity', Entity.projectTrainee)
                    form.append('view', View.traineeProjects)
                    form.append('data', JSON.stringify(data))
    
                    dispatch(updateFiles({data: form, type: DocumentActions.detach}))
                } else {
                    const form = new FormData()
    
                    const data = [
                        {
                            id: academia.id,
                            docs: [...value].map((item: File, index: number) => {
                                const id = `$file=${index + 1}`
                                form.append(id, item)
    
                                return id
                            })
                        }
                    ]
    
                    form.append('entity', Entity.projectTrainee)
                    form.append('view', View.traineeProjects)
                    form.append('data', JSON.stringify(data))
    
                    dispatch(updateFiles({data: form, type: DocumentActions.attach}))
                }
            }
        }
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}

        if (academia) {
            obj.id = academia.id

            dispatch(updateInformation({
                entity: Entity.projectTrainee,
                view: View.traineeProjects,
                data: [{...obj}]
            }))
        }

        handleFormOpen()
    }

    const academia = useMemo(() => {
        return academias.find(item => item.project_id === Number(academiaId))
    }, [academiaId, academias])

    const initialValues = useMemo(() => {
        return {
            presentationVideo: academia ? academia.presentationVideo : ''
        }
    }, [academia])
    
    return (    
        <React.Fragment> 
            {!academia && !!academias.length ?
                <Navigate to={`/${routes.Courses}/${academias[0].projectId}`} replace={true} /> :
                <Box sx={{display: 'flex', flexGrow: 1, minWidth: 0}}>
                    <Menu>
                        <List>
                            {academias.map((item) => {
                                const to = `/${routes.Courses}/${item.project_id}`

                                return (
                                    <ListItem key={item.project_id} disablePadding>
                                        <ListItemButton component={Link} to={to} selected={to === pathname}>
                                            <ListItemIcon>
                                                <School />
                                            </ListItemIcon>
                                            <ListItemText primary={item.project_name} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Menu>
                    <Box sx={{padding: 2, flexGrow: 1, minWidth: 0}}>
                        {academia &&
                            <div>  
                                <div style={{marginBottom: '20px', position: 'relative'}}>
                                    <Card data={academia} presentation config={course} />
                                    <Fab color="secondary" size='small' component={Link} to={`/${routes.Courses}/${academia.project_id}/${routes.Calendar}`} sx={button}>
                                        <Event fontSize='small' />
                                    </Fab>
                                </div>
                                <div style={{marginBottom: '20px', position: 'relative'}}>
                                    <Card data={academia} presentation config={person} onChange={onChange} />
                                </div>
                                <EditForm
                                    open={openForm}
                                    handleClose={handleFormOpen}
                                    handleConfirm={handleConfirm}
                                    title={'Update information'}
                                    config={form}
                                    initialValues={initialValues}
                                />
                            </div>
                        }
                    </Box>
                </Box>
            }
         </React.Fragment>
    )
}

export default Courses