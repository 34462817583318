import React, {useContext} from 'react'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import {Context} from 'containers/Auth'

interface IComponent {
    children: JSX.Element
    width?: number
}

const Menu = (props: IComponent) => {
    const context = useContext(Context)

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: props.width || 250,
                    display: {xs: 'none', md: 'block'}
                }}
                PaperProps={{
                    sx: {
                        width: props.width || 250
                    }
                }}
                variant='permanent'
                anchor='left'
            >
                <Toolbar />
                {props.children}
            </Drawer>
            <Drawer
                variant="temporary"
                open={context.openSidebar}
                onClose={context.handleOpenSidebar}
                ModalProps={{
                    keepMounted: true
                }}
                sx={{
                    width: props.width || 250,
                    display: {xs: 'block', md: 'none'}
                }}
                PaperProps={{
                    sx: {
                        width: props.width || 250
                    }
                }}
            >
                <Toolbar />
                {props.children}
            </Drawer>
        </React.Fragment>
    )
}

export default Menu