import {useMemo} from 'react'
import Sheet from 'components/Sheet'
import {filter, entities} from 'enums/tasks'
import {Entity, Sheet as Sheets} from 'api/enums'
import {RoleIdTeam} from 'enums/utils'
import {useAppSelector} from 'app/hooks'

const Tasks = () => {
    const user = useAppSelector(state => state.auth.user)

    const filterFields = useMemo(() => {
        if (user && user.roleId === RoleIdTeam) {
            const index = filter.findIndex(item => item.name === 'teamMemberId')
            const data = [...filter]
            data.splice(index, 1, {...filter[index], default: user.data_teamMemberId})
            return data
        }

        return filter
    }, [user])
    
    return (
        <Sheet
            data={Entity.projectTask}
            primaryId={'id'}
            filterFields={filterFields as any}
            entities={entities as any}
            sheet={Sheets.tasks}
        />
    )
}

export default Tasks