import {types} from 'enums/fields'

export const createForm = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Sales close date',
        type: types.Date,
        name: 'salesCloseDate',
        required: false
    },
    {
        title: 'Start date',
        type: types.Date,
        name: 'starts',
        required: true,
        max: 'ends'
    },
    {
        title: 'End date',
        type: types.Date,
        name: 'ends',
        required: true,
        min: 'starts'
    },
    {
        title: 'Price',
        type: types.Number,
        name: 'pricePerTrainee',
        required: false
    },
    {
        title: 'Initial session',
        type: types.DateTime,
        name: 'kickoffDate',
        required: false
    },
    {
        title: 'Initial session link',
        type: types.Text,
        name: 'kickoffLink',
        required: false
    },
    {
        title: 'Final session',
        type: types.DateTime,
        name: 'finalSessionDate',
        required: false
    },
    {
        title: 'Final session link',
        type: types.Text,
        name: 'finalSessionLink',
        required: false
    },
    {
        title: 'Survey link',
        type: types.Text,
        name: 'surveyLink',
        required: false
    },
    {
        title: 'Team members',
        type: types.Chips,
        name: 'teamMembers',
        required: false
    }
]

export const updateForm = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Sales close date',
        type: types.Date,
        name: 'salesCloseDate',
        required: false
    },
    {
        title: 'Start date',
        type: types.Date,
        name: 'starts',
        required: true,
        max: 'ends'
    },
    {
        title: 'End date',
        type: types.Date,
        name: 'ends',
        required: true,
        min: 'starts'
    },
    {
        title: 'Price',
        type: types.Number,
        name: 'pricePerTrainee',
        required: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: true
    },
    {
        title: 'Initial session',
        type: types.DateTime,
        name: 'kickoffDate',
        required: false
    },
    {
        title: 'Initial session link',
        type: types.Text,
        name: 'kickoffLink',
        required: false
    },
    {
        title: 'Final session',
        type: types.DateTime,
        name: 'finalSessionDate',
        required: false
    },
    {
        title: 'Final session link',
        type: types.Text,
        name: 'finalSessionLink',
        required: false
    },
    {
        title: 'Survey link',
        type: types.Text,
        name: 'surveyLink',
        required: false
    },
    {
        title: 'Team members',
        type: types.Chips,
        name: 'teamMembers',
        required: false
    }
]