import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import {IReqId, IReqData, IReqEntityData, IProjectSession, IProjectSessionCreate, IAcademiaSession, IReqIds, IProject,
    IProjectTrainee, IProjectTraineeCreate, IAcademiaArea, IProjectTask, IAcademiaSessionCreate, IProjectExpenseExtras,
    IProjectExpenseExtrasCreate} from 'api/interfaces'
import api from 'utils/api'
import {AcademiaCorporate} from 'enums/utils'

type ExpenseTrainer = {
    hours: number | null
    projectExpenseTrainer_price: number | null
    projectArea_trainer_hourlyPrice: number | null
    id: number
    projectArea_trainer_name: string
    [key: string]: any
}

type ExpenseExtra = {
    id: number,
    name: string
    price: number
    units: number
    projectId: number
}

type Appointment = {
    id: number,
    area_name: string,
    projectId: number
    areaId: number,
    trainer_name: string,
    [key: string]: any
}

type Task = {
    id: number
    name: string
    deadline: string | null
    projectId: number
    statusId: number
    teamMemberId: number | null,
    [key: string]: any
}

type Participants = {
    id: number,
    projectId: number,
    traineeId: number | null,
    trainee_id: number | null,
    presentationVideo: string,
    finalReport: string,
    trainee_name: string,
    trainee_photo: string,
    trainee_phone: string,
    trainee_email: string,
    trainee_role: string,
    trainee_company_name: string,
    trainee_clientId: number | null,
    [key: string]: any
}

type Rule = {
    id: number,
    value: string,
    status_id: number,
    status_name: string,
    status_color: string,
    [key: string]: any
}

type Project = {
    id: number
    name: string
    starts: string
    ends: string
    participants: string
    context: string
    typeId: number
    statusId: number
    clientId: number | null
    contactId: number | null
    teamMembers: Member[]
    invoiceRules: Rule[]
    commercialOwnerId: number | null,
    commissionValue: string,
    [key: string]: any
}

type Session = {
    id: number,
    [key: string]: any
}

type Type = {
    color: string,
    id: number,
    name: string,
    protected: boolean,
    [key: string]: any
}

type Location = {
    id: number,
    name: string
}

type Member = {
    teamMemberId: number,
    member_name: string,
    member_color: string
}

interface IState {
    info: Project | null
    types: Type[]
    locations: Location[]
    projects: Project[]
    sessions: Session[]
    participants: Participants[]
    tasks: Task[]
    appointments: Appointment[]
    expenses: ExpenseTrainer[]
    extras: ExpenseExtra[]
    сommissions: Project[]
    requests: {[key: string]: boolean}
    pending: boolean
    notExist: boolean
}

const initialState: IState = {
    info: null,
    types: [],
    locations: [],
    projects: [],
    sessions: [],
    participants: [],
    tasks: [],
    appointments: [],
    expenses: [],
    extras: [],
    сommissions: [],
    requests: {},
    pending: false,
    notExist: false
}

export const getProjects = createAsyncThunk('project/getProjects', async () => {
    const response = await api(ApiRoute.getprojects)
    return response.data
}, {condition: (data, {getState}) => {
        const {projects} = getState() as {projects: IState}
        const pending = projects.requests.projects

        if (pending) {
            return false
        }
    }
})

export const createProjects = createAsyncThunk('project/createProjects', async (data: IReqData<IProject>) => {
    const response = await api(ApiRoute.createprojects, data)
    return response.data
})

export const updateProjects = createAsyncThunk('project/updateProjects', async (data: IReqData<IProject>, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.updateprojects, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const deleteProjects = createAsyncThunk('project/deleteProjects', async (data: IReqIds) => {
    const response = await api(ApiRoute.deleteprojects, data)
    return response.data
})

export const getProject = createAsyncThunk('project/getProject', async (data: IReqId) => {
    const response = await api(ApiRoute.getproject, data)
    return response.data
})

export const getProjectSessions = createAsyncThunk('project/getProjectSessions', async (data: {id: number, type: number}) => {
    const response = data.type === AcademiaCorporate ?
        await api(ApiRoute.getacademiasessions, {id: data.id}) : await api(ApiRoute.getprojectsessions, {id: data.id})
    return response.data
})

export const createProjectSessions = createAsyncThunk('project/createProjectSessions', async (data: {data: IAcademiaSessionCreate[] | IProjectSessionCreate[], type: number}, {rejectWithValue}) => {
    try {
        const response = data.type === AcademiaCorporate ?
            await api(ApiRoute.createacademiasessions, {data: data.data}) :
            await api(ApiRoute.createprojectsessions, {data: data.data})

        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const updateProjectSessions = createAsyncThunk('project/updateProjectSessions', async (data: {data: IAcademiaSession[] | IProjectSession[], type: number}, {rejectWithValue}) => {
    try {
        const response = data.type === AcademiaCorporate ?
            await api(ApiRoute.updateacademiasessions, {data: data.data}) :
            await api(ApiRoute.updateprojectsessions, {data: data.data})

        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const deleteProjectSessions = createAsyncThunk('project/deleteProjectSessions', async (data: {ids: number[], type: number}) => {
    const response = data.type === AcademiaCorporate ?
        await api(ApiRoute.deleteacademiasessions, {ids: data.ids}) : await api(ApiRoute.deleteprojectsessions, {ids: data.ids})
    return response.data
})

export const getParticipants = createAsyncThunk('project/getParticipants', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojecttrainees, data)
    return response.data
})

export const createParticipants = createAsyncThunk('project/createParticipants', async (data: IReqData<IProjectTraineeCreate>) => {
    const response = await api(ApiRoute.createprojecttrainees, data)
    return response.data
})

export const createParticipantsImage = createAsyncThunk('project/createParticipantsImage', async (data: FormData) => {
    const response = await api(ApiRoute.createwfiles, data)
    return response.data
})

export const updateParticipants = createAsyncThunk('project/updateParticipants', async (data: IReqData<IProjectTrainee>) => {
    const response = await api(ApiRoute.updateprojecttrainees, data)
    return response.data
})

export const updateParticipantsImage = createAsyncThunk('project/updateParticipantsImage', async (data: FormData) => {
    const response = await api(ApiRoute.updatewfiles, data)
    return response.data
})

export const deleteParticipants = createAsyncThunk('project/deleteParticipants', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteprojecttrainees, ids)
    return response.data
})

export const getTasks = createAsyncThunk('project/getTasks', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojecttasks, data)
    return response.data
})

export const createTasks = createAsyncThunk('project/createTasks', async (data: IReqData<IProjectTask>) => {
    const response = await api(ApiRoute.createprojecttasks, data)
    return response.data
})

export const updateTasks = createAsyncThunk('project/updateTasks', async (data: IReqData<IProjectTask>) => {
    const response = await api(ApiRoute.updateprojecttasks, data)
    return response.data
})

export const deleteTasks = createAsyncThunk('project/deleteTasks', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteprojecttasks, ids)
    return response.data
})

export const getProjectAppointments = createAsyncThunk('project/getProjectAppointments', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademiaareas, data)
    return response.data
})

export const createProjectAppointments = createAsyncThunk('project/createProjectAppointments', async (data: IReqData<IAcademiaArea>) => {
    const response = await api(ApiRoute.createacademiaareas, data)
    return response.data
})

export const updateProjectAppointments = createAsyncThunk('project/updateProjectAppointments', async (data: IReqData<IAcademiaArea>) => {
    const response = await api(ApiRoute.updateacademiaareas, data)
    return response.data
})

export const deleteProjectAppointments = createAsyncThunk('project/deleteProjectAppointments', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteacademiaareas, ids)
    return response.data
})

export const getExpenseTrainers = createAsyncThunk('project/getTrainerExpenses', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojectexpensetrainers, data)
    return response.data
})

export const updateExpenseTrainers = createAsyncThunk('project/updateExpenseTrainers', async (data: IReqEntityData<any>) => {
    const response = await api(ApiRoute.update, data)
    return response.data
})

export const getExpenseExtras = createAsyncThunk('project/getExpenseExtras', async (data: IReqId) => {
    const response = await api(ApiRoute.getprojectexpenseextras, data)
    return response.data
})

export const createExpenseExtras = createAsyncThunk('project/createExpenseExtras', async (data: IReqData<IProjectExpenseExtrasCreate>) => {
    const response = await api(ApiRoute.createprojectexpenseextras, data)
    return response.data
})

export const updateExpenseExtras = createAsyncThunk('project/updateExpenseExtras', async (data: IReqData<IProjectExpenseExtras>) => {
    const response = await api(ApiRoute.updateprojectexpenseextras, data)
    return response.data
})

export const deleteExpenseExtras = createAsyncThunk('project/deleteExpenseExtras', async (ids: IReqIds) => {
    const response = await api(ApiRoute.deleteprojectexpenseextras, ids)
    return response.data
})

export const updateProjectFiles = createAsyncThunk('project/updateProjectFiles', async (data: {data: FormData, type: string}) => {
    const response = data.type === 'attach' ? await api(ApiRoute.attachwfiles, data.data) :
        await api(ApiRoute.detachwfiles, data.data)
    return response.data
})

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        resetProject: state => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state, action) => {
                state.pending = true
                state.requests.projects = true
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.projects = action.payload
                state.pending = false
                state.requests.projects = false
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.pending = false
                state.requests.projects = false
            })

            .addCase(getProject.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getProject.fulfilled, (state, action) => {
                state.info = action.payload
                state.notExist = !action.payload
                state.pending = false
            })
            .addCase(getProject.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getProjectSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getProjectSessions.fulfilled, (state, action) => {
                state.sessions = action.payload
                state.pending = false
            })
            .addCase(getProjectSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createProjectSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createProjectSessions.fulfilled, (state, action) => {
                const sessions = state.sessions
                sessions.push(...action.payload)

                state.sessions = sessions
                state.pending = false
            })
            .addCase(createProjectSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteProjectSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteProjectSessions.fulfilled, (state, action) => {
                const sessions = state.sessions.filter(session => session.id && !action.meta.arg.ids.includes(session.id))
                state.sessions = sessions
                state.pending = false
            })
            .addCase(deleteProjectSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateProjectSessions.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateProjectSessions.fulfilled, (state, action) => {
                const sessions = state.sessions

                action.payload.forEach((item: IProjectSession) => {
                    const index = sessions.findIndex(session => session.id === item.id)
                    sessions[index] = item
                })

                state.sessions = sessions
                state.pending = false
            })
            .addCase(updateProjectSessions.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createProjects.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createProjects.fulfilled, (state, action) => {
                const projects = state.projects
                projects.push(...action.payload)

                state.projects = projects
                state.pending = false
            })
            .addCase(createProjects.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteProjects.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteProjects.fulfilled, (state, action) => {
                state.info = null
                state.notExist = true
                state.pending = false
            })
            .addCase(deleteProjects.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateProjects.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateProjects.fulfilled, (state, action) => {
                const projects = state.projects

                action.payload.forEach((item: Project) => {
                    const index = projects.findIndex(project => project.id === item.id)
                    projects[index] = item
                })

                state.projects = projects
                state.info = action.payload[0]
                state.pending = false
            })
            .addCase(updateProjects.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getParticipants.fulfilled, (state, action) => {
                state.participants = action.payload
                state.pending = false
            })
            .addCase(getParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createParticipants.fulfilled, (state, action) => {
                const participants = state.participants
                participants.push(...action.payload)

                state.participants = participants
                state.pending = false
            })
            .addCase(createParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createParticipantsImage.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createParticipantsImage.fulfilled, (state, action) => {
                const participants = state.participants
                participants.push(...action.payload)

                state.participants = participants
                state.pending = false
            })
            .addCase(createParticipantsImage.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteParticipants.fulfilled, (state, action) => {
                const participants = state.participants.filter(participant => participant.id && !action.meta.arg.ids.includes(participant.id))
                state.participants = participants
                state.pending = false
            })
            .addCase(deleteParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateParticipants.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateParticipants.fulfilled, (state, action) => {
                const participants = state.participants

                action.payload.forEach((item: Participants) => {
                    const index = participants.findIndex(participant => participant.id === item.id)
                    participants[index] = item
                })

                state.participants = participants
                state.pending = false
            })
            .addCase(updateParticipants.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateParticipantsImage.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateParticipantsImage.fulfilled, (state, action) => {
                const participants = state.participants

                action.payload.forEach((item: Participants) => {
                    const index = participants.findIndex(participant => participant.id === item.id)
                    participants[index] = item
                })

                state.participants = participants
                state.pending = false
            })
            .addCase(updateParticipantsImage.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTasks.fulfilled, (state, action) => {
                state.tasks = action.payload
                state.pending = false
            })
            .addCase(getTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createTasks.fulfilled, (state, action) => {
                const tasks = state.tasks
                tasks.push(...action.payload)

                state.tasks = tasks
                state.pending = false
            })
            .addCase(createTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteTasks.fulfilled, (state, action) => {
                const tasks = state.tasks.filter(task => task.id && !action.meta.arg.ids.includes(task.id))
                state.tasks = tasks
                state.pending = false
            })
            .addCase(deleteTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateTasks.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateTasks.fulfilled, (state, action) => {
                const tasks = state.tasks

                action.payload.forEach((item: Task) => {
                    const index = tasks.findIndex(task => task.id === item.id)
                    tasks[index] = item
                })

                state.tasks = tasks
                state.pending = false
            })
            .addCase(updateTasks.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getProjectAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getProjectAppointments.fulfilled, (state, action) => {
                state.appointments = action.payload
                state.pending = false
            })
            .addCase(getProjectAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createProjectAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createProjectAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments
                appointments.push(...action.payload)

                state.appointments = appointments
                state.pending = false
            })
            .addCase(createProjectAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteProjectAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteProjectAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments.filter(item => item.id && !action.meta.arg.ids.includes(item.id))
                state.appointments = appointments
                state.pending = false
            })
            .addCase(deleteProjectAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateProjectAppointments.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateProjectAppointments.fulfilled, (state, action) => {
                const appointments = state.appointments

                action.payload.forEach((item: Appointment) => {
                    const index = appointments.findIndex(appointment => appointment.id === item.id)
                    appointments[index] = item
                })

                state.appointments = appointments
                state.pending = false
            })
            .addCase(updateProjectAppointments.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getExpenseTrainers.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getExpenseTrainers.fulfilled, (state, action) => {
                state.expenses = action.payload
                state.pending = false
            })
            .addCase(getExpenseTrainers.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateExpenseTrainers.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateExpenseTrainers.fulfilled, (state, action) => {
                const expenses = state.expenses

                action.payload.forEach((item: ExpenseTrainer) => {
                    const index = expenses.findIndex(expense => expense.id === item.id)
                    expenses[index] = item
                })

                state.expenses = expenses
                state.pending = false
            })
            .addCase(updateExpenseTrainers.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getExpenseExtras.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getExpenseExtras.fulfilled, (state, action) => {
                state.extras = action.payload
                state.pending = false
            })
            .addCase(getExpenseExtras.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(createExpenseExtras.pending, (state, action) => {
                state.pending = true
            })
            .addCase(createExpenseExtras.fulfilled, (state, action) => {
                const extras = state.extras
                extras.push(...action.payload)

                state.extras = extras
                state.pending = false
            })
            .addCase(createExpenseExtras.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateExpenseExtras.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateExpenseExtras.fulfilled, (state, action) => {
                const extras = state.extras

                action.payload.forEach((item: ExpenseExtra) => {
                    const index = extras.findIndex(extra => extra.id === item.id)
                    extras[index] = item
                })

                state.extras = extras
                state.pending = false
            })
            .addCase(updateExpenseExtras.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(deleteExpenseExtras.pending, (state, action) => {
                state.pending = true
            })
            .addCase(deleteExpenseExtras.fulfilled, (state, action) => {
                const extras = state.extras.filter(item => item.id && !action.meta.arg.ids.includes(item.id))
                state.extras = extras
                state.pending = false
            })
            .addCase(deleteExpenseExtras.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateProjectFiles.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateProjectFiles.fulfilled, (state, action) => {
                state.info = action.payload[0]
                state.pending = false
            })
            .addCase(updateProjectFiles.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export const {resetProject} = projectsSlice.actions

export default projectsSlice