import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'trainee_name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'trainee_photo_fileId',
        title: 'Photo',
        width: 200,
        type: types.Image
    },
    {
        name: 'trainee_phone',
        title: 'Phone',
        width: 200,
        type: types.Phone,
        color: ''
    },
    {
        name: 'trainee_email',
        title: 'Email',
        width: 200,
        type: types.Text,
        color: ''
    },
    {
        name: 'trainee_company_name',
        title: 'Company',
        width: 200,
        type: types.Chip
    },
    {
        name: 'trainee_role',
        title: 'Current role',
        width: 200,
        type: types.Text
    },
    {
        name: 'presentationVideo',
        title: 'Presentation video',
        width: 200,
        type: types.Link,
        link: 'presentationVideo'
    }
]

export const createFormConfig = [
    {
        title: 'Trainee',
        type: types.Chip,
        name: 'traineeId',
        required: false,
        autocomplete: true,
        helper: 'Select an existing trainee or fill in the fields below to create a new participant'
    },
    {
        title: 'Name',
        type: types.Text,
        name: 'trainee_name',
        autocompleteDependent: true,
        required: true
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'trainee_photo_name',
        autocompleteDependent: true,
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'trainee_phone',
        autocompleteDependent: true,
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'trainee_email',
        autocompleteDependent: true,
        required: true
    },
    {
        title: 'Company',
        type: types.Chip,
        name: 'trainee_clientId',
        autocompleteDependent: true,
        required: false,
        adding: true
    },
    {
        title: 'Current role',
        type: types.Text,
        name: 'trainee_role',
        autocompleteDependent: true,
        required: false
    }
]

export const updateFormConfig = [
    {
        title: 'Name',
        type: types.Text,
        name: 'trainee_name',
        required: true
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'trainee_photo_name',
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'trainee_phone',
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'trainee_email',
        required: true
    },
    {
        title: 'Company',
        type: types.Chip,
        name: 'trainee_clientId',
        required: false,
        adding: true
    },
    {
        title: 'Current role',
        type: types.Text,
        name: 'trainee_role',
        required: false
    },
    {
        name: 'presentationVideo',
        title: 'Presentation video',
        type: types.Text,
        required: false
    },
]