import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import {useAppDispatch} from 'app/hooks'
import {changePass} from 'slices/auth'
import {Action} from 'api/enums'
import routes from 'enums/routes'

const box = {
    padding: 2,
    flexGrow: 1,
    minWidth: 0
}

const title = {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '0.5px',
    marginBottom: 2
}

const subtitle = {
    marginBottom: 4
}

const card = {
    backgroundColor: 'primary.light',
    padding: 2,
    display: 'flex',
    flexDirection: 'column'
}

const input = {
    marginBottom: 4
}

type Values = {
    current: string
    new: string
    confirmation: string
}

const Account = () => {
    const [values, setValues] = useState<Values>({current: '', new: '', confirmation: ''})

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        dispatch(changePass({
            action: Action.changepassword,
            data: {
                old: values.current.trim(),
                new: values.new.trim()
            }
        }))
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const disabled = !!Object.keys(values).find(item => !values[item as keyof Values]) || values.new !== values.confirmation

    return (
        <Box sx={box}>
            <Box sx={title}>
                <IconButton component={Link} to={routes.Redirect} sx={{marginRight: 2}}>
                    <ChevronLeft />
                </IconButton>
                {intl.formatMessage({id: 'account.Account'})}
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper onSubmit={onSubmit} method='post' component='form' sx={card}>
                        <Box sx={subtitle}>{intl.formatMessage({id: 'account.ChangePassword'})}</Box>
                        <TextField name='current' label={intl.formatMessage({id: 'account.CurrentPassword'})} sx={input} InputLabelProps={{shrink: true}} onChange={onChange} />
                        <TextField name='new' label={intl.formatMessage({id: 'account.NewPassword'})} sx={input} InputLabelProps={{shrink: true}} onChange={onChange} />
                        <TextField name='confirmation' label={intl.formatMessage({id: 'account.PasswordConfirmation'})} sx={input} InputLabelProps={{shrink: true}} onChange={onChange} />
                        <Button variant='contained' disabled={disabled} onClick={onSubmit} type='submit'>{intl.formatMessage({id: 'app.Confirm'})}</Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Account