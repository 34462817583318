import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Autocomplete from 'components/Autocomplete'
import Modal from 'components/Modal'
import Date from 'components/FormInputs/Date'
import {IComponent, Value} from 'components/FilterForm/interfaces'
import {types} from 'enums/fields'

const Container = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: inline-flex;
`

const Form = (props: IComponent) => {
    const [filters, setFilters] = useState<{[key: string]: Value}>(props.values)

    const intl = useIntl()

    const onChange = (name: string, value: any) => {
        setFilters({
            ...filters,
            [name]: value
        })
    }

    const handleConfirm = () => {
        props.handleConfirm(filters)
    }

    const getOptions = (name: string) => {
        if (typeof props.getOptions === 'function') {
            return props.getOptions(name)
        } else {
            return []
        }
    }
    
    return (
        <Modal
            open={props.open}
            title={intl.formatMessage({id: "filter.SelectFilters"})}
            handleClose={props.handleClose}
            handleConfirm={handleConfirm}
        >
            <React.Fragment>
                    {props.config.map(item => {
                        const options = getOptions(item.name)

                        return <Container key={item.name}>
                            {item.type === types.Date && item.start && item.end ?
                                <React.Fragment>
                                    <div style={{marginRight: '20px', flex: 1}}>
                                        <Date
                                            value={filters.start as string}
                                            name={item.start}
                                            onChange={onChange}
                                            title={item.title ? intl.formatMessage({id: "filter.After"}, {name: item.title}) : ''}
                                            datetime={false}
                                            max={filters.end as string}
                                            disabled={false}
                                        />
                                    </div>
                                    <div style={{marginLeft: '20px', flex: 1}}>
                                        <Date
                                            value={filters.end as string}
                                            name={item.end}
                                            onChange={onChange}
                                            title={item.title ? intl.formatMessage({id: "filter.Before"}, {name: item.title}) : ''}
                                            datetime={false}
                                            min={filters.start as string}
                                            disabled={false}
                                        />
                                    </div>
                                </React.Fragment> :
                                <Autocomplete
                                    options={options}
                                    onChange={onChange}
                                    value={filters[item.name]}
                                    title={item.title}
                                    name={item.name}
                                    multiple={item.type === types.Chips ? true : false}
                                />
                            }
                        </Container>
                    })}
            </React.Fragment>
        </Modal>
    )
}

export default React.memo(Form)
