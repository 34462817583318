import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'company_name',
        title: 'Company',
        width: 200,
        type: types.Chip
    },
    {
        name: 'role',
        title: 'Current role',
        width: 200,
        type: types.Text
    },
    {
        name: 'photo_fileId',
        title: 'Photo',
        width: 200,
        type: types.Image
    },
    {
        name: 'email',
        title: 'Email',
        width: 200,
        type: types.Text
    },
    {
        name: 'phone',
        title: 'Phone',
        width: 200,
        type: types.Phone
    },
    {
        name: 'userData_id',
        title: 'Create account',
        width: 200,
        type: types.Custom
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Company',
        type: types.Chip,
        name: 'clientId',
        required: false,
        data: Entity.client
    },
    {
        title: 'Current role',
        type: types.Text,
        name: 'role',
        required: false
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'photo_name',
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'email',
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'phone',
        required: false
    }
]

export const entities = [Entity.client]