import React, {useState} from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Modal from 'components/Modal'
import Fields from './Fields'
import {IComponent, Slot} from './interfaces'

const empty = {
    letterSpacing: '0.5px',
    color: '#708085',
    fontSize: '13px',
    textAlign: 'center',
    padding: 2
}

const EditForm = (props: IComponent) => {
    const [values, setValues] = useState<Slot[]>(props.initialValues.length ? props.initialValues : [])

    const onChange = (id: number, name?: string, value?: string | null) => {
        if (name) {
            const data = values.map(item => {
                return item.id === id ? {
                    ...item,
                    [name]: value
                } : item
            })
    
            setValues(data)
        } else {
            setValues(values.filter(item => item.id !== id))
        }
    }

    const handleCreate = () => {
        const maxId = values.reduce((max, item) => Math.max(max, item.id), 0)

        setValues([
            ...values,
            {
                id: maxId + 1,
                starts: null,
                ends: null
            }
        ])
    }

    const handleConfirm = () => {
        props.handleConfirm(values)
    }

    const handleCopy = () => {
        props.handleCopy(values)
    }

    const handlePaste = () => {
        const maxId = values.reduce((max, item) => Math.max(max, item.id), 0)
        const content = props.contentPaste.map((item, index) => {
            return {
                id: maxId + 1 + index,
                starts: item.starts,
                ends: item.ends
            }
        })

        setValues([
            ...values,
            ...content
        ])
    }

    const disabled = !!values.find(item => !item.starts || !item.ends)

    return (
        <Modal
            open={props.open}
            title={props.title}
            handleClose={props.handleClose}
            handleConfirm={handleConfirm}
            disabled={disabled}
        >
            <React.Fragment>
                {!!values.length ?
                    values.map(item => {
                        return <Fields
                            key={item.id}
                            id={item.id}
                            start={item.starts}
                            end={item.ends}
                            onChange={onChange}
                        />
                    }) :
                    <Box sx={empty}>You do not have timeslots for this day yet</Box>
                }
                <Button onClick={handleCreate} variant='contained' color='secondary' sx={{marginTop: 2}}>Add time range</Button>
                {!!values.length &&
                    <Button onClick={handleCopy} variant='outlined' color='secondary' sx={{marginTop: 2, marginLeft: 2}}>
                        Copy timeslots
                    </Button>
                }
                {!!props.contentPaste.length &&
                    <Button onClick={handlePaste} variant='outlined' color='secondary' sx={{marginTop: 2, marginLeft: 2}}>
                        Paste timeslots
                    </Button>
                }
            </React.Fragment>
      </Modal>
    )
}

export default React.memo(EditForm)
