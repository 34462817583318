import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import PlaylistAdd from '@mui/icons-material/PlaylistAdd'
import ListItem from 'components/ProposalSheet/ListItem'
import Cell from 'components/ProposalSheet/Cell'
import {currencyFormatter} from 'utils/formatter'

const Title = styled.div`
    margin-bottom: 40px;
    letter-spacing: 0.5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 32px;
`

const Header = styled.div`
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
`

const Empty = styled.div`
    display: flex;
    justify-content: center;
    letter-spacing: 0.5px;
    color: #708085;
    font-size: 13px;
    line-height: 32px;
`

const Total = styled.div`
    font-size: 15px;
    letter-spacing: 0.5px;
    color: #43a047;
    line-height: 32px;
    margin-top: 40px;
    font-weight: 500;
`

export type Field = {
    title: string,
    width: number,
    name: string,
    type: string,
    readonly: boolean,
    additionalName?: string
}

export type Item = {
    id: number,
    [key: string]: any
}

const paper = {
    padding: 2
}

const chip = {
    backgroundColor: 'info.light'
}

type Option = {
    id: number,
    name: string
}

interface IComponent {
    title: string,
    fields: Field[],
    data: Item[],
    margin: number,
    total?: number,
    handleCreate?: () => void,
    onChange?: (name: string, value: any, id: number) => void,
    onIconClick?: (id?: number) => void,
    getOptions?: (name: string) => Option[]
}

const ProposalSheet = (props: IComponent) => {
    const intl = useIntl()

    const onClick = () => {
        if (typeof props.handleCreate === 'function') {
            props.handleCreate()
        }
    }

    const onIconClick = (id?: number) => {
        if (typeof props.onIconClick === 'function') {
            props.onIconClick(id)
        }
    }

    const button = typeof props.handleCreate === 'function'

    const total = useMemo(() => {
        return currencyFormatter(props.total)
    }, [props.total])
    
    return (
        <Paper sx={paper}>
            <Title>
                {props.title}
                {button &&
                    <Chip
                        variant='outlined'
                        label={intl.formatMessage({id: "proposal.AddItem"})}
                        onClick={onClick}
                        sx={chip}
                        icon={<PlaylistAdd />}
                    />
                }
            </Title>
            {!!props.data.length && <Header>
                {props.fields.map(item =>
                    <Cell
                        key={item.name}
                        width={item.width}
                        count={props.fields.length}
                        margin={props.margin}
                    >
                        {item.title}
                    </Cell>
                )}
            </Header>}
            {props.data.map(item => {
                return <ListItem
                    key={item.id}
                    fields={props.fields}
                    data={item}
                    onChange={props.onChange}
                    margin={props.margin}
                    onClick={onIconClick}
                    getOptions={props.getOptions}
                />
            })}
            {!props.data.length && <Empty>{intl.formatMessage({id: "proposal.EmptySheet"})}</Empty>}
            {total &&
                <Total>
                    <span style={{marginRight: '20px'}}>{intl.formatMessage({id: "proposal.Total"})}</span> {total}
                </Total>
            }
        </Paper>
    )
}

export default React.memo(ProposalSheet)
