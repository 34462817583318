import {types} from 'enums/fields'

export const createForm = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Sales close date',
        type: types.Date,
        name: 'salesCloseDate',
        required: false
    },
    {
        title: 'Start date',
        type: types.Date,
        name: 'starts',
        required: true,
        max: 'ends'
    },
    {
        title: 'End date',
        type: types.Date,
        name: 'ends',
        required: true,
        min: 'starts'
    },
    {
        title: 'Number of participants',
        type: types.Text,
        name: 'participants',
        required: false
    },
    {
        title: 'Type',
        type: types.Chip,
        name: 'typeId',
        required: true
    },
    {
        title: 'Client',
        type: types.Chip,
        name: 'clientId',
        required: false,
        adding: true
    },
    {
        title: 'Contact',
        type: types.Chip,
        name: 'contactId',
        required: false,
        optionsDependency: 'clientId',
        adding: true
    },
    {
        title: 'Team members',
        type: types.Chips,
        name: 'teamMembers',
        required: false
    },
    {
        title: 'Commercial owner',
        type: types.Chip,
        name: 'commercialOwnerId',
        required: false,
        optionsDependency: 'teamMembers',
    },
    {
        title: 'Context',
        type: types.Textarea,
        name: 'context',
        required: false
    }
]

export const updateForm = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true,
        disabled: false
    },
    {
        title: 'Sales close date',
        type: types.Date,
        name: 'salesCloseDate',
        required: false,
        disabled: false
    },
    {
        title: 'Start date',
        type: types.Date,
        name: 'starts',
        required: true,
        max: 'ends',
        disabled: false
    },
    {
        title: 'End date',
        type: types.Date,
        name: 'ends',
        required: true,
        min: 'starts',
        disabled: false
    },
    {
        title: 'Number of participants',
        type: types.Text,
        name: 'participants',
        required: false,
        disabled: false
    },
    {
        title: 'Type',
        type: types.Chip,
        name: 'typeId',
        required: true,
        disabled: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: true,
        disabled: false
    },
    {
        title: 'Client',
        type: types.Chip,
        name: 'clientId',
        required: false,
        adding: true,
        disabled: false
    },
    {
        title: 'Contact',
        type: types.Chip,
        name: 'contactId',
        required: false,
        optionsDependency: 'clientId',
        adding: true,
        disabled: false
    },
    {
        title: 'Team members',
        type: types.Chips,
        name: 'teamMembers',
        required: false,
        disabled: false
    },
    {
        title: 'Commercial owner',
        type: types.Chip,
        name: 'commercialOwnerId',
        required: false,
        optionsDependency: 'teamMembers',
        disabled: false
    },
    {
        title: 'Context',
        type: types.Textarea,
        name: 'context',
        required: false,
        disabled: false
    }
]