import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Replay from '@mui/icons-material/Replay'

interface IComponent {
    documentId: number
    name: string
    onClick?: (documentId: number) => void
}

const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ResetButton = (props: IComponent) => {
    const [openPopup, setOpenPopup] = useState<boolean>(false)

    const intl = useIntl()

    const handleOpen = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        setOpenPopup(true)
    }

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        setOpenPopup(false)
    }

    const handleConfirm = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (typeof props.onClick === 'function') {
            props.onClick(props.documentId)
        }

        setOpenPopup(false)
    }

    return (
        <Container>
            <React.Fragment>
                <IconButton onClick={handleOpen} color='warning'>
                    <Replay fontSize='small' />
                </IconButton>
                <Dialog
                    open={openPopup}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        {intl.formatMessage({id: "app.ConfirmYourAction"})}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {intl.formatMessage({id: "app.WantToResetPassword"}, {name: props.name})}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{intl.formatMessage({id: "app.Cancel"})}</Button>
                        <Button onClick={handleConfirm} variant='contained'>{intl.formatMessage({id: "app.Confirm"})}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </Container>
    )
}

export default React.memo(ResetButton)
