import {types} from 'enums/fields'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 300,
        type: types.Chip,
        color: 'color'
    },
    {
        name: 'email',
        title: 'Email',
        width: 200,
        type: types.Text
    },
    {
        name: 'userData_teamMemberId',
        title: 'Create account',
        width: 200,
        type: types.Custom
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Color',
        type: types.Color,
        name: 'color',
        required: false,
        data: 'palette'
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'email',
        required: false
    }
]