import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div<{width: number, count: number, margin: number}>`
    width: ${props => `calc(${props.width} * 10% - ${props.margin - props.margin / props.count}px)`};
`

interface IComponent {
    width: number
    count: number
    margin: number
    children: React.ReactNode
}

const Cell = (props: IComponent) => {
    return (
        <Container
            width={props.width}
            count={props.count}
            margin={props.margin}
        >
            {props.children}
        </Container>
    )
}

export default React.memo(Cell)
