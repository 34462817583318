import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import {useTheme} from '@mui/material/styles'
import Chip from '@mui/material/Chip'
import {types} from 'enums/fields'
import {DateFormate, DateTimeFormate} from 'enums/utils'

const Container = styled.div`
    font-size: 13px;
    margin-bottom: 20px;

    :last-child {
        margin-bottom: 0;
    }
`

const Title = styled.div<{textColor: string}>`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: ${props => props.textColor};
    white-space: nowrap;
    height: 20px;
`

const Icon = styled.div`
    margin-right: 5px;
`

const Name = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`

const Text = styled.div`
    height: 15px;
`

const chip = {
    marginBottom: '10px'
}

interface IField {
    label: string
    icon: JSX.Element
    type: string
    value: string
    props?: {
        id: string
        text?: string
        background?: string
        value?: string
    }
}

interface IComponent {
    data: any
    field: IField
}

const Item = (props: IComponent) => {
    const theme = useTheme()

    const getItem = (field: IField) => {
        const value = props.data[field.value]

        switch (field.type) {
            case types.Date:
                return (
                    <Text>
                        {value ? dayjs(value).format(DateFormate) : ''}
                    </Text>
                )

            case types.DateTime:
                return (
                    <Text>
                        {value ? dayjs(value).format(DateTimeFormate) : ''}
                    </Text>
                )

            case types.Chip:
                return (
                    <div>
                        <Chip label={value}
                            sx={{
                                ...chip,
                                background: field.props && field.props.background ? props.data[field.props.background] : '',
                            }}
                        />
                    </div>
                )

            default:
                return (
                    <Text>{value}</Text>
                )
        }

    }
    
    return (
        <Container>
            <Title textColor={theme.palette.info.dark}>
                {props.field.icon &&
                    <Icon>{props.field.icon}</Icon>
                }
                <Name>{props.field.label}</Name>
            </Title>
            {getItem(props.field)}
        </Container>
    )
}

export default React.memo(Item)