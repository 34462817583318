import React from 'react'
import styled from '@emotion/styled'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import Bookmark from '@mui/icons-material/Bookmark'
import {defaultEventColor} from 'enums/styles'

const chip = {
    height: '20px',
    borderRadius: '5px',
    marginBottom: '10px',
    minWidth: 0
}

const ListItemContent = styled.div`
    display: flex;
    justify-content: space-between;
`

const Note = styled.span`
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #708085;
    margin-top: 5px;
    display: flex;
`

const Name = styled.span`
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
    display: flex;
`

interface IComponent {
    id: number
    label: string
    background: string
    mark: boolean
    name: string
    email: string
    phone: string
    selected: boolean
    onClick: (id: number) => void
}

const Item = (props: IComponent) => {
    const onClick = () => {
        props.onClick(props.id)
    }

    return (
        <ListItem disablePadding>
            <ListItemButton
                onClick={onClick}
                selected={props.selected}
                sx={{height: '120px', alignItems: 'flex-start'}}
            >
                <ListItemText
                    sx={{margin: 0}}
                    primary={<ListItemContent>
                        <Chip label={props.label} sx={{...chip, background: props.background || defaultEventColor}} />
                        {props.mark &&
                            <Tooltip title="Session booked" placement="bottom">
                                <Bookmark sx={{color: '#4caf50', marginLeft: '20px', marginTop: '-3px'}} />
                            </Tooltip>
                        }
                    </ListItemContent>}
                    secondary={<React.Fragment>
                        {props.name && <Name>{props.name}</Name>}
                        {props.email && <Note>{props.email}</Note>}
                        {props.phone && <Note>{props.phone}</Note>}
                    </React.Fragment>}
                />
            </ListItemButton>
        </ListItem>
    )
}

export default React.memo(Item) 