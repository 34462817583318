import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'areas',
        title: 'Areas',
        width: 300,
        type: types.Chips,
        itemId: 'areaId',
        itemLabel: 'area_name',
        itemColor: 'area_color'
    },
    {
        name: 'photo_fileId',
        title: 'Photo',
        width: 200,
        type: types.Image
    },
    {
        name: 'email',
        title: 'Email',
        width: 200,
        type: types.Text
    },
    {
        name: 'phone',
        title: 'Phone',
        width: 200,
        type: types.Phone
    },
    {
        name: 'hourlyPrice',
        title: 'Hourly price',
        width: 200,
        type: types.Currency
    },
    {
        name: 'joinLink',
        title: 'Join link',
        width: 200,
        type: types.Link,
        link: 'joinLink'
    },
    {
        name: 'userData_id',
        title: 'Create account',
        width: 200,
        type: types.Custom
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Areas',
        type: types.Chips,
        name: 'areas',
        required: false,
        data: Entity.area,
        key: 'areaId'
    },
    {
        title: 'Photo',
        type: types.Image,
        name: 'photo_name',
        required: false
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'email',
        required: false
    },
    {
        title: 'Phone',
        type: types.Phone,
        name: 'phone',
        required: false
    },
    {
        title: 'Hourly price',
        type: types.Number,
        name: 'hourlyPrice',
        required: false
    },
    {
        title: 'Join link',
        type: types.Text,
        name: 'joinLink',
        required: false
    }
]

export const filter = [
    {
        title: 'Filter by Areas',
        name: 'areas',
        type: types.Chips,
        data: Entity.area,
        key: 'areaId'
    }
]

export const entities = [Entity.area]