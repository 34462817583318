export const palette = [
    {
        id: 1,
        name: 'Red',
        color: '#ffcdd2'
    },
    {
        id: 2,
        name: 'Pink',
        color: '#f8bbd0'
    },
    {
        id: 3,
        name: 'Purple',
        color: '#e1bee7'
    },
    {
        id: 4,
        name: 'Indigo',
        color: '#c5cae9'
    },
    {
        id: 5,
        name: 'Blue',
        color: '#bbdefb'
    },
    {
        id: 6,
        name: 'Cyan',
        color: '#b2ebf2'
    },
    {
        id: 7,
        name: 'Teal',
        color: '#b2dfdb'
    },
    {
        id: 8,
        name: 'Green',
        color: '#c8e6c9'
    },
    {
        id: 9,
        name: 'Lime',
        color: '#f0f4c3'
    },
    {
        id: 10,
        name: 'Amber',
        color: '#ffecb3'
    },
    {
        id: 11,
        name: 'Orange',
        color: '#ffccbc'
    },
    {
        id: 12,
        name: 'Dark red',
        color: '#ff8a80'
    },
    {
        id: 13,
        name: 'Dark pink',
        color: '#ff80ab'
    },
    {
        id: 14,
        name: 'Dark purple',
        color: '#ea80fc'
    },
    {
        id: 15,
        name: 'Dark indigo',
        color: '#8c9eff'
    },
    {
        id: 16,
        name: 'Dark blue',
        color: '#82b1ff'
    },
    {
        id: 17,
        name: 'Dark cyan',
        color: '#84ffff'
    },
    {
        id: 18,
        name: 'Dark teal',
        color: '#a7ffeb'
    },
    {
        id: 19,
        name: 'Dark green',
        color: '#b9f6ca'
    },
    {
        id: 20,
        name: 'Dark lime',
        color: '#f4ff81'
    },
    {
        id: 21,
        name: 'Dark amber',
        color: '#ffe57f'
    },
    {
        id: 22,
        name: 'Dark orange',
        color: '#ff9e80'
    }
]