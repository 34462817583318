import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useAppSelector} from 'app/hooks'
import {access} from 'enums/routes'

interface IComponent {
    access: number[]
}

const Access = (props: IComponent) => {
    const user = useAppSelector(state => state.auth.user)
    const type = user ? Object.values(access).find(item => item.id === user.roleId) : undefined

    return (
        <React.Fragment>
            {user && type &&
                <React.Fragment>
                    {props.access.includes(user.roleId) ?
                        <Outlet /> :
                        <Navigate to={type.redirect} replace={true} />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Access