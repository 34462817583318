import React from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Navigate
} from 'react-router-dom'
import {Provider} from 'react-redux'
import {ThemeProvider} from '@mui/material/styles'

import Intl from 'containers/Intl'
import Root from 'containers/Root'
import Redirect from 'containers/Redirect'
import Access from 'containers/Access'
import Auth from 'containers/Auth'
import Signin from 'containers/Signin'
import Account from 'containers/Account'

import Trainees from 'containers/Employee/Entities/Trainees'
import Trainers from 'containers/Employee/Entities/Trainers'
import Expenses from 'containers/Employee/Entities/Expenses'
import DefaultTasks from 'containers/Employee/Entities/Tasks'
import Clients from 'containers/Employee/Entities/Clients'
import Contacts from 'containers/Employee/Entities/Contacts'
import Members from 'containers/Employee/Entities/Members'
import Types from 'containers/Employee/Entities/Types'
import Areas from 'containers/Employee/Entities/Areas'
import Users from 'containers/Employee/Entities/Users'
import Templates from 'containers/Employee/Entities/Templates'

import Employee from 'containers/Employee'
import Projects from 'containers/Employee/Projects'
import Academias from 'containers/Employee/Academias'
import Tasks from 'containers/Employee/Tasks'
import Commissions from 'containers/Employee/Commissions'
import Forecast from 'containers/Employee/Forecast'
import Calendar from 'containers/Employee/Calendar'
import Availability from 'containers/Employee/Availability'
import Entities from 'containers/Employee/Entities'
import Backups from 'containers/Employee/Backups'

import Project from 'containers/Employee/Project'
import ProjectSessions from 'containers/Employee/Project/Sessions'
import ProjectTasks from 'containers/Employee/Project/Tasks'
import ProjectOverview from 'containers/Employee/Project/Overview'
import ProjectParticipants from 'containers/Employee/Project/Participants'
import ProjectProposal from 'containers/Employee/Project/Proposal'
import ProjectAppointments from 'containers/Employee/Project/Appointments'
import ProjectCalendar from 'containers/Employee/Project/Calendar'
import ProjectInvoices from 'containers/Employee/ProjectInvoices'

import Academia from 'containers/Employee/Academia'
import AcademiaOverview from 'containers/Employee/Academia/Overview'
import AcademiaSessions from 'containers/Employee/Academia/Sessions'
import AcademiaParticipants from 'containers/Employee/Academia/Participants'
import AcademiaAreas from 'containers/Employee/Academia/Areas'
import AcademiaTasks from 'containers/Employee/Academia/Tasks'
import AcademiaCalendar from 'containers/Employee/Academia/Calendar'
import AcademiaInvoices from 'containers/Employee/AcademiaInvoices'

import Trainee from 'containers/Trainee'
import Courses from 'containers/Trainee/Courses'
import Course from 'containers/Trainee/Course'

import Trainer from 'containers/Trainer'
import Trainings from 'containers/Trainer/Trainings'
import Schedule from 'containers/Trainer/Schedule'
import Training from 'containers/Trainer/Training'
import TrainingSessions from 'containers/Trainer/Training/Sessions'
import TrainingParticipants from 'containers/Trainer/Training/Participants'

import routes, {access} from 'enums/routes'
import {store} from './app/store'
import theme from 'theme'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Intl />}>
            <Route element={<Root />}>
                <Route path={routes.Signin} element={<Signin />} />
                <Route element={<Auth />}>
                    <Route path={routes.Account} element={<Account />} />
                    
                    <Route element={<Employee />}>
                        <Route element={<Access access={[access.master.id, access.team.id]}/>}>
                            <Route path={routes.Projects} element={<Projects />} />
                            <Route path={routes.Academias} element={<Academias />} />
                            <Route path={routes.Tasks} element={<Tasks />} />
                            <Route path={routes.Calendar} element={<Calendar />} />
                            <Route path={routes.ProjectInvoices} element={<ProjectInvoices />} />
                            <Route path={routes.AcademiaInvoices} element={<AcademiaInvoices />} />
                            <Route path={routes.Commissions} element={<Commissions />} />
                            <Route path={routes.Forecast} element={<Forecast />} />
                            <Route path={routes.Availability} element={<Availability />} />
                            <Route path={`${routes.Project}/:projectId`} element={<Project />}>
                                <Route path={routes.Overview} element={<ProjectOverview />} />
                                <Route path={routes.Tasks} element={<ProjectTasks />} />
                                <Route path={routes.Sessions} element={<ProjectSessions />} />
                                <Route path={routes.Participants} element={<ProjectParticipants />} />
                                <Route path={routes.Program} element={<ProjectAppointments />} />
                                <Route path={routes.Proposal} element={<ProjectProposal />} />
                                <Route path={routes.Calendar} element={<ProjectCalendar />} />
                            </Route>
                            <Route path={`${routes.Academia}/:academiaId`} element={<Academia />}>
                                <Route path={routes.Overview} element={<AcademiaOverview />} />
                                <Route path={routes.Tasks} element={<AcademiaTasks />} />
                                <Route path={routes.Sessions} element={<AcademiaSessions />} />
                                <Route path={routes.Participants} element={<AcademiaParticipants />} />
                                <Route path={routes.Program} element={<AcademiaAreas />} />
                                <Route path={routes.Calendar} element={<AcademiaCalendar />} />
                            </Route>
                            <Route path={routes.Entities} element={<Entities />}>
                                <Route path={routes.Areas} element={<Areas />} />
                                <Route path={routes.Trainees} element={<Trainees />} />
                                <Route path={routes.Trainers} element={<Trainers />} />
                                <Route path={routes.Expenses} element={<Expenses />} />
                                <Route path={routes.Tasks} element={<DefaultTasks />} />
                                <Route path={routes.Clients} element={<Clients />} />
                                <Route path={routes.Contacts} element={<Contacts />} />
                                <Route path={routes.Members} element={<Members />} />
                                <Route path={routes.Types} element={<Types />} />
                                <Route path={routes.Users} element={<Users />} />
                                <Route path={routes.Templates} element={<Templates />} />
                            </Route>
                            <Route path={routes.Backups} element={<Backups />} />
                        </Route>
                    </Route>

                    <Route element={<Trainee />}>
                        <Route element={<Access access={[access.trainee.id]}/>}>
                            <Route path={`${routes.Courses}/:academiaId?`} element={<Courses />} />
                            <Route path={`${routes.Courses}/:academiaId/${routes.Calendar}`} element={<Course />} />
                        </Route>
                    </Route>

                    <Route element={<Trainer />}>
                        <Route element={<Access access={[access.trainer.id]}/>}>
                            <Route path={routes.Trainings} element={<Trainings />} />
                            <Route path={routes.Schedule} element={<Schedule />} />
                            <Route path={`${routes.Training}/:academiaId`} element={<Training />}>
                                <Route path={routes.Sessions} element={<TrainingSessions />} />
                                <Route path={routes.Participants} element={<TrainingParticipants />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path={routes.Redirect} element={<Redirect />} />
                </Route>
                <Route path='*' element={<Navigate to={routes.Redirect} replace={true} />} />
            </Route>
        </Route>
    )
)

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </ThemeProvider>
    )
}

export default App
