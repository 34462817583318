import {types} from 'enums/fields'

export const sheetConfig = [
    {
        name: 'name',
        title: 'Name',
        width: 400,
        type: types.Text
    },
    {
        name: 'deadline',
        title: 'Date',
        width: 200,
        type: types.Date
    },
    {
        name: 'status_name',
        title: 'Status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'teamMembers_name',
        title: 'Assigned to',
        width: 200,
        type: types.Chip,
        color: 'teamMembers_color'
    },
    {
        name: 'comment',
        title: 'Comment',
        width: 400,
        type: types.Text
    },
]

export const formConfig = [
    {
        title: 'Name',
        type: types.Suggestions,
        name: 'name',
        required: true
    },
    {
        title: 'Date',
        type: types.Date,
        name: 'deadline',
        required: false
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: true
    },
    {
        title: 'Assigned to',
        type: types.Chip,
        name: 'teamMemberId',
        required: false
    },
    {
        title: 'Comment',
        type: types.Textarea,
        name: 'comment',
        required: false
    }
]

export const filterConfig = [
    {
        title: 'Filter by Status',
        name: 'statusId',
        type: types.Chip
    },
    {
        title: 'Filter by Assigned to',
        name: 'teamMemberId',
        type: types.Chip
    }
]