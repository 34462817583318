import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {IAuthData, IReqLogin, IReqEnv} from 'api/interfaces'
import {ApiRoute} from 'api/enums'
import api from 'utils/api'

interface IUser {
    id: number
    login: string
    name: string
    email: string
    roleId: number
    [key: string]: any
}

interface IState {
    user: IUser | null,
    pending: boolean,
    authorized: boolean,
    initialized: boolean
}

const initialState: IState = {
    user: null,
    pending: false,
    authorized: false,
    initialized: false
}

export const getInit = createAsyncThunk('auth/getInit', async (data: IReqEnv) => {
    const response = await api(ApiRoute.getenv, data)
    return response.data as IAuthData
  }
)

export const getLogin = createAsyncThunk('auth/getLogin', async (data: IReqLogin) => {
      const response = await api(ApiRoute.signin, data)
      return response.data as IAuthData
    }
)

export const getLogout = createAsyncThunk('auth/getLogout', async () => {
    const response = await api(ApiRoute.signout)
        return response.data as IAuthData
    }
)

export const changePass = createAsyncThunk('auth/changePass', async (data: any, {rejectWithValue}) => {
    try {
        const response = await api(ApiRoute.action, data)
        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
  }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogin.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getLogin.fulfilled, (state, action) => {
                const user = action.payload.user
                state.authorized = !!user
                state.user = user
                state.pending = false
            })
            .addCase(getLogin.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getInit.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getInit.fulfilled, (state, action) => {
                const user = action.payload.user
                state.authorized = !!user
                state.user = user
                state.pending = false
                state.initialized = true
            })
            .addCase(getInit.rejected, (state, action) => {
                state.pending = false
                state.initialized = true
            })

            .addCase(getLogout.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getLogout.fulfilled, (state, action) => {
                const user = action.payload.user

                state.authorized = !!user
                state.user = user
                state.pending = false
            })
            .addCase(getLogout.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(changePass.pending, (state, action) => {
                state.pending = true
            })
            .addCase(changePass.fulfilled, (state, action) => {
                state.pending = false
            })
            .addCase(changePass.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default authSlice