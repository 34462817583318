import React, {ChangeEvent} from 'react'
import {debounce} from '@framelink/utils'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'

interface IComponent {
    value: string,
    onChange: (value: string) => void
}

const input = {
    fontSize: 14
}

const container = {
    maxWidth: {xs: '100%', sm: 400}
}

const TextFilter = (props: IComponent) => {
    const onChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }, 300)

    return (
        <TextField
            defaultValue={props.value}
            onChange={onChange}
            fullWidth
            size='small'
            sx={container}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                ),
                sx: input
            }}
        />
    )
}

export default React.memo(TextFilter)
