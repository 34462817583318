import React, {useState, useMemo, createContext, useCallback} from 'react'
import {Outlet, Navigate, Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Logout from '@mui/icons-material/Logout'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Menu from '@mui/icons-material/Menu'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getLogout} from 'slices/auth'
import routes from 'enums/routes'
import main from 'images/main.png'

const Name = styled(Link)`
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
`

const box = {
    display: 'flex',
    flexGrow: 1,
    minWidth: 0,
    minHeight: 0
}

interface IContext {
    openSidebar: boolean
    handleOpenSidebar: () => void
}

export const Context = createContext<IContext>({openSidebar: false, handleOpenSidebar: () => {}})

const Auth = () => {
    const [openSidebar, setOpenSidebar] = useState<boolean>(false)

    const authorized = useAppSelector(state => state.auth.authorized)
    const initialized = useAppSelector(state => state.auth.initialized)
    const user = useAppSelector(state => state.auth.user)

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const onClick = () => {
        dispatch(getLogout())
    }

    const handleOpenSidebar = useCallback(() => {
        setOpenSidebar(open => !open)
    }, [])

    const context = useMemo(() => ({
        openSidebar,
        handleOpenSidebar
    }), [openSidebar, handleOpenSidebar])

    return (
        <React.Fragment>
            <AppBar position='fixed' sx={{zIndex: 1300}} elevation={3}>
                <Toolbar>
                    <IconButton onClick={handleOpenSidebar} sx={{mr: 2, color: 'inherit', display: {md: 'none'}}}>
                        <Menu fontSize='small' />
                    </IconButton>
                    <Link to={routes.Redirect} style={{marginRight: 'auto'}}><img src={main} style={{height: '40px'}} /></Link>
                    <Name to={routes.Account}>{user && user.name}</Name>
                    <Tooltip title={intl.formatMessage({id: "login.Logout"})} placement="bottom" sx={{color: 'inherit'}}>
                        <IconButton onClick={onClick}>
                            <Logout fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Box sx={{...box, flexDirection: 'column'}} component='main'>
                <Toolbar />
                <Box sx={box}>
                    {!initialized || authorized ?
                        <Context.Provider value={context}>
                            <Outlet />
                        </Context.Provider> :
                        <Navigate to={routes.Signin} replace={true} />
                    }
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default Auth