import React from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import {setTime} from 'utils/datetime'

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0;
`

const Buttons = styled.div`
    display: flex;
`

interface IComponent {
    id: string,
    title: string,
    start: string,
    menuItemId: number | null,
    sessionId?: number,
    booked: boolean,
    link?: string,
    phone?: string,
    onConfirm: (id: string, title: string, start: string, menuItemId: number | null) => void,
    onCancel: (sessionId?: number) => void,
    onClose: () => void
}

const cancellationLimit = 48
const reservationLimit = 24
const delay = 0.25

const EventPopup = (props: IComponent) => {
    const intl = useIntl()

    const target = setTime(new Date(props.start), delay)
    const current = new Date()
    const past = current > target
    const cancellation = target > setTime(new Date(), cancellationLimit)
    const reservation = target > setTime(new Date(), reservationLimit)

    const date = intl.formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    const onConfirm = () => {
        props.onClose()
        props.onConfirm(props.id, props.title, props.start, props.menuItemId)
    }

    const onCancel = () => {
        props.onClose()
        props.onCancel(props.sessionId)
    }
    
    return (
        <div>
            {props.booked ?
                <React.Fragment>
                    {past ?
                        <Text>
                            {intl.formatMessage({id: "calendar.PastSession"}, {title: props.title, date: date, time: time})}
                        </Text> :
                        <React.Fragment>
                            <Text>
                                {cancellation ? intl.formatMessage({id: "calendar.CancelReservation"}, {title: props.title, date: date, time: time}) :
                                    intl.formatMessage({id: "calendar.Uneditable"}, {limit: cancellationLimit, name: props.title, phone: props.phone})}
                            </Text>
                            <Buttons>
                                {cancellation &&
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='warning'
                                        onClick={onCancel}
                                        sx={{marginRight: 4}}
                                    >
                                        {intl.formatMessage({id: 'calendar.CancelSession'})}
                                    </Button>
                                }
                                {props.link &&
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='secondary'
                                        component={Link}
                                        to={props.link}
                                        target='_blank'
                                    >
                                        {intl.formatMessage({id: 'calendar.Connect'})}
                                    </Button>
                                }
                            </Buttons>
                        </React.Fragment>
                    }
                </React.Fragment> :
                <React.Fragment>
                    {reservation ?
                        <React.Fragment>
                            <Text>
                                {intl.formatMessage({id: "calendar.Session"}, {title: props.title, date: date, time: time})}
                            </Text>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={onConfirm}
                            >
                                {intl.formatMessage({id: 'calendar.Book'})}
                            </Button>
                        </React.Fragment> :
                        <Text>
                            {intl.formatMessage({id: "calendar.Uneditable"}, {limit: reservationLimit, name: props.title, phone: props.phone})}
                        </Text>
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default React.memo(EventPopup) 