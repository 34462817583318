import React, {useEffect, useState, useCallback, useLayoutEffect} from 'react'
import {Outlet, Navigate, useParams, useLocation} from 'react-router-dom'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProject, resetProject} from 'slices/projects'
import Breadcrumbs from 'components/Breadcrumbs'
import TextFilter from 'components/TextFilter'
import routes from 'enums/routes'
import {TopBarHeight} from 'enums/styles'

const Top = styled.div<{height: number}>`
    height: ${props => props.height}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const chip = {
    width: '180px',
    marginLeft: 'auto'
}

const breadcrumbs = [
    {
        name: 'section',
        path: `/${routes.Projects}`
    },
    {
        name: 'name'
    }
]

const Project = () => {
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [textFilter, setTextFilter] = useState<string>('')

    const project = useAppSelector(state => state.projects.info)
    const notExist = useAppSelector(state => state.projects.notExist)
    const [appliedFilters, setAppliedFilters] = useState<boolean>(false)

    const {projectId} = useParams()
    const {pathname} = useLocation()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (projectId) {
            dispatch(getProject({id: Number(projectId)}))
        }

        return () => {
            dispatch(resetProject())
        }
    }, [dispatch, projectId])

    useLayoutEffect(() => {
        setAppliedFilters(false)
    }, [pathname])

    const handleFilterOpen = () => {
        setOpenFilter(true)
    }

    const handleFilterClose = (filters?: {appliedFilters: boolean}) => {
        if (filters) {
            setAppliedFilters(filters.appliedFilters)
        }

        setOpenFilter(false)
    }

    const handleFilterText = useCallback((value: string) => {
        setTextFilter(value)
    }, [])

    const filter = pathname.includes(routes.Tasks) || pathname.includes(routes.Sessions) || pathname.includes(routes.Calendar)
    const search = pathname.includes(routes.Participants)
    const main = pathname.includes(routes.Overview) || pathname.includes(routes.Proposal)

    return (
        <React.Fragment>
            {!main &&
                <Top height={TopBarHeight}>
                    {project &&
                        <React.Fragment>
                            <Breadcrumbs margin={'0 20px 0 0'} items={breadcrumbs} data={{name: project.name, section: 'Projects'}} />
                            {filter &&
                                <Chip
                                    icon={<FilterAlt />}
                                    label={'Filters'}
                                    sx={chip}
                                    onClick={handleFilterOpen}
                                    variant={appliedFilters ? 'filled' : 'outlined'}
                                    color='secondary'
                                />
                            }
                            {search &&
                                <TextFilter onChange={handleFilterText} value={textFilter} />
                            }
                        </React.Fragment>
                    }
                </Top>
            }
            {notExist ?
                <Navigate to={`/${routes.Projects}`} replace={true} /> :
                <Outlet context={{textFilter, openFilter, handleFilterClose}} />
            }
        </React.Fragment>
    )
}

export default Project