import {types} from 'enums/fields'

export const filter = [
    {
        title: 'Filter by Project',
        name: 'projectArea_projectId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainer',
        name: 'projectArea_trainerId',
        type: types.Chip
    },
    {
        title: 'Filter by Trainee',
        name: 'traineeId',
        type: types.Chip
    }
]