import {types} from 'enums/fields'
import {Entity} from 'api/enums'

export const sheet = [
    {
        name: 'name',
        title: 'Name',
        width: 200,
        type: types.Text
    },
    {
        name: 'login',
        title: 'Login',
        width: 200,
        type: types.Text
    },
    {
        name: 'email',
        title: 'Email',
        width: 200,
        type: types.Text
    },
    {
        name: 'role_name',
        title: 'Role',
        width: 200,
        type: types.Chip
    },
    {
        name: 'id',
        title: 'Reset password',
        width: 200,
        type: types.Custom
    }
]

export const form = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Login',
        type: types.Text,
        name: 'login',
        required: true
    },
    {
        title: 'Email',
        type: types.Text,
        name: 'email',
        required: true
    },
    {
        title: 'Password',
        type: types.Text,
        name: 'pwd',
        required: true
    },
    {
        title: 'Role',
        type: types.Chip,
        name: 'roleId',
        required: true,
        data: Entity.role
    }
]

export const entities = [Entity.role]