import React from 'react'
import {useAppSelector} from 'app/hooks'
import GeneralCalendar from 'containers/Employee/Project/Calendar/GeneralCalendar'
import CorporateCalendar from 'containers/Employee/Project/Calendar/CorporateCalendar'
import {AcademiaCorporate} from 'enums/utils'

const Calendar = () => {
    const project = useAppSelector(state => state.projects.info)
    
    return (
        <React.Fragment>
            {project && 
                <React.Fragment>
                    {project.typeId === AcademiaCorporate ?
                        <CorporateCalendar /> :
                        <GeneralCalendar />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Calendar