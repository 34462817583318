import React, {useMemo} from 'react'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import {DateFormate, DateTimeFormate} from 'enums/utils'

interface IComponent {
    value: string
    datetime: boolean
}

const Input = styled.div<{color: string}>`
    height: 100%;
    width: 100%;
    padding: 0 10px;
    color: ${props => props.color};
`

const DateTime = (props: IComponent) => {
    const theme = useTheme()

    const format = props.datetime ? DateTimeFormate : DateFormate

    const date = useMemo(() => {
        return props.value ? dayjs(props.value).format(format) : ''
    }, [props.value, format])

    return (
        <Input color={theme.palette.text.primary}>{date}</Input>
    )
}

export default React.memo(DateTime)
