import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react'
import {useParams, useOutletContext} from 'react-router-dom'
import styled from '@emotion/styled'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {filter} from '@framelink/utils'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getAcademiaParticipants, createAcademiaParticipants, deleteAcademiaParticipants, updateAcademiaParticipantsImage, createAcademiaParticipantsImage} from 'slices/academias'
import {getEntities, createEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import InvoiceRules from 'components/InvoiceRules/Academia'
import Bottom from 'components/SheetBottom'
import {createFormConfig, updateFormConfig} from 'enums/academias/participants'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {IContext} from 'containers/Employee/Academia/interfaces'
import {Entity, View, Sheet} from 'api/enums'

type InvoiceRule = {
    id?: number
    percent: string
    daysAfter: number
    projectTraineeId: number
}

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
    border-top: 1px solid rgba(0, 0, 0, 0.12);
`

interface IField {
    name: number | string,
    title: string,
    width: number,
    type?: string,
    color?: string
}

const defaultSheetFields: any = []

const Participants = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [participantId, setParticipantId] = useState<number | null>(null)
    const [incorrectInvoice, setIncorrectInvoice] = useState<boolean>(false)

    const invoice = useRef<InvoiceRule[]>([])

    const academia = useAppSelector(state => state.academias.info)
    const participants = useAppSelector(state => state.academias.participants)
    const trainees = useAppSelector(state => state.entities[Entity.trainee])
    const clients = useAppSelector(state => state.entities[Entity.client])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.academiaParticipants])
    const user = useAppSelector(state => state.auth.user)

    const {academiaId} = useParams()
    const dispatch = useAppDispatch()
    const {textFilter}: IContext = useOutletContext()

    useEffect(() => {
        if (academiaId) {
            dispatch(getAcademiaParticipants({id: Number(academiaId)}))
            dispatch(getEntities({entity: Entity.trainee}))
            dispatch(getEntities({entity: Entity.client}))
            dispatch(getSheet({name: Sheet.academiaParticipants}))
        }
    }, [dispatch, academiaId])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: IField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setParticipantId(documentId)
        }

        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setParticipantId(null)
        invoice.current = []
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}
        obj.projectId = Number(academiaId)

        if (participantId) {
            obj.id = participantId
            const participant = participants.find(item => item.id === participantId)

            if (participant) {
                const traineeId = participant.traineeId
                obj.traineeId = traineeId

                if (invoice.current.length) {
                    obj.invoiceRules = invoice.current
                }

                const data = new FormData()

                if (!obj.trainee_photo_name) {
                    obj.trainee_photoId = null
                } else if (typeof obj.trainee_photo_name !== 'string') {
                    const file = obj.trainee_photo_name
                
                    obj.trainee_photoId = '$file=1'
    
                    data.append('$file=1', file)
                }

                if (!obj.report_name) {
                    obj.reportId = null
                } else if (typeof obj.report_name !== 'string') {
                    const file = obj.report_name
                
                    obj.reportId = '$file=2'
    
                    data.append('$file=2', file)
                }

                if (!obj.diploma_name) {
                    obj.diplomaId = null
                } else if (typeof obj.diploma_name !== 'string') {
                    const file = obj.diploma_name
                
                    obj.diplomaId = '$file=3'
    
                    data.append('$file=3', file)
                }

                if (!obj.diagnostics_name) {
                    obj.diagnosticsId = null
                } else if (typeof obj.diagnostics_name !== 'string') {
                    const file = obj.diagnostics_name
                
                    obj.diagnosticsId = '$file=4'
    
                    data.append('$file=4', file)
                }

                delete obj.trainee_photo_name
                delete obj.report_name
                delete obj.diploma_name
                delete obj.diagnostics_name
                
                data.append('entity', Entity.projectTrainee)
                data.append('view', View.projectTrainees)
                data.append('data', JSON.stringify([obj]))

                dispatch(updateAcademiaParticipantsImage(data))
            }
        } else {
            if (values.traineeId) {
                const data: any = {
                    traineeId: values.traineeId,
                    projectId: Number(academiaId)
                }

                dispatch(createAcademiaParticipants({data: [{...data}]}))
            } else {
                if (!obj.trainee_photo_name) {
                    obj.trainee_photoId = null
                    delete obj.trainee_photo_name
                    dispatch(createAcademiaParticipants({data: [obj]}))
                } else if (typeof obj.trainee_photo_name === 'string') {
                    delete obj.trainee_photo_name
                    dispatch(createAcademiaParticipants({data: [obj]}))
                } else {
                    const file = obj.trainee_photo_name
                    const data = new FormData()
    
                    obj.trainee_photoId = '$file=1'
                    delete obj.trainee_photo_name
    
                    data.append('$file=1', file)
                    data.append('entity', Entity.projectTrainee)
                    data.append('view', View.projectTrainees)
                    data.append('data', JSON.stringify([obj]))
    
                    dispatch(createAcademiaParticipantsImage(data))
                }
            }
        }

        handleClose()
    }

    const handleDelete = () => {
        dispatch(deleteAcademiaParticipants({ids: checked}))
        setCheck([])
    }

    const handleCreate = (name: string, value: string) => {
        if (name === 'trainee_clientId') {
            dispatch(createEntities({
                entity: Entity.client,
                data: [{name: value}]
            }))
        }
    }

    const getRulesComponent = useCallback((name: string) => {
        if (name === 'invoiceRules' && participantId) {
            const participant = participants.find(item => item.id === participantId)
            const invoiceRules = participant && participant.invoiceRules.length ? participant.invoiceRules : [{percent: '100', daysAfter: 0, projectTraineeId: participantId}]

            return <InvoiceRules
                values={invoiceRules}
                onChange={handleChangeInvoice}
                participantId={participantId}
                disabled={academia && academia.accepted}
            />
        }

        return <div></div>  
    }, [participantId, participants, academia])

    const handleChangeInvoice = (data: InvoiceRule[]) => {
        invoice.current = data
        const sum = data.reduce((acc, item) => acc + Number(item.percent), 0)

        if (sum && sum !== 100) {
            setIncorrectInvoice(true)
        } else {
            setIncorrectInvoice(false)
        }
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'traineeId') {
            return trainees.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    description: item.email
                }
            })
        } else if (name === 'trainee_clientId') {
            return clients
        } else {
            return []
        }
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaParticipants,
                data: [{
                    name: Sheet.academiaParticipants,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.academiaParticipants,
                data: [{
                    name: Sheet.academiaParticipants,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const getAutocomplete = (name: string, value: any) => {
        if (name === 'traineeId') {
            const data = trainees.find(item => item.id === value)

            return {
                trainee_name: data ? data.name : '',
                trainee_phone: data ? data.phone : '',
                trainee_email: data ? data.email : '',
                trainee_role: data ? data.role : '',
                trainee_clientId: data ? data.clientId : null,
                trainee_photo_name: data ? data.photo_name : null
            }
        } else {
            return undefined
        }
    }

    const updateInitialValues = useMemo(() => {
        const participant = participants.find(item => item.id === participantId)

        return {
            trainee_name: participant ? participant.trainee_name : '',
            trainee_phone: participant ? participant.trainee_phone : '',
            trainee_email: participant ? participant.trainee_email : '',
            trainee_role: participant ? participant.trainee_role : '',
            trainee_clientId: participant ? participant.trainee_clientId : null,
            trainee_photo_name: participant ? participant.trainee_photo_name : null,
            finalVideo: participant ? participant.finalVideo : '',
            report_name: participant ? participant.report_name : null,
            diploma_name: participant ? participant.diploma_name : null,
            diagnostics_name: participant ? participant.diagnostics_name : '',
            invoiceRules: participant ? participant.invoiceRules : []
        }
    }, [participants, participantId])

    const createInitialValues = useMemo(() => {
        return {
            traineeId: null,
            trainee_name: '',
            trainee_phone: '',
            trainee_email: '',
            trainee_role: '',
            trainee_clientId: null,
            trainee_photo_name: null
        }
    }, [])

    const participantsFiltered = useMemo(() => {
        return filter(participants, textFilter, ['trainee_name', 'trainee_phone', 'trainee_email', 'trainee_company_name', 'trainee_role'])
    }, [participants, textFilter])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false
    
    return (
        <React.Fragment>
            <Container margin={BottomBarHeight + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={participantsFiltered}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={handleOpen}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom height={BottomBarHeight} checked={!!checked.length} handleDelete={handleDelete} handleCreate={handleOpen} hiddenButtons={academia && academia.accepted} />
            <EditForm
                open={open && !participantId}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={'Create record'}
                config={createFormConfig}
                getOptions={getOptions}
                initialValues={createInitialValues}
                getAutocomplete={getAutocomplete}
                onCreate={handleCreate}
            />
            <EditForm
                open={open && !!participantId}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                title={'Change record'}
                config={updateFormConfig}
                getOptions={getOptions}
                initialValues={updateInitialValues}
                onCreate={handleCreate}
                getComponent={getRulesComponent}
                disabled={incorrectInvoice}
            />
        </React.Fragment>
    )
}

export default Participants