import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react'
import {createSelector} from '@reduxjs/toolkit'
import styled from '@emotion/styled'
import {useIntl} from 'react-intl'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {filter as utilsFilter} from '@framelink/utils'
import Chip from '@mui/material/Chip'
import FilterAlt from '@mui/icons-material/FilterAlt'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getEntities, deleteEntities, createEntities, updateEntities,
    updateImageEntities, createImageEntities} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import FilterForm from 'components/FilterForm'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import EditForm from 'components/EditForm'
import TextFilter from 'components/TextFilter'
import Bottom from 'components/SheetBottom'
import {BottomBarHeight, TopBarHeight} from 'enums/styles'
import {types, defaultValues} from 'enums/fields'
import {palette} from 'enums/palette'
import {RoleIdAdmin} from 'enums/utils'
import {View} from 'api/enums'

const chip = {
    width: '180px',
    marginLeft: 'auto',
    marginRight: 2,

    '&:last-child': {
        marginRight: 0
    }
}

const Top = styled.div<{height: number}>`
    height: ${props => props.height}px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
`

const defaultSheetFields: any = []

interface IComponent {
    data: string
    primaryId: string
    filterFields?: FilterField[]
    textFilter?: string[]
    editFormFields?: FormField[]
    entities: string[]
    sheet?: string
    protectedItem?: string
    bottom?: boolean
    totals?: string[]
    uneditable?: boolean,
    getCustomItem?: (value: any, column: any, data: any) => JSX.Element
}

type FilterField = {
    title: string
    name: string
    type: string
    key?: string
    data: string
    default?: number | number[]
}

type FormField = {
    title: string
    type: string,
    name: string,
    required: boolean
    data?: string
    key?: string
}

type SheetField = {
    name: number | string,
    title: string,
    width: number,
    type?: string,
    color?: string
}

type Filters = {
    [key: string]: number | number[] | null
}

const getStateEntities = (state: any) => state.entities
const getPropsList = (state: any, list: any) => list

const setEntitiesLists = () => {
    return createSelector(getStateEntities, getPropsList, (stateEntities: any, propsList: any) => {
        const items: any = {}

        propsList.forEach((item: any) => {
            items[item] = stateEntities[item]
        })
    
        return items
    })
}

const getFilterState = (filters?: FilterField[]) => {
    const data: any = {}

    if (filters) {
        filters.forEach(item => {
            data[item.name] = item.default ? item.default : item.type === types.Chips ? [] : null 
        })
    }

    return data
}

const Sheet = (props: IComponent) => {
    const [checked, setCheck] = useState<number[]>([])
    const [openEditForm, setOpenEditForm] = useState<boolean>(false)
    const [itemId, setItemId] = useState<number | null>(null)
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [textFilter, setTextFilter] = useState<string>('')
    const [stateFilters, setStateFilters] = useState<Filters>(() => getFilterState(props.filterFields))

    const editFormTitle = useRef<string>('')

    const sheet = useAppSelector(state => props.sheet ? state.sheets.data[props.sheet] : undefined)

    const user = useAppSelector(state => state.auth.user)

    const dataPrimary = useAppSelector(state => state.entities[props.data as keyof typeof state.entities])

    const getEntitiesLists = useMemo(setEntitiesLists, [])

    const entities = useAppSelector(state => getEntitiesLists(state, props.entities))

    const intl = useIntl()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (View[props.data as keyof typeof View]) {
            dispatch(getEntities({view: props.data}))
        } else {
            dispatch(getEntities({entity: props.data}))
        }
        
        props.entities.forEach(item => {
            if (View[item as keyof typeof View]) {
                dispatch(getEntities({view: item}))
            } else {
                dispatch(getEntities({entity: item}))
            }
        })

        if (props.sheet) {
            dispatch(getSheet({name: props.sheet}))
        }

    }, [dispatch, props.data, props.entities, props.sheet])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: SheetField) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        if (column.type === types.Custom && typeof props.getCustomItem === 'function') {
            const component = props.getCustomItem(value, column, data)

            return component
        }

        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleOpen = (documentId?: number) => {
        if (documentId) {
            setItemId(documentId)
            editFormTitle.current = 'Change record'
        } else {
            editFormTitle.current = 'Create record'
        }

        setOpenEditForm(true)
    }

    const handleClose = useCallback(() => {
        setOpenEditForm(false)
        setItemId(null)
    }, [])

    const handleDelete = () => {
        if (View[props.data as keyof typeof View]) {
            dispatch(deleteEntities({
                view: props.data,
                ids: checked
            }))
        } else {
            dispatch(deleteEntities({
                entity: props.data,
                ids: checked
            }))
        }

        setCheck([])
    }

    const handleConfirm = (values: any) => {
        const obj = {...values}

        if (itemId) {
            obj.id = itemId

            if (!obj.photo_name) {
                obj.photoId = null
                delete obj.photo_name

                if (View[props.data as keyof typeof View]) {
                    dispatch(updateEntities({
                        view: props.data,
                        data: [obj]
                    }))
                } else {
                    dispatch(updateEntities({
                        entity: props.data,
                        data: [obj]
                    }))
                }
            } else if (typeof obj.photo_name === 'string') {
                delete obj.photo_name

                if (View[props.data as keyof typeof View]) {
                    dispatch(updateEntities({
                        view: props.data,
                        data: [obj]
                    }))
                } else {
                    dispatch(updateEntities({
                        entity: props.data,
                        data: [obj]
                    }))
                }
            } else {
                const file = obj.photo_name
                const data = new FormData()

                obj.photoId = '$file=1'
                delete obj.photo_name

                data.append('$file=1', file)
                data.append('entity', props.data)
                data.append('data', JSON.stringify([obj]))

                dispatch(updateImageEntities({
                    entity: props.data,
                    data: data as any
                }))
            }

            setItemId(null)
        } else {
            if (!obj.photo_name) {
                obj.photoId = null
                delete obj.photo_name

                if (View[props.data as keyof typeof View]) {
                    dispatch(createEntities({
                        view: props.data,
                        data: [obj]
                    }))
                } else {
                    dispatch(createEntities({
                        entity: props.data,
                        data: [obj]
                    }))
                }
            } else if (typeof obj.photo_name === 'string') {
                delete obj.photo_name

                if (View[props.data as keyof typeof View]) {
                    dispatch(createEntities({
                        view: props.data,
                        data: [obj]
                    }))
                } else {
                    dispatch(createEntities({
                        entity: props.data,
                        data: [obj]
                    }))
                }
            } else {
                const file = obj.photo_name
                const data = new FormData()

                obj.photoId = '$file=1'
                delete obj.photo_name

                data.append('$file=1', file)
                data.append('entity', props.data)
                data.append('data', JSON.stringify([obj]))

                dispatch(createImageEntities({
                    entity: props.data,
                    data: data as any
                }))
            }
        }

        handleClose()
    }

    const onDragEnd = (ids: any) => {
        if (props.sheet && sheet && sheet.config) {
            dispatch(updateSheet({
                name: props.sheet,
                data: [{
                    name: props.sheet,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (props.sheet && sheet && sheet.config) {
            dispatch(updateSheet({
                name: props.sheet,
                data: [{
                    name: props.sheet,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const getOptions = useCallback((name: string, dependencyField?: string, dependencyValue?: any) => {
        if (props.editFormFields && props.editFormFields.length) {
            const field = props.editFormFields.find(item => item.name === name)

            if (field && field.data === 'palette') {
                return palette
            }

            if (field && field.data === 'role') {
                return entities[field.data].slice(0, 1)
            }

            return field && field.data ? entities[field.data] || [] : []
        }
    }, [entities, props.editFormFields])

    const getFilterOptions = useCallback((name: string) => {
        if (props.filterFields && props.filterFields.length) {
            const filter = props.filterFields.find(item => item.name === name)

            return filter ? entities[filter.data] || [] : []
        }
    }, [entities, props.filterFields])

    const handleFilterConfirm = (values: any) => {
        setStateFilters(values)
        handleFilterClose()
    }
    
    const handleFilterOpen = () => {
        setOpenFilter(true)
    }

    const handleFilterClose = useCallback(() => {
        setOpenFilter(false)
    }, [])

    const handleFilterText = useCallback((value: string) => {
        setTextFilter(value)
    }, [])

    const initialValues = useMemo(() => {
        if (props.primaryId && props.editFormFields && !!props.editFormFields.length) {
            const selected: any = (dataPrimary as any).find((item: any) => item[props.primaryId] === itemId)

            const values: any = {}

            props.editFormFields.forEach(field => {
                if (selected) {
                    if (field.type === types.Chips) {
                        values[field.name] = field.key ? selected[field.name].map((item: any) => item[field.key!]) : defaultValues[field.type]
                    } else {
                        values[field.name] = selected[field.name]
                    }
                } else {
                    values[field.name] = defaultValues[field.type]
                }
            })

            return values
        }
    }, [dataPrimary, itemId, props.primaryId, props.editFormFields])

    const dataFiltered = useMemo(() => {
        const textFiltered: any = props.textFilter && textFilter ? utilsFilter(dataPrimary as any, textFilter, [...props.textFilter]) : dataPrimary
        const filterValues = !!Object.values(stateFilters).find(item => Array.isArray(item) ? !!item.length : !!item)

        return filterValues ? textFiltered.filter((dataItem: any) => {
            const filters: any = {}

            props.filterFields && props.filterFields.forEach(filter => {
                if (stateFilters[filter.name]) {
                    if (Array.isArray(stateFilters[filter.name]) && !(stateFilters[filter.name] as number[]).length) {
                        filters[filter.name] = true
                    } else {
                        filters[filter.name] = Array.isArray(stateFilters[filter.name]) ?
                            (stateFilters[filter.name] as number[]).find(id => {
                                return Array.isArray(dataItem[filter.name]) ? dataItem[filter.name].find((item: any) => filter.key ? item[filter.key] === id : false) : dataItem[filter.name] === id
                            }) :
                            dataItem[filter.name] === stateFilters[filter.name]
                    }
                } else {
                    filters[filter.name] = true
                }
            })

            return !Object.keys(filters).find(key => !filters[key])
        }) : textFiltered
    }, [dataPrimary, stateFilters, props.filterFields, props.textFilter, textFilter])

    const protectedItems = useMemo(() => {
        return props.protectedItem ?
            !!dataFiltered.find((item: any) => item[props.protectedItem!] && checked.includes(item[props.primaryId])) : false
    }, [props.protectedItem, dataFiltered, checked, props.primaryId])

    const filters = useMemo(() => {
        return !!Object.values(stateFilters).find(item => Array.isArray(item) ? !!item.length : !!item)
    }, [stateFilters])

    const totals = useMemo(() => props.totals && props.totals.length ? dataFiltered.reduce((sum: any, item: any) => {
        if (props.totals) {
            props.totals.forEach(field => {
                const amount = sum[field] || 0
                sum[field] = item[field] ? amount + Number(item[field]) : amount
            })
            
            return sum
        } else {
            return undefined
        }
    }, {}) : undefined, [props.totals, dataFiltered])

    const heightBottom = props.bottom ? BottomBarHeight : 0

    const sheetFields = props.sheet && sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = props.sheet && sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Top height={TopBarHeight}>
                <div />
                {props.filterFields && !!props.filterFields.length &&
                    <Chip
                        icon={<FilterAlt />}
                        label={'Filters'}
                        sx={chip}
                        onClick={handleFilterOpen}
                        variant={filters ? 'filled' : 'outlined'}
                        color='secondary'
                    />
                }
                {!!props.textFilter && props.textFilter.length &&
                    <TextFilter onChange={handleFilterText} value={textFilter} />
                }
            </Top>
            <Container margin={heightBottom + TopBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={dataFiltered}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    onRowClick={!props.uneditable && props.editFormFields && props.editFormFields.length ? handleOpen : undefined}
                    totals={totals}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            {props.bottom &&
                <Bottom
                    height={heightBottom}
                    checked={!!checked.length}
                    handleDelete={handleDelete}
                    handleCreate={handleOpen}
                    deleteButtonDisabled={protectedItems}
                    deleteButtonText={protectedItems ? intl.formatMessage({id: "spreadsheet.ProtectedItems"}) : undefined} />
            }
            {!!props.editFormFields &&
                <EditForm
                    open={openEditForm}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    title={editFormTitle.current}
                    config={props.editFormFields}
                    getOptions={getOptions}
                    initialValues={initialValues}
                />
            }
            {!!props.filterFields &&
                <FilterForm
                    open={openFilter}
                    handleClose={handleFilterClose}
                    handleConfirm={handleFilterConfirm}
                    values={stateFilters}
                    getOptions={getFilterOptions}
                    config={props.filterFields}
                />
            }
        </React.Fragment>
    )
}

export default Sheet