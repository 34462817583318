import React from 'react'
import {useAppSelector} from 'app/hooks'
import GeneralSessions from 'containers/Employee/Project/Sessions/GeneralSessions'
import CorporateSessions from 'containers/Employee/Project/Sessions/CorporateSessions'
import {AcademiaCorporate} from 'enums/utils'

const Sessions = () => {
    const project = useAppSelector(state => state.projects.info)
    
    return (
        <React.Fragment>
            {project && 
                <React.Fragment>
                    {project.typeId === AcademiaCorporate ?
                        <CorporateSessions /> :
                        <GeneralSessions />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Sessions