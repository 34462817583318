import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import {IReqId, IReqData, IReqIds, ITraineeSession, IReqEntityData} from 'api/interfaces'
import api from 'utils/api'

type Academia = {
    id: number,
    name: string,
    [prop: string]: any
}

export type Area = {
    areaId: number,
    area_color: string,
    area_id: number,
    area_name: string,
    id: number,
    projectId: number,
    session_ends: string,
    session_id: number,
    session_location: string,
    session_projectAreaId: number,
    session_sessionTypeId: number
    session_starts: string,
    session_traineeId: number,
    trainerId: number,
    trainer_email: string,
    trainer_id: number,
    trainer_name: string,
    trainer_phone: string,
    trainer_photo: string,
    trainer_joinLink: string
}

interface IState {
    info: Academia | null,
    academias: Academia[],
    areas: Area[],
    slots: any,
    pending: boolean,
    notExist: boolean
}

const initialState: IState = {
    info: null,
    academias: [],
    areas: [],
    slots: [],
    pending: false,
    notExist: false
}

export const getAcademias = createAsyncThunk('trainee/getAcademias', async () => {
    const response = await api(ApiRoute.gettraineeacademias)
    return response.data
})

export const getAcademia = createAsyncThunk('trainee/getAcademia', async (data: IReqId) => {
    const response = await api(ApiRoute.getacademia, data)
    return response.data
})

export const getAreas = createAsyncThunk('trainee/getAreas', async (data: IReqId) => {
    const response = await api(ApiRoute.gettraineeacademiaareas, data)
    return response.data
})

export const getTimeslots = createAsyncThunk('trainee/getTimeslots', async (data: IReqId) => {
    const response = await api(ApiRoute.gettraineeacademiaavailability, data)
    return response.data
})

export const bookSession = createAsyncThunk('trainee/bookSession', async (data: IReqData<ITraineeSession>, {rejectWithValue, dispatch}) => {
    try {
        const response = await api(ApiRoute.createtraineeacademiasession, data)

        if (response.data) {
            dispatch(getTimeslots({id: response.data[0].projectId}))
        }

        return response.data
    } catch (e) {
        const response = (e as any).response
        const data = response ? response.data : undefined
        return rejectWithValue(data)
    }
})

export const cancelSession = createAsyncThunk('trainee/cancelSession', async (ids: IReqIds, {dispatch}) => {
    const response = await api(ApiRoute.canceltraineeacademiasession, ids)

    if (response.data) {
        dispatch(getTimeslots({id: response.data[0].projectId}))
    }

    return response.data
})

export const updateFiles = createAsyncThunk('trainee/updateFiles', async (data: {data: FormData, type: string}) => {
    const response = data.type === 'attach' ? await api(ApiRoute.attachwfiles, data.data) :
        await api(ApiRoute.detachwfiles, data.data)
    return response.data
})

export const updateInformation = createAsyncThunk('trainee/updateInformation', async (data: IReqEntityData<Academia>) => {
    const response = await api(ApiRoute.update, data)
    return response.data
})

export const traineeSlice = createSlice({
    name: 'trainee',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAcademias.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademias.fulfilled, (state, action) => {
                state.academias = action.payload
                state.pending = false
            })
            .addCase(getAcademias.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAcademia.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAcademia.fulfilled, (state, action) => {
                state.info = action.payload
                state.notExist = !action.payload
                state.pending = false
            })
            .addCase(getAcademia.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getAreas.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getAreas.fulfilled, (state, action) => {
                state.areas = action.payload
                state.pending = false
            })
            .addCase(getAreas.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(getTimeslots.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getTimeslots.fulfilled, (state, action) => {
                state.slots = action.payload
                state.pending = false
            })
            .addCase(getTimeslots.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(bookSession.pending, (state, action) => {
                state.pending = true
            })
            .addCase(bookSession.fulfilled, (state, action) => {
                const areas = state.areas

                action.payload.forEach((item: Area) => {
                    const index = areas.findIndex(area => area.id === item.id)
                    areas[index] = item
                })

                state.areas = areas
                state.pending = false
            })
            .addCase(bookSession.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(cancelSession.pending, (state, action) => {
                state.pending = true
            })
            .addCase(cancelSession.fulfilled, (state, action) => {
                const areas = state.areas

                action.payload.forEach((item: Area) => {
                    const index = areas.findIndex(area => area.id === item.id)
                    areas[index] = item
                })

                state.areas = areas
                state.pending = false
            })
            .addCase(cancelSession.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateFiles.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateFiles.fulfilled, (state, action) => {
                const academias = state.academias

                action.payload.forEach((item: Academia) => {
                    const index = academias.findIndex(academia => academia.id === item.id)
                    academias[index] = item
                })

                state.academias = academias
                state.pending = false
            })
            .addCase(updateFiles.rejected, (state, action) => {
                state.pending = false
            })

            .addCase(updateInformation.pending, (state, action) => {
                state.pending = true
            })
            .addCase(updateInformation.fulfilled, (state, action) => {
                const academias = state.academias

                action.payload.forEach((item: Academia) => {
                    const index = academias.findIndex(academia => academia.id === item.id)
                    academias[index] = item
                })

                state.academias = academias
                state.pending = false
            })
            .addCase(updateInformation.rejected, (state, action) => {
                state.pending = false
            })
    }
})

export default traineeSlice