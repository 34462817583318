import React from 'react'
import styled from '@emotion/styled'
import {Outlet, Link, useLocation, useParams} from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import BarChart from '@mui/icons-material/BarChart'
import Insights from '@mui/icons-material/Insights'
import School from '@mui/icons-material/School'
import Mic from '@mui/icons-material/Mic'
import People from '@mui/icons-material/People'
import Workspaces from '@mui/icons-material/Workspaces'
import Checklist from '@mui/icons-material/Checklist'
import Groups from '@mui/icons-material/Groups'
import ContentPaste from '@mui/icons-material/AutoAwesome'
import PendingActions from '@mui/icons-material/PendingActions'
import RocketLaunch from '@mui/icons-material/RocketLaunch'
import Cloud from '@mui/icons-material/Cloud'
import CreditCard from '@mui/icons-material/CreditCard'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import Event from '@mui/icons-material/Event'
import Assignment from '@mui/icons-material/Assignment'
import Phone from '@mui/icons-material/Phone'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import ListAlt from '@mui/icons-material/ListAlt'
import Payments from '@mui/icons-material/Payments'
import AccountCircle from '@mui/icons-material/AccountCircle'
import EventAvailable from '@mui/icons-material/EventAvailable'
import Web from '@mui/icons-material/Web'
import FolderCopy from '@mui/icons-material/FolderCopy'
import Menu from 'components/Menu'
import {useAppSelector} from 'app/hooks'
import routes from 'enums/routes'
import {AcademiaCorporate, RoleIdAdmin} from 'enums/utils'

const Button = styled.div`
    padding: 10px;
`

const main = [
    {
        title: 'Projects',
        path: routes.Projects,
        icon: <Insights />
    },
    {
        title: 'Academias',
        path: routes.Academias,
        icon: <School />
    },
    {
        title: 'Tasks',
        path: routes.Tasks,
        icon: <Checklist />
    },
    {
        title: 'Calendar',
        path: routes.Calendar,
        icon: <Event />
    },
    {
        title: 'Trainers availability',
        path: routes.Availability,
        icon: <EventAvailable />
    },
    {
        title: 'Project invoices',
        path: routes.ProjectInvoices,
        icon: <ListAlt />
    },
    {
        title: 'Academia invoices',
        path: routes.AcademiaInvoices,
        icon: <ListAlt />
    },
    {
        title: 'Commissions',
        path: routes.Commissions,
        icon: <Payments />
    },
    {
        title: 'Forecast',
        path: routes.Forecast,
        icon: <BarChart />
    }
]

const entities = [
    {
        title: 'Entities',
        path: routes.Trainers,
        icon: <Cloud />
    }
]

const admin = [
    {
        title: 'Excel backups',
        path: routes.Backups,
        icon: <FolderCopy />
    }
]

const entitiesRoutes = [
    {
        title: 'Trainers',
        path: routes.Trainers,
        icon: <Mic />
    },
    {
        title: 'Trainees',
        path: routes.Trainees,
        icon: <People />
    },
    {
        title: 'Areas',
        path: routes.Areas,
        icon: <Workspaces />
    },
    {
        title: 'Frequent expenses',
        path: routes.Expenses,
        icon: <CreditCard />
    },
    {
        title: 'Default tasks',
        path: routes.Tasks,
        icon: <Checklist />
    },
    {
        title: 'Clients',
        path: routes.Clients,
        icon: <Assignment />
    },
    {
        title: 'Client contacts',
        path: routes.Contacts,
        icon: <Phone />
    },
    {
        title: 'Team members',
        path: routes.Members,
        icon: <Groups />
    },
    {
        title: 'Project types',
        path: routes.Types,
        icon: <AutoFixHigh />
    },
    {
        title: 'Users',
        path: routes.Users,
        icon: <AccountCircle />
    },
    {
        title: 'Templates',
        path: routes.Templates,
        icon: <Web />
    }
]

const projectRoutes = [
    {
        title: 'Overview',
        path: routes.Overview,
        icon: <ContentPaste />
    },
    {
        title: 'Sessions',
        path: routes.Sessions,
        icon: <PendingActions />
    },
    {
        title: 'Calendar',
        path: routes.Calendar,
        icon: <Event />
    },
    {
        title: 'Participants',
        path: routes.Participants,
        icon: <Groups />
    },
    {
        title: 'Tasks',
        path: routes.Tasks,
        icon: <Checklist />
    },
    {
        title: 'Proposal',
        path: routes.Proposal,
        icon: <RocketLaunch />
    }
]

const proposalRoute = [
    {
        title: 'Proposal',
        path: routes.Proposal,
        icon: <RocketLaunch />
    }
]

const academiaRoutes = [
    {
        title: 'Overview',
        path: routes.Overview,
        icon: <ContentPaste />
    },
    {
        title: 'Program',
        path: routes.Program,
        icon: <Workspaces />
    },
    {
        title: 'Sessions',
        path: routes.Sessions,
        icon: <PendingActions />
    },
    {
        title: 'Calendar',
        path: routes.Calendar,
        icon: <Event />
    },
    {
        title: 'Participants',
        path: routes.Participants,
        icon: <Groups />
    },
    {
        title: 'Tasks',
        path: routes.Tasks,
        icon: <Checklist />
    }
]

const Employee = () => {
    const info = useAppSelector(state => state.projects.info)
    const user = useAppSelector(state => state.auth.user)

    const {pathname} = useLocation()
    const {projectId, academiaId} = useParams()

    const currentRoutes = projectId ? info && info.typeId === AcademiaCorporate ? academiaRoutes.concat(proposalRoute) : projectRoutes : academiaRoutes
    const section = projectId ? routes.Project : routes.Academia
    const id = projectId || academiaId

    const entity = pathname.includes(routes.Entities)

    return (
        <React.Fragment>
            <Menu>
                {id ?
                    <React.Fragment>
                        <List>
                            {currentRoutes.map((item) => {
                                const to = `/${section}/${id}/${item.path}`

                                return (
                                    <ListItem key={item.path} disablePadding>
                                        <ListItemButton component={Link} to={to} selected={to === pathname}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Divider />
                        <Button>
                            <IconButton component={Link} to={projectId ? routes.Projects : routes.Academias}>
                                <ChevronLeft />
                            </IconButton>
                        </Button>
                    </React.Fragment>
                : entity ?
                    <React.Fragment>
                        <List>
                            {entitiesRoutes.map((item) => {
                                const to = `/${routes.Entities}/${item.path}`

                                return (
                                    <ListItem key={item.path} disablePadding>
                                        <ListItemButton component={Link} to={to} selected={to === pathname}>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        <Divider />
                        <Button>
                            <IconButton component={Link} to={routes.Projects}>
                                <ChevronLeft />
                            </IconButton>
                        </Button>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <List>
                            {main.map((item, index) => (
                                <ListItem key={item.path} disablePadding>
                                    <ListItemButton component={Link} to={item.path} selected={pathname === `/${item.path}`}>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                        <List>
                            {user && user.roleId === RoleIdAdmin &&
                                <React.Fragment>
                                    {admin.map((item) => 
                                        <ListItem key={item.path} disablePadding>
                                            <ListItemButton component={Link} to={item.path} selected={pathname === `/${item.path}`}>
                                                <ListItemIcon>
                                                    {item.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </React.Fragment>
                            }
                            {entities.map((item) => {
                                const to = `/${routes.Entities}/${item.path}`

                                return <ListItem key={item.path} disablePadding>
                                    <ListItemButton component={Link} to={to} selected={pathname === item.path}>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            })}
                        </List>
                    </React.Fragment>
                }
            </Menu>
            <Box sx={{flexGrow: 1, minWidth: 0}}>
                <Outlet />
            </Box>
        </React.Fragment>
    )
}

export default Employee