import React, {useCallback, useEffect, useState} from 'react'
import styled from '@emotion/styled'
import {useIntl} from 'react-intl'
import {Spreadsheet} from '@framelink/spreadsheet_ts'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getEntities, deleteBackups, createBackups} from 'slices/entities'
import {getSheet, updateSheet} from 'slices/sheets'
import HeaderItem from 'components/HeaderItem'
import BodyItem from 'components/BodyItem'
import Bottom from 'components/SheetBottom'
import DownloadButton from 'components/Fields/DownloadButton'
import {BottomBarHeight} from 'enums/styles'
import {RoleIdAdmin} from 'enums/utils'
import {Entity, Sheet} from 'api/enums'
import {types} from 'enums/fields'

const Container = styled.div<{margin: number}>`
    display: flex;
    height: ${props => `calc(100% - ${props.margin}px)`};
`

const defaultSheetFields: any = []

const Backups = () => {
    const [checked, setCheck] = useState<number[]>([])
    const [pending, setPending] = useState<boolean>(false)

    const backups = useAppSelector(state => state.entities[Entity.excelDump])
    const sheet = useAppSelector(state => state.sheets.data[Sheet.excelDump])
    const user = useAppSelector(state => state.auth.user)

    const intl = useIntl()

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getEntities({entity: Entity.excelDump}))
        dispatch(getSheet({name: Sheet.excelDump}))
    }, [dispatch])

    const onCheck = useCallback((documentIds: number[]) => {
        setCheck(documentIds)
    }, [])

    const renderHeaderCell = (data: any) => {
        return <HeaderItem
            name={data.name}
            title={data.title}
            width={data.width}
            type={data.type as string}
        />
    }

    const renderCell = (value: any, column: any, data: any) => {
        if (column.type === types.Custom) {
            const component = getCustomItem(value, column, data)

            return component
        }

        return <BodyItem
            value={value}
            column={column}
            data={data}
        />
    }

    const handleDelete = () => {
        dispatch(deleteBackups({
            ids: checked
        }))

        setCheck([])
    }

    const handleCreate = async () => {
        setPending(true)
        const response = await dispatch(createBackups())

        if (response.payload) {
            window.open(response.payload[0].file_link)
        }
        
        setPending(false)
    }

    const onDragEnd = (ids: any) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.excelDump,
                data: [{
                    name: Sheet.excelDump,
                    config: {
                        fieldsOrder: ids,
                        fields: sheet.config.fields
                    }
                }]
            }))
        }
    }

    const onResizeEnd = (id: any, width: number) => {
        if (sheet && sheet.config) {
            dispatch(updateSheet({
                name: Sheet.excelDump,
                data: [{
                    name: Sheet.excelDump,
                    config: {
                        ...sheet.config,
                        fields: sheet.config.fields.map(item => {
                            return item.name === id ? {
                                ...item,
                                width: width
                            } : item
                        })
                    }
                }]
            }))
        }
    }

    const getCustomItem = useCallback((value: any, column: any, data: any) => {
        return (
            <DownloadButton
                link={column.name ? data[column.name] : undefined}
            />
        )
    }, [])

    const sheetFields = sheet && sheet.config ? sheet.config.fields : defaultSheetFields

    const order = sheet && sheet.config ? sheet.config.fieldsOrder : sheetFields.map((item: any) => item.name)

    const sheetEditable = user ? user.roleId === RoleIdAdmin : false

    return (
        <React.Fragment>
            <Container margin={BottomBarHeight}>
                <Spreadsheet
                    columns={sheetFields}
                    rows={backups}
                    order={order}
                    heightRow={60}
                    onCheck={onCheck}
                    renderHeaderCell={renderHeaderCell}
                    renderCell={renderCell}
                    checkedRows={checked}
                    reordering={sheetEditable}
                    resizing={sheetEditable}
                    onDragEnd={onDragEnd}
                    onResizeEnd={onResizeEnd}
                />
            </Container>
            <Bottom
                height={BottomBarHeight}
                checked={!!checked.length}
                createButtonText={intl.formatMessage({id: "backup.CreateBackup"})}
                deleteButtonText={intl.formatMessage({id: "backup.DeleteBackup"})}
                handleDelete={handleDelete}
                handleCreate={handleCreate}
                createButtonDisabled={pending}
            />
        </React.Fragment>
    )
}

export default Backups