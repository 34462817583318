import React from 'react'
import MuiAutocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

interface IComponent {
    value: string,
    title?: string,
    name: string,
    options: string[],
    disabled?: boolean,
    size?: 'small',
    onChange: (name: string, value: string) => void
}

const Autocomplete = (props: IComponent) => {
    const onChange = (e: any, value: string) => {
        props.onChange(props.name, value)
    }

    const getInput = (params: AutocompleteRenderInputParams) => {
        return <TextField
            {...params}
            label={props.title}
            InputLabelProps={{
                shrink: true
            }}
            size={props.size}
            sx={props.size && {input: {fontSize: 14}}}
        />
    }

    return (
        <MuiAutocomplete
            fullWidth
            value={null}
            options={props.options}
            onInputChange={onChange}
            inputValue={props.value}
            renderInput={getInput}
            freeSolo={true}
            disabled={props.disabled}
        />
    )
}

export default React.memo(Autocomplete)
