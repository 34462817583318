import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'
import Numeric from 'components/FormInputs/Numeric'

interface IComponent {
    values: {[key: string]: number},
    options: Option[],
    onChange: (values: {[key: string]: number}) => void
}

type Option = {
    id: number,
    name: string,
    color: string
}

const Container = styled.div`
    width: 100%;
`

const Title = styled.div`
    font-size: 15px;
    color: #666;
    font-weight: 500;
    letter-spacing: 0.5px;
`

const Item = styled.div`
    margin: 20px;
    display: flex;
    align-items: center;
`

const chip = {
    width: 180,
    marginRight: 5
}

const InvoiceRules = (props: IComponent) => {
    const [values, setValue] = useState(props.values)

    const intl = useIntl()

    const onChange = (name: string, value: number) => {
        const data = {
            ...values,
            [name]: value
        }

        setValue(data)

        props.onChange(data)
    }

    return (
        <Container>
            <Title>Invoice rules</Title>
            {props.options.map(item => {
                return <Item key={item.id}>
                    <Chip
                        label={item.name}
                        sx={{...chip, background: item.color}}
                    />
                    <Numeric
                        value={values[item.id]}
                        name={item.id.toString()}
                        onChange={onChange}
                        controlled={true}
                        disabled={false}
                        title={intl.formatMessage({id: "invoice.Percent"})}
                    />
                </Item>
            })}
        </Container>
    )
}

export default React.memo(InvoiceRules)
