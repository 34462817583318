import React from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import Chip from '@mui/material/Chip'

const Title = styled.div`
    font-size: 13px;
    color: #708085;
    letter-spacing: 0.5px;
    font-weight: 500;
    margin: 10px 0;
`

const Text = styled.div`
    font-size: 14px;
    line-height: 1.5;
    margin: 20px 0;
`

interface IComponent {
    id: number
    name?: string
    title?: string
    project?: string
    trainee?: string
    start: string
    onClose: () => void
}

const EventPopup = (props: IComponent) => {
    const intl = useIntl()

    const target = new Date(props.start)
    const current = new Date()
    const past = current > target

    const date = intl.formatDate(props.start, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long',
    })

    const time = intl.formatDate(props.start, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    })

    return (
        <div>
            <React.Fragment>
                {props.project && <Title>{props.project}</Title>}
                {props.title && <Title>{props.title}</Title>}
                {props.trainee &&
                    <Chip label={props.trainee} size='small'
                        sx={{marginBottom: '10px', borderRadius: '5px'}}
                    />
                }
                {past ?
                    <React.Fragment>
                        {props.name && <Text>
                            {intl.formatMessage({id: "calendar.PastSession"}, {title: props.name, date: date, time: time})}
                        </Text>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {props.name && <Text>
                            {intl.formatMessage({id: "calendar.FutureSession"}, {title: props.name, date: date, time: time})}
                        </Text>}
                    </React.Fragment>
                }
            </React.Fragment>
        </div>
    )
}

export default React.memo(EventPopup) 