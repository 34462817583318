import {useCallback, useEffect, useState, useMemo, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Chip from '@mui/material/Chip'
import Person from '@mui/icons-material/Person'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Brush from '@mui/icons-material/Brush'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProjects, createProjects} from 'slices/projects'
import {getEntities, createEntities} from 'slices/entities'
import CardsList from 'components/CardsList'
import EditForm from 'components/EditForm'
import {types} from 'enums/fields'
import {createForm} from 'enums/projects/projects'
import routes from 'enums/routes'
import {Entity} from 'api/enums'

const Buttons = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`

const icon = {
    fontSize: 18
}

const chip = {
    width: '180px',
    marginBottom: 2
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Type',
            icon: <AutoFixHigh sx={icon} />,
            type: types.Chip,
            value: 'type_name',
            props: {
                id: 'type_id',
                text: 'type_name',
                background: 'type_color'
            }
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        }
    ]
}

const Projects = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement | null>(null)
    const [status, setStatus] = useState<number>(0)
    const [type, setType] = useState<number>(0)
    const [openForm, setOpenForm] = useState<boolean>(false)

    const nameFilter = useRef<string>('')
    const containerElement = useRef(null)

    const projects = useAppSelector(state => state.projects.projects)
    const projectTypes = useAppSelector(state => state.entities[Entity.projectType])
    const statuses = useAppSelector(state => state.entities[Entity.projectStatus])
    const members = useAppSelector(state => state.entities[Entity.teamMember])
    const contacts = useAppSelector(state => state.entities[Entity.clientContact])
    const clients = useAppSelector(state => state.entities[Entity.client])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const sm = useMediaQuery(theme.breakpoints.down('sm'))


    useEffect(() => {
        dispatch(getProjects())
        dispatch(getEntities({entity: Entity.projectType}))
        dispatch(getEntities({entity: Entity.projectStatus}))
    }, [dispatch])

    const projectStatuses = useMemo(() => {
        return statuses.filter(status => !status.academia)
    }, [statuses])

    const filteredProjectTypes = useMemo(() => {
        return projectTypes.filter(type => type.project)
    }, [projectTypes])

    const onGridItemClick = useCallback((id: number) => {
        navigate(`/${routes.Project}/${id}/${routes.Overview}`)
    }, [navigate])

    const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        const name = e.currentTarget.getAttribute('data-name')
        nameFilter.current = name ? name : ''
        setOpenFilter(e.currentTarget)
    }

    const handleFilterClose = () => {
        setOpenFilter(null)
    }

    const filterItems = (id: number, filter: string) => {
        if (filter === 'status') {
            if (id === status) {
                setStatus(0)
            } else {
                setStatus(id)
            }
        } else {
            if (id === type) {
                setType(0)
            } else {
                setType(id)
            }
        }

        handleFilterClose()
    }

    const handleFormOpen = () => {
        dispatch(getEntities({entity: Entity.client}))
        dispatch(getEntities({entity: Entity.clientContact}))
        dispatch(getEntities({entity: Entity.teamMember}))

        setOpenForm(true)
    }

    const handleFormClose = () => {
        setOpenForm(false)
    }

    const handleConfirm = (values: any) => {
        dispatch(createProjects({data: [{...values}]}))

        handleFormClose()
    }

    const handleCreate = (name: string, value: string, dependencyValue: any) => {
        if (name === 'clientId') {
            dispatch(createEntities({
                entity: Entity.client,
                data: [{name: value}]
            }))
        }

        if (name === 'contactId') {
            dispatch(createEntities({
                entity: Entity.clientContact,
                data: [{
                    name: value,
                    clientId: dependencyValue
                }]
            }))
        }
    }

    const getOptions = (name: string, dependencyField?: string, dependencyValue?: any) => {
        if (name === 'typeId') {
            return filteredProjectTypes
        }
        
        if (name === 'clientId') {
            return clients
        }
        
        if (name === 'contactId') {
            return dependencyField && dependencyValue ?
                contacts.filter((contact: {[key: string]: any}) => contact[dependencyField] === dependencyValue) : contacts
        }
        
        if (name === 'teamMembers') {
            return members
        }

        if (name === 'commercialOwnerId') {
            return members.filter(item => dependencyValue.includes(item.id))
        }

        return []
    }

    const initialValues = useMemo(() => {
        return {
            name: '',
            starts: null,
            ends: null,
            salesCloseDate: null,
            participants: '',
            context: '',
            typeId: null,
            clientId: null,
            contactId: null,
            commercialOwnerId: null,
            teamMembers: []
        }
    }, [])

    const projectsFiltered = useMemo(() => status || type ? projects.filter(project => {
        const filterStatus = status ? project.status_id === status : true
        const filterType = type ? project.type_id === type : true

        return filterStatus && filterType

    }) : projects, [projects, status, type])

    const currentStatus = projectStatuses.find(item => item.id === status)
    const currentType = filteredProjectTypes.find(item => item.id === type)
    
    return (
        <div style={{height: '100%', overflow: 'auto', padding: '20px'}} ref={containerElement}>
            <Buttons>
                <Chip
                    variant='outlined'
                    color='secondary'
                    label='Create project'
                    onClick={handleFormOpen}
                    sx={{...chip, marginRight: 2}}
                    icon={<Brush />}
                />
                <div>
                    <Chip
                        icon={<TaskAlt />}
                        label={currentStatus ? currentStatus.name : 'Status'}
                        sx={{...chip, marginRight: 2, background: currentStatus ? currentStatus.color : ''}}
                        onClick={handleFilterOpen}
                        data-name='status'
                    />
                    <Chip
                        icon={<AutoFixHigh />}
                        label={currentType ? currentType.name : 'Type'}
                        sx={{...chip, background: currentType ? currentType.color : ''}}
                        onClick={handleFilterOpen}
                        data-name='type'
                    />
                </div>
                <Menu
                    anchorEl={openFilter}
                    open={!!openFilter}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                marginTop: '10px',
                                width: '180px'
                            }
                        }
                    }}
                >
                    {nameFilter.current === 'status' ?
                        Object.values(projectStatuses).map(item => (
                            <MenuItem onClick={() => filterItems(item.id, 'status')}
                                key={item.id}
                                value={item.id}
                                selected={status === item.id}
                            >
                                <Typography variant="inherit" noWrap>
                                    {item.name}
                                </Typography>
                            </MenuItem>
                        )) :
                        Object.values(filteredProjectTypes).map(item => (
                            <MenuItem onClick={() => filterItems(item.id, 'type')}
                                key={item.id}
                                value={item.id}
                                selected={type === item.id}
                            >
                                <Typography variant="inherit" noWrap>
                                    {item.name}
                                </Typography>
                            </MenuItem>
                        ))
                    }
                </Menu>
            </Buttons>
            <CardsList
                config={config}
                data={projectsFiltered}
                onClick={onGridItemClick}
                color='#faf5f1'
                itemsCount={sm ? 1 : md ? 3 : 4}
                containerComponent={containerElement.current}
                paperHeight={435}
            />
            <EditForm
                open={openForm}
                handleClose={handleFormClose}
                handleConfirm={handleConfirm}
                onCreate={handleCreate}
                title={'Create project'}
                config={createForm}
                getOptions={getOptions}
                initialValues={initialValues}
            />
        </div>
    )
}

export default Projects