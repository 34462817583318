const routes = {
    Signin: 'signin',
    Account: 'account',

    Projects: 'projects',
    Academias: 'academias',
    ProjectInvoices: 'project-invoices',
    AcademiaInvoices: 'academia-invoices',
    Commissions: 'commissions',
    Forecast: 'forecast',
    Project: 'project',
    Academia: 'academia',
    Availability: 'availability',
    Entities: 'entities',
    Backups: 'backups',
    
    Overview: 'overview',
    Sessions: 'sessions',
    Tasks: 'tasks',
    Participants: 'participants',
    Program: 'program',
    Proposal: 'proposal',
    Calendar: 'calendar',

    Trainers: 'trainers',
    Trainees: 'trainees',
    Areas: 'areas',
    Expenses: 'expenses',
    Clients: 'clients',
    Contacts: 'contacts',
    Members: 'members',
    Types: 'types',
    Users: 'users',
    Templates: 'templates',

    Courses: 'courses',

    Trainings: 'trainings',
    Schedule: 'schedule',
    Training: 'training',

    Redirect: '/'
}

export const access = {
    master: {
        id: 1,
        redirect: routes.Projects
    },
    trainee: {
        id: 2,
        redirect: routes.Courses
    },
    trainer: {
        id: 3,
        redirect: routes.Trainings
    },
    team: {
        id: 4,
        redirect: routes.Projects
    },
}

export default routes
