import React from 'react'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import {getFormattedPhone} from 'components/PhoneInput'

interface IComponent {
    value: string
}

const Input = styled.div<{color: string}>`
    height: 100%;
    width: 100%;
    margin: 0 10px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    color: ${props => props.color};
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`

const Phone = (props: IComponent) => {
    const theme = useTheme()

    const {number} = getFormattedPhone(props.value)

    return (
        <Input color={theme.palette.text.primary}>{number}</Input>
    )
}

export default React.memo(Phone)
