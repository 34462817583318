import {types} from 'enums/fields'
import {Entity, View} from 'api/enums'

export const sheet = [
    {
        name: 'item_project_name',
        title: 'Academia',
        width: 400,
        type: types.Text
    },
    {
        name: 'item_trainee_name',
        title: 'Client',
        width: 200,
        type: types.Chip
    },
    {
        name: 'percent',
        title: 'Invoice rule',
        width: 200,
        type: types.AcademiaInvoice
    },
    {
        name: 'item_enrollDate',
        title: 'Enroll date',
        width: 200,
        type: types.Date
    },
    {
        name: 'dueDate',
        title: 'Due date',
        width: 200,
        type: types.Date
    },
    {
        name: 'value',
        title: 'Invoice value',
        width: 200,
        type: types.Currency
    },
    {
        name: 'status_name',
        title: 'Invoice status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'statusId',
        title: 'Send invoice',
        width: 200,
        type: types.Custom
    }
]

export const filter = [
    {
        title: 'Filter by Academia',
        name: 'item_project_id',
        type: types.Chip,
        data: View.academias
    },
    {
        title: 'Filter by Client',
        name: 'item_trainee_id',
        type: types.Chip,
        data: Entity.trainee
    },
    {
        title: 'Filter by Status',
        name: 'status_id',
        type: types.Chip,
        data: Entity.invoiceStatus
    }
]

export const entities = [Entity.trainee, Entity.invoiceStatus, View.academias]

export const totals = ['value']