import React from 'react'
import PhoneInput from 'components/PhoneInput'

interface IComponent {
    value: string,
    title?: string,
    name: string,
    disabled: boolean,
    onChange: (name: string, value: string) => void
}

const Phone = (props: IComponent) => {
    const onChange = (phone: string) => {
        props.onChange(props.name, phone)
    }

    return (
        <PhoneInput
            disabled={props.disabled}
            value={props.value}
            label={props.title}
            // name={props.name}
            onChange={onChange}
            defaultCountry='pt'
        />
    )
}

export default React.memo(Phone)
