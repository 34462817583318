import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import api from 'utils/api'

type Info = {
    accepted: number
    notaccepted: number
    salesCloseDate_month: string
    total: number
}

type Item = {
    id: number
    accepted: boolean
    name: string
    starts: string
    ends: string
    participants: string
    context: string
    typeId: number
    statusId: number
    clientId: number | null
    contactId: number | null
    commercialOwnerId: number | null
    commissionValue: string,
    salesCloseDate: string | null
    salesCloseDate_month: string | null
    [key: string]: any
}

interface IState {
    data: {
        infos: Info[],
        items: Item[]
    }
    pending: boolean
}

const initialState: IState = {
    data: {infos: [], items: []},
    pending: false
}

export const getForecast = createAsyncThunk('forecast/getForecast', async () => {
    const response = await api(ApiRoute.getforecast)
    return response.data
}, {condition: (data, {getState}) => {
        const {forecast} = getState() as {forecast: IState}
        const pending = forecast.pending

        if (pending) {
            return false
        }
    }
})

export const forecastSlice = createSlice({
    name: 'forecast',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getForecast.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getForecast.fulfilled, (state, action) => {
                state.data = action.payload
                state.pending = false
            })
            .addCase(getForecast.rejected, (state, action) => {
                state.pending = false
            })
    },
})

export default forecastSlice