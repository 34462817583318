import Sheet from 'components/Sheet'
import {form, entities} from 'enums/entities/tasks'
import {Entity, Sheet as Sheets} from 'api/enums'

const Tasks = () => {
    return (
        <Sheet
            data={Entity.defaultTask}
            primaryId={'id'}
            textFilter={['name']}
            editFormFields={form}
            entities={entities as any}
            sheet={Sheets.defaultTask}
            bottom
        />
    )
}

export default Tasks