import {types} from 'enums/fields'
import {Entity, View} from 'api/enums'

export const sheet = [
    {
        name: 'rule_project_name',
        title: 'Project',
        width: 400,
        type: types.Text
    },
    {
        name: 'rule_project_client_name',
        title: 'Client',
        width: 200,
        type: types.Chip
    },
    {
        name: 'rule_value',
        title: 'Rule',
        width: 200,
        type: types.ProjectInvoice,
        color: 'rule_status_color',
        status: 'rule_status_name'
    },
    {
        name: 'rule_project_status_name',
        title: 'Project status',
        width: 200,
        type: types.Chip,
        color: 'rule_project_status_color'
    },
    {
        name: 'rule_project_salesValue',
        title: 'Project value',
        width: 200,
        type: types.Currency
    },
    {
        name: 'value',
        title: 'Invoice value',
        width: 200,
        type: types.Currency
    },
    {
        name: 'status_name',
        title: 'Invoice status',
        width: 200,
        type: types.Chip,
        color: 'status_color'
    },
    {
        name: 'statusId',
        title: 'Send invoice',
        width: 200,
        type: types.Custom
    }
]

export const filter = [
    {
        title: 'Filter by Project',
        name: 'rule_project_id',
        type: types.Chip,
        data: View.projects
    },
    {
        title: 'Filter by Client',
        name: 'rule_project_client_id',
        type: types.Chip,
        data: Entity.client
    },
    {
        title: 'Filter by Status',
        name: 'status_id',
        type: types.Chip,
        data: Entity.invoiceStatus
        
    }
]

export const entities = [Entity.client, Entity.invoiceStatus, View.projects]

export const totals = ['value', 'rule_project_salesValue']