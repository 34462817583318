import {View, Action, Sheet as Sheets} from 'api/enums'
import Sheet from 'components/Sheet'
import {form, entities} from 'enums/entities/users'
import {useAppDispatch} from 'app/hooks'
import {actEntity} from 'slices/entities'
import ResetButton from 'components/Fields/ResetButton'

const Users = () => {
    const dispatch = useAppDispatch()

    const getCustomItem = (value: any, column: any, data: any) => {
        return (
            <ResetButton
                name={data.name}
                documentId={data.id}
                onClick={onClick}
            />
        )
    }

    const onClick = (id: number) => {
        dispatch(actEntity({
            view: View.users,
            action: Action.resetpassword,
            ids: [id]
        }))
    }

    return (
        <Sheet
            data={View.users}
            primaryId={'id'}
            textFilter={['email', 'login', 'name']}
            editFormFields={form}
            entities={entities as any}
            sheet={Sheets.users}
            bottom
            uneditable
            getCustomItem={getCustomItem}
        />
    )
}

export default Users